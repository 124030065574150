import { VerticalService } from './../../shared/services/vertical.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-create-vertical',
  templateUrl: './create-vertical.component.html',
  styleUrls: ['./create-vertical.component.scss'],
})
export class CreateVerticalComponent implements OnInit {

  verticalForm = new FormGroup({
    type: new FormControl("", [Validators.required]),
    subType: new FormControl(),
    isEnabled: new FormControl(true)
  });

  constructor(private verticalService: VerticalService, private modalController: ModalController) { }

  ngOnInit() { }

  onCreateVerticalSubmit() {
    console.log(this.verticalForm)
    let vertical = {
      type: this.verticalForm.value.type,
      subType: this.verticalForm.value.subType,
      isEnabled: this.verticalForm.value.isEnabled

    }
    console.log(vertical)
    this.verticalService.createVertical(vertical).subscribe((res: any)=>{
      console.log(res)
      this.modalController.dismiss();
    })
  }
}
