import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Subscription } from 'rxjs';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import { AccountSettlementService } from 'src/app/shared/services/account-settlement.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { AccountSettlementInput } from 'src/app/feature/merchant-details/account.model';
import { ConfirmedValidator } from 'src/app/shared/validators/confirmed-validator/confirmed-validator.component';
import { BusinessService } from 'src/app/shared/services/business.service';
import { OrderNumber } from './order-number.model';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit, OnDestroy {

  createAccountQuerySubscription: Subscription;
  createOrderNumberSucscription: Subscription;
  account: AccountSettlementInput;

  @Input() allData: any;
  merchantId: string;
  businessId: string;
  accountForm: FormGroup = new FormGroup({});
  accountSettlement: AccountSettlementInput;
  accountFormatted = [];
  loadingControllerInstance: any;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountSettlementService,
    private modalCtrl: ModalController,
    public alertController: AlertController,
    public loadingController: LoadingController,
    private businessService: BusinessService
  ) {

  }

  ngOnInit() {
    this.merchantId = this.allData.data.merchantId;
    this.businessId = this.allData.data.id;

    this.accountForm = this.fb.group({
      businessId: new FormControl('', [Validators.required, Validators.minLength(10)]),
      accountType: new FormControl('STRIPE', Validators.required),
      id: new FormControl(this.makeid(24), Validators.required),
      accountId: new FormControl('', Validators.required),
      confirm_accountId: new FormControl('', Validators.required),
      // isActive: new FormControl(true),
      // additionalInfo: new FormControl('', Validators.required),
      simPayXFee: new FormControl(9.9, Validators.required),
  }, {
    validator: ConfirmedValidator('accountId', 'confirm_accountId')
  });
    // this.accountForm.get('merchantId').setValue(this.merchantId);
    this.accountForm.get('businessId').setValue(this.businessId);
  }

  get f(){
    return this.accountForm.controls;
  }

  onAccountSubmit() {
    this.presentLoading();
    this.accountSettlement = this.accountForm.value;
    // this.accountFormatted.push(this.accountSettlement);
    const formattedAccount = JSON.parse(JSON.stringify(this.accountSettlement, (key, value) => {
      if (key === 'simPayXFee') {
        value = Number(value);
        return value;
      }
      return value;
    }));

    delete formattedAccount.confirm_accountId;
    console.log('formattedAccount', formattedAccount);
    // tslint:disable-next-line: max-line-length
    this.createAccountQuerySubscription = this.accountService.createAccount(this.merchantId, this.businessId, formattedAccount).subscribe((response: any) => {
      this.createOrderNumber();
    });
    this.accountForm.reset();
  }

  createOrderNumber(){
    var orderNumber = new OrderNumber();
    orderNumber.businessId = this.businessId;
    orderNumber.counter = 0;
    this.createOrderNumberSucscription = this.businessService.createOrderNumber(this.businessId, orderNumber).subscribe((response:any) =>{
      this.loadingControllerInstance.dismiss();
      this.showAlert('Success','You have successfully onboarded your business');
    });
  }

  makeid(length) {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 async presentLoading() {
  this.loadingControllerInstance = await this.loadingController.create({
    message: 'Please wait...'
  });
  await this.loadingControllerInstance.present();
}

async showAlert(header: string, msg: string) {
  const alert = await this.alertController.create({
    header: header,
    message: msg,
    buttons: [
      {
        text: "Ok",
        handler: () => {
          console.log('Cancelled');
          this.modalCtrl.dismiss();
        }
      },
    ],
  });
  await alert.present();
}

ngOnDestroy(): void {
  if (this.createAccountQuerySubscription){
    this.createAccountQuerySubscription.unsubscribe();
  }

  if(this.createOrderNumberSucscription){
    this.createOrderNumberSucscription.unsubscribe();
  }
}

}
