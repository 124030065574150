import { ModalController, NavParams } from '@ionic/angular';
import { BusinessService } from './../../shared/services/business.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { BusinessInput } from '../business-details/business.model';

@Component({
  selector: 'app-create-business',
  templateUrl: './create-business.component.html',
  styleUrls: ['./create-business.component.scss'],
})
export class CreateBusinessComponent implements OnInit, OnDestroy {
  @Input() connectedId: string;
  businessQuerySubscription: Subscription;
  createBusinessQuerySubscription: Subscription;
  business: BusinessInput;
  allBusinessForMerchant: any[];
  businessFormatted = [];
  Servicetype: any = ['DINE_IN', 'PICKUP', 'TAKEOUT', 'RESERVATION']

  example: [{
    Monday: {
      isOpen: false,
      businessHours: {
        opensAt: '',
        closesAt: ''
      }
    }
  }];

  businessForm: FormGroup;
  interval: any;

  constructor(
    private fb: FormBuilder,
    private businessService: BusinessService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.business = new BusinessInput();
    this.business.merchantId = this.connectedId;
    this.businessForm = this.fb.group({
      merchantId: new FormControl(this.business.merchantId, [Validators.required, Validators.minLength(10)]),
      name: new FormControl(this.business.name, [Validators.required, Validators.minLength(4)]),
      vertical: new FormControl(this.business.vertical, Validators.required),
      isEnabled: new FormControl(this.business.isEnabled),
      address: new FormGroup({
        line1: new FormControl(this.business.address.line1, Validators.required),
        line2: new FormControl(this.business.address.line2),
        city: new FormControl(this.business.address.city, Validators.required),
        state: new FormControl(this.business.address.state, Validators.required),
        zip: new FormControl(this.business.address.zip, Validators.required),
        country: new FormControl(this.business.address.country),
      }),

      digitalAddresses: this.fb.array([
        this.fb.group({
          addressType: [this.business.digitalAddresses[0].addressType],
          address: [this.business.digitalAddresses[0].address],
          preferred: [this.business.digitalAddresses[0].preferred]
        }),
        this.fb.group({
          addressType: [this.business.digitalAddresses[1].addressType],
          address: [this.business.digitalAddresses[1].address],
          preferred: [this.business.digitalAddresses[1].preferred]
        }),
        this.fb.group({
          addressType: [this.business.digitalAddresses[2].addressType],
          address: [this.business.digitalAddresses[2].address],
          preferred: [this.business.digitalAddresses[2].preferred]
        }),
        this.fb.group({
          addressType: [this.business.digitalAddresses[3].addressType],
          address: [this.business.digitalAddresses[3].address],
          preferred: [this.business.digitalAddresses[3].preferred]
        })
      ]),

      businessAttributes: new FormGroup({
        taxes: this.fb.array([
          // this.setTaxes(),
        ]),
      })
    });
    // console.log('merchant ID: ', this.connectedId);
    // this.businessForm.get('merchantId').setValue(this.connectedId);

    //Helper function for testing validation | not for production
    this.interval = setInterval(() => {
      console.log(this.findInvalidControlsRecursive(this.businessForm));
    }, 15000);
  }

  //Helper function for testing validation | not for production
  findInvalidControlsRecursive(formToInvestigate: FormGroup | FormArray): string[] {
    var invalidControls: string[] = [];
    let recursiveFunc = (form: FormGroup | FormArray) => {
      Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        if (control.invalid) invalidControls.push(field);
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }
      });
    }
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }

  //SET TAXES
  setTaxes() {
    return this.fb.group({
      name: ['', Validators.required],
      rate: ['', Validators.required]
    });
  }

  addTaxes() {
    const control = this.businessForm.controls.businessAttributes['controls'].taxes as FormArray;
    control.push(this.setTaxes());
  }

  removeTaxes(i: number) {
    const control = this.businessForm.controls.businessAttributes['controls'].taxes as FormArray;
    control.removeAt(i);
  }

  //SET Business Hours
  setBusinessTimings(day) {
    return this.fb.group({
      day: day,
      isOpen: new FormControl(true),
      businessHours: this.fb.array([this.setBusinessHours()])
    });
  }

  setBusinessHours() {
    return this.fb.group({
      opensAt: [''],
      closesAt: ['']
    })
  }

  //SET Open Time
  setOpen() {
    return this.fb.group({
      opensAt: ['', Validators.required]
    });
  }

  //SET Close Time
  setClose() {
    return this.fb.group({
      closesAt: ['', Validators.required]
    });
  }

  //SET SERVICE PLATFORM TYPE
  setServicePlatform() {
    return this.fb.group({
      isEnabled: new FormControl(true),
      platformFees: [2.5, Validators.required],
      type: ['', Validators.required],
      businessTimings: this.fb.array([
        this.setBusinessTimings("Monday"),
        this.setBusinessTimings("Tuesday"),
        this.setBusinessTimings("Wednesday"),
        this.setBusinessTimings("Thursday"),
        this.setBusinessTimings("Friday"),
        this.setBusinessTimings("Saturday"),
        this.setBusinessTimings("Sunday")
      ])
    });
  }

  addServicePlatform() {
    const control = this.businessForm.controls.businessAttributes['controls'].servicePlatforms as FormArray;
    control.push(this.setServicePlatform());
  }

  removeServicePlatform(j: number) {
    const control = this.businessForm.controls.businessAttributes['controls'].servicePlatforms as FormArray;
    control.removeAt(j);
  }

  changeServicetype(e) {
    console.log('e = ', e.target.value);

    // this.businessForm.controls.businessAttributes['controls'].servicePlatforms['controls'].platforms['controls'].type.setValue(e.target.value, {
    //   onlySelf: true
    // });
    console.log(this.businessForm.controls.businessAttributes['controls']);
  }

  onBusinessSubmit() {

    // this.businessForm.value.businessAttributes.catalogues = [];
    // this.businessForm.value.businessAttributes.servicePlatforms.liveCatalogueId = "";
    // this.businessForm.value.businessAttributes.servicePlatforms.resources = [];
    // this.businessForm.value.businessAttributes.servicePlatforms.businessTimings = [];


    // this.businessForm.value.businessAttributes.servicePlatforms.businessTimings.push();

    let businessDetails = this.businessForm.value;
    businessDetails.businessAttributes.servicePlatforms = this.business.businessAttributes.servicePlatforms;
    this.businessFormatted.push(businessDetails);
    const formattedBusiness = JSON.parse(JSON.stringify(this.businessFormatted, (key, value) => {
      if (key === 'platformFees' || key === 'simPayXFee') {
        value = Number(value);
        return value;
      }
      return value;
    }));

    // tslint:disable-next-line: max-line-length
    this.createBusinessQuerySubscription = this.businessService.createBusinessForMerchant(formattedBusiness[0]).subscribe((response: any) => {
      console.log('Business created', response);

    });
    this.modalCtrl.dismiss();
    this.businessForm.reset();
  }

  ngOnDestroy(): void {
    if (this.businessQuerySubscription) {
      this.businessQuerySubscription.unsubscribe();
    }
    if (this.createBusinessQuerySubscription) {
      this.createBusinessQuerySubscription.unsubscribe();
    }
    clearInterval(this.interval);
  }
}
