import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';

@Component({
  selector: 'simpayx-select-day',
  templateUrl: './select-day.component.html',
  styleUrls: ['./select-day.component.scss'],
})
export class SelectDayComponent implements OnInit {
  customDays: any=[];
  isCustom: boolean;
  selectDay: string;
  @Input() weekday:[];
  constructor(public modalController: ModalController,public alertController: AlertController) { }

  ngOnInit() {}

  doneCustom() {
    this.isCustom = false;
    this.selectDay="Custom"
    console.log(this.customDays);
    if(this.customDays.length === 0){
      this.alertOpen("Please select days");
    }
    else{
      this.modalController.dismiss(this.customDays);
    }
    
  }

  async alertOpen(msg:string){
    const alert = await this.alertController.create({
      header: "Alert",
      message: msg,
      buttons: [
        {
          text: "Ok",
          role: "cancel",
        },
      ],
    });
    await alert.present();
  }
  cancelCustom() {
    this.isCustom = false
    this.customDays = []
    this.modalController.dismiss(this.customDays);
  }


  checkMultiValue(event, i) {
    console.log(event.detail.value, event.detail.checked, i)
    if (event.detail.checked) {
      this.customDays.push(event.detail.value)
    }
    else {
      this.customDays.splice(i, 1)
    }
  }

  isOptionSelected(selectDay, i) {
    const index = this.weekday.findIndex(
      (day) => selectDay === day[0]
    );
    return index > -1 ? true : false;
  }

}
