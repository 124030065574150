import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { MerchantBusinessService } from 'src/app/shared/services/merchant-business.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';

@Component({
  selector: 'app-edit-merchant',
  templateUrl: './edit-merchant.component.html',
  styleUrls: ['./edit-merchant.component.scss'],
})
export class EditMerchantComponent implements OnInit, OnDestroy {
  private querySubscription: Subscription;
  private queryGetMerchantSubscription: Subscription;
  merchant: any;
  @Input() merchantNameId: any;
  public response: BehaviorSubject<any>;

  constructor(private fb: FormBuilder,
              private modalController: ModalController,
              private merchantBusinessService: MerchantBusinessService,
              private eventEmitterService: EventEmitterService) {

  }

editMerchantForm = new FormGroup ({
    id: new FormControl('', [Validators.required, Validators.minLength(10)]),
    name: new FormControl('', [Validators.required, Validators.minLength(2)]),
    isEnabled: new FormControl(true)
  });

ngOnInit() {
  this.editMerchantForm.get('id').setValue(this.merchantNameId.editId);
  this.editMerchantForm.get('name').setValue(this.merchantNameId.merchantName);
  this.editMerchantForm.get('isEnabled').setValue(true);
  this.getMerchantsById(this.merchantNameId.editId);
}

getMerchantsById(merchantId) {
  this.queryGetMerchantSubscription = this.merchantBusinessService.getMerchantsById(merchantId)
  .valueChanges.subscribe((responseData: any) => {
    if (responseData !== undefined){
      console.log('getMerchantsById' , responseData.data.GetMerchantById);

    }
  });
}

  onEditMerchantSubmit() {
    const merchantData = {
      name: this.editMerchantForm.value.name,
      isEnabled: this.editMerchantForm.value.isEnabled
    };
    this.merchantBusinessService
    .editMerchant(this.editMerchantForm.value.id, merchantData).subscribe((response: any) => {
      console.log(response);
    });
    // this.eventEmitterService.refreshGrid();
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
    if (this.queryGetMerchantSubscription) {
      this.queryGetMerchantSubscription.unsubscribe();
    }
}
}
