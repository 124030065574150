<section class="form-modal-content">
  <form [formGroup]="tagMasterForm" (ngSubmit)="onCreateTagMasterSubmit()">

    <fieldset>
      <legend>
        <h3>NEW TAG TEMPLATE: </h3>
      </legend>
      <div>
        <ion-list lines="full" class="ion-no-margin ion-no-padding">
          <ion-item hidden>
            <ion-label position="floating">  ID
                <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input type="text" formControlName="id"></ion-input>
          </ion-item>
          <ion-item hidden>
            <ion-label position="floating"> Business ID
                <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input required type="text" formControlName="businessId"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating"> Name <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input required type="text" formControlName="name"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating"> Abbreviation
            </ion-label>
            <ion-input type="text" formControlName="abbreviation"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating"> Description 
            </ion-label>
            <ion-input type="text" formControlName="description"></ion-input>
          </ion-item>
        </ion-list>
      </div>
    </fieldset>
    <ion-button expand="block" type="submit" [disabled]="!tagMasterForm.valid">Create Tag</ion-button>
  </form>
</section>