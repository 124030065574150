<section class="form-modal-content">
  <form [formGroup]="catalogueForm" (ngSubmit)="onCatalogueSubmit()">
    <div formArrayName="catalogue">
      <div *ngFor="let catalogue of catalogueForm.get('catalogue')['controls']; let i=index">
        <fieldset>
          <legend>
            <h3>CATALOGUE</h3>
          </legend>
          <div [formGroupName]="i">
            <ion-list lines="full" class="ion-no-margin ion-no-padding">
              <ion-item hidden class="faded">
                <ion-label position="floating"> Business ID 
                    <ion-text color="danger">*</ion-text>
                </ion-label>
                <ion-input required type="text" formControlName="businessId"></ion-input>
            </ion-item>
              <ion-item>
                <ion-label position="floating"> Catalogue Name <ion-text color="danger">*</ion-text>
                </ion-label>
                <ion-input required type="text" formControlName="name"></ion-input>
              </ion-item>
            </ion-list>
            <!--Category Form -->
            <div formArrayName="categories">
              <div *ngFor="let category of catalogue.get('categories')['controls']; let j=index">
                  <fieldset>
                      <legend>
                          <h4>CATEGORY {{j+1}}</h4>
                      </legend>
                      <div [formGroupName]="j">
                          <ion-list lines="full" class="ion-no-margin ion-no-padding">
                              <ion-item>
                                <ion-label position="floating"> Name <ion-text color="danger">*</ion-text>
                                </ion-label>
                                <ion-input required type="text" formControlName="name"></ion-input>
                              </ion-item>
                              <ion-item>
                                <ion-label position="floating"> Description</ion-label>
                                <ion-input type="text" formControlName="description"></ion-input>
                              </ion-item>
                            </ion-list>
                            <!--Items-->
                            <div formArrayName="items">
                              <div *ngFor="let item of category.get('items')['controls']; let k=index">
                                  <fieldset>
                                      <legend>
                                          <h4>ITEMS {{k+1}}</h4>
                                      </legend>
                                      <div [formGroupName]="k">
                                          <ion-list lines="full" class="ion-no-margin ion-no-padding">
                                              <ion-item>
                                                <ion-label position="floating"> Name <ion-text color="danger">*</ion-text>
                                                </ion-label>
                                                <ion-input required type="text" formControlName="name"></ion-input>
                                              </ion-item>
                                              <ion-item>
                                                <ion-label position="floating"> Description
                                                </ion-label>
                                                <ion-input type="text" formControlName="description"></ion-input>
                                              </ion-item>
                                              <ion-item>
                                                <ion-label position="floating"> Price <ion-text color="danger">*</ion-text>
                                                </ion-label>
                                                <ion-input required type="text" formControlName="price" type="number"></ion-input>
                                              </ion-item>
                                            </ion-list>
                                            <!--Tags-->
                                            <div formArrayName="tags">
                                              <div *ngFor="let tag of item.get('tags')['controls']; let m=index">
                                                  <fieldset>
                                                      <legend>
                                                          <h4>TAGS {{m+1}}</h4>
                                                      </legend>
                                                      <div [formGroupName]="m">
                                                          <ion-list lines="full" class="ion-no-margin ion-no-padding">
                                                              <ion-item>
                                                                <ion-label position="floating"> Name</ion-label>
                                                                <ion-input type="text" formControlName="name"></ion-input>
                                                              </ion-item>
                                                            </ion-list>
                                                          <div>
                                                              <ion-button size="small" fill="clear"
                                                                  (click)="deleteTag(item.controls.tags, m)">
                                                                  <ion-icon slot="start" name="remove-circle-outline"></ion-icon>
                                                                  Clear Tag Form
                                                              </ion-button>
                                                          </div>
                                                      </div>
                                                  </fieldset>
                                              </div>
                                              <div><a role="button" (click)="addNewTag(item.controls.tags);">
                                                      <ion-icon name="add-circle-outline" slot="icon-only"></ion-icon> Add A
                                                      Tag?
                                                  </a></div>
                                          </div>
                                          <div>
                                              <ion-button size="small" fill="clear"
                                                  (click)="deleteItem(category.controls.items, k)">
                                                  <ion-icon slot="start" name="remove-circle-outline"></ion-icon>
                                                  Clear Item Form
                                              </ion-button>
                                          </div>
                                      </div>
                                  </fieldset>
                              </div>
                              <div><a role="button" (click)="addNewItem(category.controls.items);">
                                      <ion-icon name="add-circle-outline" slot="icon-only"></ion-icon> Add An
                                      Item?
                                  </a></div>
                          </div>
                          <div>
                              <ion-button size="small" fill="clear"
                                  (click)="deleteCategory(catalogue.controls.categories, j)">
                                  <ion-icon slot="start" name="remove-circle-outline"></ion-icon>
                                  Clear Category Form
                              </ion-button>
                          </div>
                      </div>
                  </fieldset>
              </div>
              <div><a role="button" (click)="addNewCategory(catalogue.controls.categories);">
                      <ion-icon name="add-circle-outline" slot="icon-only"></ion-icon> Add A
                      Category?
                  </a></div>
          </div>
          </div>
        </fieldset>
      </div>
    </div><br>
    <ion-button expand="block" type="submit" [disabled]="!catalogueForm.valid">Create catalogue</ion-button>
  </form>
</section>