<ion-card *ngIf="business?.businessAttributes.servicePlatforms">
    <ion-card-header class="d-flex ion-align-items-center">
        <ion-icon name="restaurant-outline" color="primary" size="large"></ion-icon>
        <ion-label class="ion-padding-start spx-bold spx-font-color-black spx-font-16">Serving Now</ion-label>
    </ion-card-header>

    <ion-card-content>
        <ion-grid class="ion-no-padding" *ngFor="let platform of business?.businessAttributes.servicePlatforms;index as i">
            <ion-card>
                <ion-card-content>
                    <ion-row>
                        <ion-col size="6">
                            <ion-label class="spx-bold spx-font-color-black spx-font-16">{{platform.type}}</ion-label>
                            <!-- <ion-text>{{platform.type}}</ion-text> -->
                        </ion-col>
                        <ion-col size="2">
                            <ion-toggle [(ngModel)]="platform.isEnabled"
                                (ngModelChange)="onServiceStatusChange(platform)" color="primary"></ion-toggle>
                        </ion-col>
                        <ion-col size="4" class="ion-text-right">
                            <a (click)="manageHours(platform.type,i)" class="underline">Manage business hours</a>
                        </ion-col>
                    </ion-row>
                    <simpayx-business-hours [business]="business" [businessTimings]="businessTimings" [type]="type" [isAddedHour]="isAddedHour"
                        [expanded]="platform.type==type" [selectedAverageServiceTime]="selectedAverageServiceTime" [serviceCapacity]="serviceCapacity"></simpayx-business-hours>
                </ion-card-content>
            </ion-card>
        </ion-grid>
    </ion-card-content>
</ion-card>