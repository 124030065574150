import { Component, OnInit, OnDestroy } from '@angular/core';
import { MerchantBusinessService } from 'src/app/shared/services/merchant-business.service';
import { Subscription } from 'rxjs';
import { FeatureToggleService } from 'src/app/shared/services/feature-toggle.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-is-enabled',
  templateUrl: './is-enabled.component.html',
  styleUrls: ['./is-enabled.component.scss'],
})
export class IsEnabledComponent implements OnInit, OnDestroy {
  isEnabled: boolean;
  params: any;
  querySubscription: Subscription;

  constructor(
    private merchantBusinessService: MerchantBusinessService,
    private featureService:FeatureToggleService,
    public router: Router
  ) { }

  ngOnDestroy(): void {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  agInit(params) {
    this.params = params;
    this.isEnabled = this.params.data.isEnabled;
  }

  ngOnInit() {

  }

  toggleMerchantEnabled(){
    this.isEnabled = !this.isEnabled;

    const merchantData = {
      "name": this.params.data.name,
      "isEnabled": this.isEnabled
    };

    this.querySubscription = this.merchantBusinessService
    .editMerchant(this.params.data.id, merchantData).subscribe((response: any) => {
      console.log(response);
    })
  }
  toggleFeatureValue(){
      this.featureService.toggleFeature(this.params.data.name,!this.isEnabled).subscribe()
  }
}


