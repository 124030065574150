import { Injectable } from "@angular/core";

import { Apollo } from "apollo-angular";
import {
  ALL_MERCHANTS_QUERY,
  MERCHANTS_BY_ID_QUERY,
  ALL_BUSINESSES_FOR_MERCHANT,
} from "src/app/feature/merchant-details/merchant-queries";
import {
  Merchant,
  MerchantInput,
} from "src/app/feature/merchant-details/merchant.model";
import gql from "graphql-tag";
import { BusinessInput, PlatformType, ServicePlatformSubType } from "src/app/feature/business-details/business.model";
import {
  UPDATE_BUSINESS,
  DELETE_BUSINESS,
  BUSINESS_BY_ID_QUERY,
} from "./business.service";
import { EventEmitterService } from "./event-emitter.service";

export const CREATE_MERCHANT = gql`
  mutation CreateMerchant($merchant: MerchantInput) {
    CreateMerchant(merchant: $merchant) {
      name
      id
      businesses {
        id
        name
        merchantId
        vertical
        address {
          line1
          line2
          state
          city
          country
          zip
          timeZone
        }
        isEnabled
        digitalAddresses {
          addressType
          address
          preferred
        }
        businessAttributes {
          catalogues
          taxes {
            name
            rate
          }
          theme
          servicePlatforms {
            type
            liveCatalogueId
            platformFees
            isEnabled
            resources {
              id
              name
              isEnabled
              attributes
              serviceCapacity
              serviceTimeInMin
            }
            businessTimings
            avgServiceTimeInMin
          }
        }
      }
    }
  }
`;

export const UPDATE_MERCHANT = gql`
  mutation updateMerchant($merchantId: String, $merchant: MerchantInput) {
    UpdateMerchant(merchantId: $merchantId, merchant: $merchant) {
      id
      name
      isEnabled
      businesses {
        id
        name
        merchantId
        vertical
        address {
          line1
          line2
          state
          city
          country
          zip
          timeZone
        }
        isEnabled
        digitalAddresses {
          addressType
          address
          preferred
        }
        businessAttributes {
          catalogues
          taxes {
            name
            rate
          }
          theme
          servicePlatforms {
            type
            liveCatalogueId
            platformFees
            isEnabled
            resources {
              id
              name
              isEnabled
              attributes
              serviceCapacity
              serviceTimeInMin
            }
            businessTimings
            avgServiceTimeInMin
          }
        }
      }
    }
  }
`;

export const DELETE_MERCHANT = gql`
  mutation DeleteMerchant($merchantId: String) {
    DeleteMerchant(merchantId: $merchantId)
  }
`;

export const CATALOGE_BY_ID = gql`
  query {
    GetCatalogueById(
      businessId: "5f03021cf4f58f61b28ae4f7"
      catalogueId: "5f0714cb978f5d6da3916edd"
    ) {
      name
      categories {
        name
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class MerchantBusinessService {
  name: string;
  merchant: MerchantInput;

  private eventEmitterService: EventEmitterService;
  constructor(public apollo: Apollo) {}

  public getAllMerchants() {
    console.log("GOT ALL MERCHANTS");
    return this.apollo.watchQuery({
      query: ALL_MERCHANTS_QUERY,
      // pollInterval: 10000,
      fetchPolicy: "cache-and-network",
    });
  }

  public getMerchantsById(merchantId) {
    return this.apollo.watchQuery({
      query: MERCHANTS_BY_ID_QUERY,

      variables: {
        // tslint:disable-next-line:object-literal-shorthand
        merchantId: merchantId,
      },
    });
  }

  public getBusinessById(merchantId, businessId) {
    console.log("inside getBusinessByID");
    return this.apollo.watchQuery({
      query: BUSINESS_BY_ID_QUERY,
      fetchPolicy: "cache-and-network",
      variables: {
        // tslint:disable-next-line:object-literal-shorthand
        merchantId: merchantId,
        // tslint:disable-next-line:object-literal-shorthand
        businessId: businessId,
      },
    });
  }

  public getAllBusinessesForMerchant(merchantId) {
    console.log("inside get all businesses for merchant with " + merchantId);
    return this.apollo.watchQuery({
      query: ALL_BUSINESSES_FOR_MERCHANT,
      // pollInterval: 10000,
      fetchPolicy: "cache-and-network",
      variables: {
        // tslint:disable-next-line:object-literal-shorthand
        merchantId: merchantId,
      },
    });
  }

  public createMerchant(merchant) {
    console.log("inside create merchant");
    return this.apollo.mutate({
      mutation: CREATE_MERCHANT,
      refetchQueries: ["allmerchants"],
      variables: {
        // tslint:disable-next-line:object-literal-shorthand
        merchant: merchant,
      },
    });
  }

  public editMerchant(merchantId, merchant: MerchantInput) {
    return this.apollo.mutate({
      mutation: UPDATE_MERCHANT, // Need to create
      //refetchQueries: ['allmerchants'],
      variables: {
        // tslint:disable-next-line:object-literal-shorthand
        merchantId: merchantId,
        // tslint:disable-next-line:object-literal-shorthand
        merchant: merchant,
      },
    });
  }

  public deleteMerchant(merchantId) {
    console.log("inside delete merchant");
    return this.apollo.mutate({
      mutation: DELETE_MERCHANT,

      variables: {
        // tslint:disable-next-line:object-literal-shorthand
        merchantId: merchantId,
      },
    });
  }

  public editBusiness(merchantId, businessId, businessData: BusinessInput) {
    businessData.businessAttributes.servicePlatforms.forEach(service=>{
      if(service.type !== PlatformType.RESERVATION){
        service.subtype = ServicePlatformSubType.RESOURCE_PLATFORM;
      }
      else{
        service.subtype = ServicePlatformSubType.SLOT_PLATFORM;
      }
    })
    console.log(businessData)
    return this.apollo.mutate({
      mutation: UPDATE_BUSINESS, // Need to create
      refetchQueries: ["getAllBusinessesForMerchant"],
      variables: {
        // tslint:disable-next-line:object-literal-shorthand
        merchantId: merchantId,
        // tslint:disable-next-line:object-literal-shorthand
        businessId: businessId,
        // tslint:disable-next-line:object-literal-shorthand
        business: businessData,
      },
    });
  }

  public deleteBusiness(merchantId, businessId) {
    console.log("inside delete merchant");
    return this.apollo.mutate({
      mutation: DELETE_BUSINESS,

      variables: {
        // tslint:disable-next-line:object-literal-shorthand
        merchantId: merchantId,
        // tslint:disable-next-line:object-literal-shorthand
        businessId: businessId,
      },
    });
  }

  public getCatalouge() {
    return this.apollo.watchQuery({
      query: CATALOGE_BY_ID,
      fetchPolicy: "cache-and-network",
    });
  }

  getBusinessForMerchant(merchantId: string) {
    this.getAllBusinessesForMerchant(merchantId).valueChanges.subscribe(
      (businessData: any) => {
        if (businessData.data !== undefined) {
          console.log(
            "business-result-test",
            businessData.data.GetAllBusinessesForMerchant
          );
          const getAllBusinessesData =
            businessData.data.GetAllBusinessesForMerchant;
          this.eventEmitterService.refreshGrid(getAllBusinessesData);
        }
      }
    );
  }
}
