import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit {
  modalTitle: string;
  modalId: string;
  editId: string;
  merchantId: string;
  businessId: string;
  connectedId: string;
  merchantNameId: any;
  allData: any;

  constructor(
    public modalController: ModalController,
    private navParams: NavParams
  ) {}

  ngOnInit() {
    console.table(this.navParams);
    this.modalId = this.navParams.data.paramID;
    this.modalTitle = this.navParams.data.paramTitle;
    this.connectedId = this.navParams.data.connectedId;
    this.editId = this.navParams.data.editID;
    this.merchantNameId = {
      merchantName: this.navParams.data.merchantName,
      editId: this.navParams.data.editID,
    };
    this.merchantId = this.navParams.data.merchantID;
    this.businessId = this.navParams.data.businessID;
    this.allData = this.navParams.data.allData;
    console.log(this.allData);
    console.log(this.navParams.get("connectedId"));
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}
