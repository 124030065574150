<section class="form-modal-content">
  <form [formGroup]="customMasterForm" (ngSubmit)="onCreateCustomMasterSubmit()">
    <div formArrayName="customization">
      <div *ngFor="let customization of customMasterForm.get('customization')['controls']; let i=index">
    <fieldset>
      <legend>
        <h3>NEW CUSTOMIZATION TEMPLATE: </h3>
      </legend>
      <div [formGroupName]="i">
        <ion-list lines="full" class="ion-no-margin ion-no-padding">
          <ion-item hidden>
            <ion-label position="floating"> Business ID
                <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input required type="text" formControlName="businessId"></ion-input>
          </ion-item>
          <ion-item hidden>
            <ion-label>Is Enabled?</ion-label>
            <ion-toggle checked="isEnabled" formControlName="isEnabled"></ion-toggle>
          </ion-item>
          <ion-item>
            <ion-label position="floating"> Name <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input required type="text" formControlName="name"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating"> Description 
            </ion-label>
            <ion-input type="text" formControlName="description"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Optional?</ion-label>
            <ion-toggle [(checked)]="isOptional" formControlName="isOptional"></ion-toggle>
          </ion-item>
        </ion-list>
        <!--Options-->
        <div formArrayName="options">
          <div *ngFor="let option of customization.get('options')['controls']; let j=index">
            <fieldset>
              <legend>
                <h4>OPTION {{j+1}}</h4>
              </legend>
              <div [formGroupName]="j">
                <ion-list lines="full" class="ion-no-margin ion-no-padding">
                  <ion-item>
                    <ion-label position="floating"> Name <ion-text color="danger">*</ion-text>
                    </ion-label>
                    <ion-input required type="text" formControlName="name"></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label position="floating"> Price
                    </ion-label>
                    <ion-input type="text" formControlName="price" type="number"></ion-input>
                  </ion-item>
                  <ion-item>
                    <ion-label>Set as Default?</ion-label>
                    <ion-toggle [(checked)]="isDefault" (click)="updateDefaultOptionNum(customization.controls.options);" formControlName="isDefault"></ion-toggle>
                </ion-item>
                </ion-list>
                <div>
                  <ion-button size="small" fill="clear"
                      (click)="deleteOption(customization.controls.options, j)">
                      <ion-icon slot="start" name="remove-circle-outline"></ion-icon>
                      Remove this Option
                  </ion-button>
              </div>
              </div>
            </fieldset>
          </div>
          <div>
            <a role="button" (click)="addNewOption(customization.controls.options);">
              <ion-icon name="add-circle-outline" slot="icon-only"></ion-icon> Add An
              Option?
            </a>
          </div>
        </div>
        <ion-list>
          <!--Set Choice Upper Limit-->
          <ion-item>
            <ion-label position="floating"> <strong>Set the maximum choices out of <span class="italics">{{customization.get('options')['controls'].length}}</span> total</strong><br>
              Cannot be less than default options <span class="italics">({{isDefaultNum}})</span>
            </ion-label>
            <ion-input 
            type="number"  
            min={{isDefaultNum}} 
            max={{choiceLimitNum}} 
            value={{isDefaultNum}} 
            formControlName="choiceUpperLimit"
            placeholder={{isDefaultNum}}></ion-input>
          </ion-item>
          <!--Set Choice Lower Limit-->
          <ion-item>
            <ion-label position="floating"> <strong>Set the minimum choices out of <span class="italics">{{customization.get('options')['controls'].length}}</span> total</strong><br>
              Cannot be less than default options <span class="italics">({{isDefaultNum}})</span>
            </ion-label>
            <ion-input 
            type="number"  
            min={{isDefaultNum}} 
            max={{choiceLimitNum}} 
            value={{isDefaultNum}} 
            formControlName="choiceLowerLimit"
            placeholder={{isDefaultNum}}></ion-input>
          </ion-item>
        </ion-list>
        <!--Options End-->
      </div>
    </fieldset>
    </div>
    </div><br>
    <ion-button expand="block" type="submit" [disabled]="!customMasterForm.valid">Create Customization</ion-button>
  </form>
</section>

