import gql from "graphql-tag";

export const ALL_CATALOGUE_FOR_BUSINESS = gql`
  query getAllCatalogues($businessId: String!) {
      GetAllCatalogues(businessId: $businessId) {
        id
        name
        isEnabled
        businessId
        isEnabled
        categories {
          id
          name
          description
          isEnabled
          
          items {
            name
            description
            price
            
            addOns {
              id
              name
              description
              price
              isEnabled
              tags {
                id
                businessId
                name
                description
                abbreviation
                imageUrl
              }
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            tags {
              id
              name
              businessId
              description
              abbreviation
              imageUrl
            }
            variants {
              name
              description
              price
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          subCategories {
            id
            name
            description
            isEnabled
            
            items {
              name
              description
              price
              
              addOns {
                id
                name
                description
                price
                isEnabled
                tags {
                  id
                  businessId
                  name
                  description
                  abbreviation
                  imageUrl
                }
                variants {
                  name
                  description
                  price
                  customizations {
                    id
                    businessId
                    name
                    options {
                      name
                      price
                    }
                    description
                    choiceLimit
                    choiceUpperLimit
                    choiceLowerLimit
                    isOptional
                    isEnabled
                  }
                }
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              tags {
                id
                name
                businessId
                description
                abbreviation
                imageUrl
              }
              variants {
                name
                description
                price
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            subCategories {
              id
              name
              description
              isEnabled
              
              items {
                name
                description
                price
                
                addOns {
                  id
                  name
                  description
                  price
                  isEnabled
                  tags {
                    id
                    businessId
                    name
                    description
                    abbreviation
                    imageUrl
                  }
                  customizations {
                    id
                    businessId
                    name
                    options {
                      name
                      price
                    }
                    
                    description
                    choiceLimit
                    choiceUpperLimit
                    choiceLowerLimit
                    isOptional
                    isEnabled
                  }
                }
                tags {
                  id
                  name
                  businessId
                  description
                  abbreviation
                  imageUrl
                }
                variants {
                  name
                  description
                  price
                  customizations {
                    id
                    businessId
                    name
                    options {
                      name
                      price
                    }
                    description
                    choiceLimit
                    choiceUpperLimit
                    choiceLowerLimit
                    isOptional
                    isEnabled
                  }
                }
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
            }
          }
        }
      }
    }
`;

export const CATALOGUE_FOR_BUSINESS_BY_ID_QUERY = gql`
  query
    getCatalogueById($businessId: String!, $catalogueId: String!) {
      GetCatalogueById(businessId: $businessId, catalogueId: $catalogueId) {
        id
        name
        isEnabled
        categories {
          id
          name
          isEnabled
          description
          
          items {
            id
            name
            description
            price
            isEnabled
            
            addOns {
              id
              name
              description
              price
              isEnabled
              tags {
                id
                name
                businessId
                description
                abbreviation
                imageUrl
              }
              variants {
                name
                description
                price
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                  
                  isDefault
                }
                
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            tags {
              id
              name
              businessId
              description
              abbreviation
              imageUrl
            }
            variants {
              name
              description
              price
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            customizations {
              id
              businessId
              name
              options {
                name
                price
                
                isDefault
              }
              
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          subCategories {
            id
            name
            description
            isEnabled
            
            items {
              name
              description
              price
              
              addOns {
                id
                name
                description
                price
                isEnabled
                tags {
                  id
                  businessId
                  name
                  description
                  abbreviation
                  imageUrl
                }
                variants {
                  name
                  description
                  price
                  customizations {
                    id
                    businessId
                    name
                    options {
                      name
                      price
                    }
                    description
                    choiceLimit
                    choiceUpperLimit
                    choiceLowerLimit
                    isOptional
                    isEnabled
                  }
                }
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              tags {
                id
                name
                businessId
                description
                abbreviation
                imageUrl
              }
              variants {
                name
                description
                price
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            subCategories {
              id
              name
              description
              isEnabled
              
              items {
                name
                description
                price
                
                addOns {
                  id
                  name
                  description
                  price
                  isEnabled
                  tags {
                    id
                    businessId
                    name
                    description
                    abbreviation
                    imageUrl
                  }
                  variants {
                    name
                    description
                    price
                    customizations {
                      id
                      businessId
                      name
                      options {
                        name
                        price
                      }
                      description
                      choiceLimit
                      choiceUpperLimit
                      choiceLowerLimit
                      isOptional
                      isEnabled
                    }
                  }
                  customizations {
                    id
                    businessId
                    name
                    options {
                      name
                      price
                    }
                    
                    description
                    choiceLimit
                    choiceUpperLimit
                    choiceLowerLimit
                    isOptional
                    isEnabled
                  }
                }
                tags {
                  id
                  name
                  businessId
                  description
                  abbreviation
                  imageUrl
                }
                variants {
                  name
                  description
                  price
                  customizations {
                    id
                    businessId
                    name
                    options {
                      name
                      price
                    }
                    description
                    choiceLimit
                    choiceUpperLimit
                    choiceLowerLimit
                    isOptional
                    isEnabled
                  }
                }
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
            }
          }
        }
      }
    }
`;

export const CREATE_CATALOGUE_FOR_BUSINESS = gql`
  mutation CreateCatalogue($catalogue: CatalogueInput) {
    CreateCatalogue(catalogue:$catalogue) {
      id
      name
      businessId
      isEnabled
      categories {
        id
        name
        description
        isEnabled
        
        items {
          name
          description
          price
          
          tags {
            id
            businessId
            name
            description
            abbreviation
            imageUrl
          }
          variants {
            name
            description
            price
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          customizations {
            id
            businessId
            name
            options {
              name
              price
              
              isDefault
            }
            
            description
            choiceLimit
            choiceUpperLimit
            choiceLowerLimit
            isOptional
            isEnabled
          }
        }
        subCategories {
          id
          name
          description
          isEnabled
          
          items {
            name
            description
            price
            
            addOns {
              id
              name
              description
              price
              isEnabled
              tags {
                id
                businessId
                name
                description
                abbreviation
                imageUrl
              }
              variants {
                name
                description
                price
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            tags {
              id
              name
              businessId
              description
              abbreviation
              imageUrl
            }
            variants {
              name
              description
              price
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          subCategories {
            id
            name
            description
            isEnabled
            
            items {
              name
              description
              price
              
              addOns {
                id
                name
                description
                price
                isEnabled
                tags {
                  id
                  businessId
                  name
                  description
                  abbreviation
                  imageUrl
                }
                variants {
                  name
                  description
                  price
                  customizations {
                    id
                    businessId
                    name
                    options {
                      name
                      price
                    }
                    description
                    choiceLimit
                    choiceUpperLimit
                    choiceLowerLimit
                    isOptional
                    isEnabled
                  }
                }
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              tags {
                id
                name
                businessId
                description
                abbreviation
                imageUrl
              }
              variants {
                name
                description
                price
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_CATALOGUE_FOR_BUSINESS = gql`
mutation updateCatalogue($businessId: String, $catalogueId: String, $catalogue: CatalogueInput){
    UpdateCatalogue(businessId: $businessId, catalogueId: $catalogueId, catalogue: $catalogue) {
      id
      name
      businessId
      isEnabled
      categories {
        id
        name
        description
        isEnabled
        
        items {
          name
          description
          price
          isEnabled
          
          addOns {
            id
            name
            description
            price
            isEnabled
            tags {
              id
              name
              businessId
              description
              abbreviation
              imageUrl
            }
            variants {
              name
              description
              price
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            customizations {
              id
              businessId
              name
              options {
                name
                price
                
                isDefault
              }
              
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          tags {
            id
            name
            businessId
            description
            abbreviation
            imageUrl
          }
          variants {
            name
            description
            price
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          customizations {
            id
            businessId
            name
            options {
              name
              price
              
              isDefault
            }
            
            description
            choiceLimit
            choiceUpperLimit
            choiceLowerLimit
            isOptional
            isEnabled
          }
        }
        subCategories {
          id
          name
          description
          isEnabled
          
          items {
            name
            description
            price
            
            addOns {
              id
              name
              description
              price
              isEnabled
              tags {
                id
                businessId
                name
                description
                abbreviation
                imageUrl
              }
              variants {
                name
                description
                price
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            tags {
              id
              name
              businessId
              description
              abbreviation
              imageUrl
            }
            variants {
              name
              description
              price
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          subCategories {
            id
            name
            description
            isEnabled
            
            items {
              name
              description
              price
              
              addOns {
                id
                name
                description
                price
                isEnabled
                tags {
                  id
                  businessId
                  name
                  description
                  abbreviation
                  imageUrl
                }
                variants {
                  name
                  description
                  price
                  customizations {
                    id
                    businessId
                    name
                    options {
                      name
                      price
                    }
                    description
                    choiceLimit
                    choiceUpperLimit
                    choiceLowerLimit
                    isOptional
                    isEnabled
                  }
                }
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              tags {
                id
                name
                businessId
                description
                abbreviation
                imageUrl
              }
              variants {
                name
                description
                price
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_CATALOGUE = gql`
mutation deleteCatalogue($businessId: String, $catalogueId: String){
    DeleteCatalogue(businessId: $businessId, catalogueId: $catalogueId)
  }
`;

export const ALL_CATEGORIES = gql`
  query GetAllCategory($businessId: String!) {
        GetAllCategory(businessId: $businessId) {
        id
        name
        description
        isEnabled
        
        items {
          name
          description
          price
          isEnabled
          
          addOns {
            id
            name
            description
            price
            isEnabled
            tags {
              id
              name
              businessId
              description
              abbreviation
              imageUrl
            }
            variants {
              name
              description
              price
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            customizations {
              id
              businessId
              name
              options {
                name
                price
                
                isDefault
              }
              
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          tags {
            id
            name
            businessId
            description
            abbreviation
            imageUrl
          }
          variants {
            name
            description
            price
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          customizations {
            id
            businessId
            name
            options {
              name
              price
              
              isDefault
            }
            
            description
            choiceLimit
            choiceUpperLimit
            choiceLowerLimit
            isOptional
            isEnabled
          }
        }
        subCategories {
          id
          name
          description
          isEnabled
          
          items {
            name
            description
            price
            
            addOns {
              id
              name
              description
              price
              isEnabled
              tags {
                id
                businessId
                name
                description
                abbreviation
                imageUrl
              }
              variants {
                name
                description
                price
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            tags {
              id
              name
              businessId
              description
              abbreviation
              imageUrl
            }
            variants {
              name
              description
              price
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          subCategories {
            id
            name
            description
            isEnabled
            
            items {
              name
              description
              price
              
              addOns {
                id
                name
                description
                price
                isEnabled
                tags {
                  id
                  businessId
                  name
                  description
                  abbreviation
                  imageUrl
                }
                variants {
                  name
                  description
                  price
                  customizations {
                    id
                    businessId
                    name
                    options {
                      name
                      price
                    }
                    description
                    choiceLimit
                    choiceUpperLimit
                    choiceLowerLimit
                    isOptional
                    isEnabled
                  }
                }
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              tags {
                id
                name
                businessId
                description
                abbreviation
                imageUrl
              }
              variants {
                name
                description
                price
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
          }
        }
      }
    }
`;

export const CREATE_CATEGORY_FOR_BUSINESS = gql`
  mutation CreateCategory($category: CategoryInput, $businessId: String) {
    CreateCategory(category:$category, businessId: $businessId) {
      id
      name
      description
      
      isEnabled
      items {
        name
        description
        price
        isEnabled
        
        addOns {
          id
          name
          description
          price
          isEnabled
          tags {
            id
            name
            businessId
            description
            abbreviation
            imageUrl
          }
          variants {
            name
            description
            price
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          customizations {
            id
            businessId
            name
            options {
              name
              price
              
              isDefault
            }
            
            description
            choiceLimit
            choiceUpperLimit
            choiceLowerLimit
            isOptional
            isEnabled
          }
        }
        tags {
          id
          name
          businessId
          description
          abbreviation
          imageUrl
        }
        variants {
          name
          description
          price
          customizations {
            id
            businessId
            name
            options {
              name
              price
            }
            description
            choiceLimit
            choiceUpperLimit
            choiceLowerLimit
            isOptional
            isEnabled
          }
        }
        customizations {
          id
          businessId
          name
          options {
            name
            price
            
            isDefault
          }
          
          description
          choiceLimit
          choiceUpperLimit
          choiceLowerLimit
          isOptional
          isEnabled
        }
      }
      subCategories {
        id
        name
        description
        isEnabled
        
        items {
          name
          description
          price
          
          addOns {
            id
            name
            description
            price
            isEnabled
            tags {
              id
              businessId
              name
              description
              abbreviation
              imageUrl
            }
            variants {
              name
              description
              price
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          tags {
            id
            name
            businessId
            description
            abbreviation
            imageUrl
          }
          variants {
            name
            description
            price
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          customizations {
            id
            businessId
            name
            options {
              name
              price
            }
            
            description
            choiceLimit
            choiceUpperLimit
            choiceLowerLimit
            isOptional
            isEnabled
          }
        }
        subCategories {
          id
          name
          description
          isEnabled
          
          items {
            name
            description
            price
            
            addOns {
              id
              name
              description
              price
              isEnabled
              tags {
                id
                businessId
                name
                description
                abbreviation
                imageUrl
              }
              variants {
                name
                description
                price
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            tags {
              id
              name
              businessId
              description
              abbreviation
              imageUrl
            }
            variants {
              name
              description
              price
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_CATEGORY_FOR_BUSINESS = gql`
  mutation UpdateCategory($businessId: String,$categoryId: String,$category: CategoryInput) {
    UpdateCategory(category:$category,categoryId:$categoryId, businessId: $businessId) {
      id
      name
      description
      
      isEnabled
      items {
        name
        description
        price
        isEnabled
        
        addOns {
          id
          name
          description
          price
          isEnabled
          tags {
            id
            name
            businessId
            description
            abbreviation
            imageUrl
          }
          variants {
            name
            description
            price
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          customizations {
            id
            businessId
            name
            options {
              name
              price
              
              isDefault
            }
            
            description
            choiceLimit
            choiceUpperLimit
            choiceLowerLimit
            isOptional
            isEnabled
          }
        }
        tags {
          id
          name
          businessId
          description
          abbreviation
          imageUrl
        }
        variants {
          name
          description
          price
          customizations {
            id
            businessId
            name
            options {
              name
              price
            }
            description
            choiceLimit
            choiceUpperLimit
            choiceLowerLimit
            isOptional
            isEnabled
          }
        }
        customizations {
          id
          businessId
          name
          options {
            name
            price
            
            isDefault
          }
          
          description
          choiceLimit
          choiceUpperLimit
          choiceLowerLimit
          isOptional
          isEnabled
        }
      }
      subCategories {
        id
        name
        description
        isEnabled
        
        items {
          name
          description
          price
          
          addOns {
            id
            name
            description
            price
            isEnabled
            tags {
              id
              businessId
              name
              description
              abbreviation
              imageUrl
            }
            variants {
              name
              description
              price
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          tags {
            id
            name
            businessId
            description
            abbreviation
            imageUrl
          }
          variants {
            name
            description
            price
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          customizations {
            id
            businessId
            name
            options {
              name
              price
            }
            
            description
            choiceLimit
            choiceUpperLimit
            choiceLowerLimit
            isOptional
            isEnabled
          }
        }
        subCategories {
          id
          name
          description
          isEnabled
          
          items {
            name
            description
            price
            
            addOns {
              id
              name
              description
              price
              isEnabled
              tags {
                id
                businessId
                name
                description
                abbreviation
                imageUrl
              }
              variants {
                name
                description
                price
                customizations {
                  id
                  businessId
                  name
                  options {
                    name
                    price
                  }
                  description
                  choiceLimit
                  choiceUpperLimit
                  choiceLowerLimit
                  isOptional
                  isEnabled
                }
              }
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            tags {
              id
              name
              businessId
              description
              abbreviation
              imageUrl
            }
            variants {
              name
              description
              price
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
        }
      }
    }
  }
`;

export const DELETE_CATEGORY = gql`
mutation DeleteCategory($businessId: String, $categoryId: String){
  DeleteCategory(businessId: $businessId, categoryId: $categoryId)
  }
`;

export const ALL_ITEMS = gql`
  query
    GetAllItems($businessId: String!) {
        GetAllItems(businessId: $businessId) {
          id
          name
          description
          price
          isEnabled
          addOns {
            id
            name
            description
            price
            isEnabled
            tags {
              id
              name
              businessId
              description
              abbreviation
              imageUrl
            }
            variants {
              name
              description
              price
              customizations {
                id
                businessId
                name
                options {
                  name
                  price
                }
                description
                choiceLimit
                choiceUpperLimit
                choiceLowerLimit
                isOptional
                isEnabled
              }
            }
            customizations {
              id
              businessId
              name
              options {
                name
                price
                
                isDefault
              }
              
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          tags {
            id
            name
            businessId
            description
            abbreviation
            imageUrl
          }
          variants {
            name
            description
            price
            customizations {
              id
              businessId
              name
              options {
                name
                price
              }
              description
              choiceLimit
              choiceUpperLimit
              choiceLowerLimit
              isOptional
              isEnabled
            }
          }
          customizations {
            id
            businessId
            name
            options {
              name
              price
              
              isDefault
            }
            
            description
            choiceLimit
            choiceUpperLimit
            choiceLowerLimit
            isOptional
            isEnabled
          }
      }
    }
`;

export const CREATE_ITEM_FOR_BUSINESS = gql`
  mutation CreateItem($businessId: String,$item: ItemInput) {
    CreateItem(item:$item, businessId: $businessId) {
      id
      name
      description
      price
      isEnabled
      
      addOns {
        id
        name
        description
        price
        isEnabled
      }
      tags {
        id
        businessId
        name
        description
        abbreviation
        imageUrl
      }
      variants {
        name
        description
        price
        customizations {
          id
          businessId
          name
          options {
            name
            price
          }
          description
          choiceLimit
          choiceUpperLimit
          choiceLowerLimit
          isOptional
          isEnabled
        }
      }
      customizations {
        id
        businessId
        name
        description
        isEnabled
        
        choiceLimit
        choiceUpperLimit
        choiceLowerLimit
        isOptional
        options {
          name
          price
          
          isDefault
        }
      }
    }
  }
`;

export const UPDATE_ITEM_FOR_BUSINESS = gql`
  mutation UpdateItem($businessId: String, $itemId: String, $item: ItemInput) {
    UpdateItem(item:$item, itemId:$itemId, businessId: $businessId) {
      id
      name
      description
      price
      isEnabled
      addOns {
        id
        name
        description
        price
        isEnabled
      }
      tags {
        id
        businessId
        name
        description
        abbreviation
        imageUrl
      }
      variants {
        name
        description
        price
        customizations {
          id
          businessId
          name
          options {
            name
            price
          }
          description
          choiceLimit
          choiceUpperLimit
          choiceLowerLimit
          isOptional
          isEnabled
        }
      }
      customizations {
        id
        name
        description
        isEnabled
        
        choiceLimit
        choiceUpperLimit
        choiceLowerLimit
        isOptional
        options {
          name
          price
          
          isDefault
        }
      }
    }
  }
`;

export const DELETE_ITEM = gql`
mutation DeleteItem($businessId: String, $itemId: String){
  DeleteItem(businessId: $businessId, itemId: $itemId)
  }
`;

export const ALL_CUSTOMIZATIONS = gql`
query
  GetAllCustomization($businessId: String!){
    GetAllCustomization(businessId: $businessId){
      id
      name
      options {
        name
        price
        
        isDefault
      }
      
      description
      choiceLimit
      choiceUpperLimit
      choiceLowerLimit
      isOptional
      isEnabled
    }
  }
`;

export const CREATE_CUSTOMIZATIONS_FOR_BUSINESS = gql`
  mutation CreateCustomization($customization: CustomizationInput) {
    CreateCustomization(customization:$customization) {
      id
      businessId
      name
      description
      isEnabled
      
      choiceLimit
      choiceUpperLimit
      choiceLowerLimit
      isOptional
      options {
        name
        price
        
        isDefault
      }
    }
  }
`;

export const UPDATE_CUSTOMIZATIONS_FOR_BUSINESS = gql`
  mutation UpdateCustomization($businessId: String,$customizationId: String,$customization: CustomizationInput) {
    UpdateCustomization(businessId:$businessId,customizationId:$customizationId,customization:$customization) {
      id
      name
      description
      isEnabled
      
      choiceLimit
      choiceUpperLimit
      choiceLowerLimit
      isOptional
      options {
        name
        price
        
        isDefault
      }
    }
  }
`;

export const DELETE_CUSTOMIZATION = gql`
mutation DeleteCustomization($businessId: String, $customizationId: String){
  DeleteCustomization(businessId: $businessId, customizationId: $customizationId)
  }
`;

export const CREATE_TAG_FOR_BUSINESS = gql`
  mutation CreateTag($tag: TagInput) {
    CreateTag(tag: $tag) {
      id
      name
      description
      abbreviation
      imageUrl
    }
  }
`;

export const UPDATE_TAG_FOR_BUSINESS = gql`
  mutation UpdateTag($businessId: businessId, $tagId: tagId, $tag: TagInput) {
    UpdateTag(businessId: $businessId, tagId: $tagId, tag: $tag) {
      id
      name
      businessId
      description
      abbreviation
      imageUrl
    }
  }
`;

export const DELETE_TAG = gql`
mutation DeleteTag($businessId: String, $tagId: String){
  DeleteTag(businessId: $businessId, tagId: $tagId)
  }
`;

export const ALL_TAGS = gql`
query
  GetAllTags($businessId: String!){
    GetAllTags(businessId: $businessId){
      id
      businessId
      name
      description
      abbreviation
      imageUrl
    }
  }
`;
