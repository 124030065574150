<ion-card>
  <ion-card-header class="d-flex ion-align-items-center">
    <ion-icon name="information-circle-outline" color="primary" size="large"></ion-icon>
    <ion-label class="ion-padding-start spx-bold spx-font-color-black spx-font-16">Information</ion-label>
</ion-card-header>
  <ion-card-content>
    <ion-grid>
      <ion-row>
        <ion-col size="6">
          <ion-item>
            <ion-label class="spx-bold spx-font-color-black spx-font-16" position="floating">Website</ion-label>
            <ion-input class="ion-no-padding" [(ngModel)]="website"></ion-input>
          </ion-item>
         </ion-col>
        <ion-col size="6">
          <ion-item>
            <ion-label class="spx-bold spx-font-color-black spx-font-16" position="floating">Location</ion-label>
            <ion-input class="ion-no-padding" [(ngModel)]="location"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="ion-text-center">
          <ion-button color="primary" (click)="saveInformation()">save</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
