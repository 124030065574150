<section *ngIf="!userAccountCreated && !userBusinessDetails; else successBlock">
    <form [formGroup]="userForm" (ngSubmit)="
      existingSelectedUser?.email === userForm.value.emailId
        ? patchBusinessForUser(existingSelectedUser.uid)
        : onUserSubmit()
    ">
        <ion-item *ngIf="businessUsers!==undefined">
            <ion-label>Business Users</ion-label>
            <ion-select mode="ios" (ionChange)="onEmailChanged($event)">
                <ion-select-option *ngFor="let user of businessUsers" [value]="user">{{ user.email }}
                </ion-select-option>
                <ion-select-option value="addEmail"><b>Use other email</b>
                </ion-select-option>
            </ion-select>
        </ion-item>
        <ion-item *ngIf="addEmailFlag || existingSelectedUser || businessUsers===undefined">
            <ion-label position="floating">
                Email ID
                <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input required type="email" formControlName="emailId"></ion-input>
        </ion-item>
        <app-control-messages [control]="userForm.get('emailId')"></app-control-messages>

        <ion-item *ngIf="(this.userForm.value.emailId !== '' && this.existingSelectedUser?.email!==this.userForm.value.emailId) || this.addEmailFlag || businessUsers===undefined">
            <ion-label position="floating">
                Password
                <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input required [type]="passwordType" formControlName="password">
            </ion-input>
            <ion-icon item-end [name]="passwordIcon" class="passwordIcon" (click)="hideShowPassword()"></ion-icon>
        </ion-item>
        <ion-item *ngIf="(this.userForm.value.emailId !== '' && this.existingSelectedUser?.email!==this.userForm.value.emailId) || this.addEmailFlag || businessUsers===undefined">
            <ion-label position="floating">Confirm password
                <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input required [type]="confirmPasswordType" formControlName="confirmPassword">
            </ion-input>
            <ion-icon item-end [name]="confirmPasswordIcon" class="passwordIcon" (click)="hideShowConfirmPassword()">
            </ion-icon>
        </ion-item>
        <ion-label *ngIf="
        userForm.get('confirmPassword').dirty &&
        userForm.get('confirmPassword').errors &&
        userForm.get('confirmPassword').errors.invalidConfirmPassword
      " class="ion-padding" color="danger">
            Password and Confirm Password doesn't match
        </ion-label>
        <div class="ion-padding ion-margin ion-text-center">
            <ion-button type="submit" [disabled]="!userForm.valid && !existingSelectedUser">Create User</ion-button>
        </div>
    </form>
</section>

<!-- Success alert -->
<ng-template #successBlock>
    <div *ngIf="!userBusinessDetails" class="ion-text-center">
        <ion-card class="success-card">
            <ion-card-header>
                <img src="./../../../assets/icon/checkmark.png" class="success-image" />
                <ion-card-title>Success</ion-card-title>
                <ion-card-subtitle>Account created successfully</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content> </ion-card-content>
        </ion-card>
    </div>
</ng-template>
<ion-card *ngIf="userBusinessDetails">
    <ion-card-content>
        <ion-grid>
            <ion-row class="d-flex ion-align-items-center ion-justify-content-center">
                <ion-col class="d-flex ion-justify-content-center">
                    <div class="d-flex spx-font-16">
                        <ion-icon class="check-icon" name="checkmark-circle-outline"></ion-icon>
                        <ion-label>
                            Credentials for this business already exists with
                            <b>{{ userBusinessDetails.email }}</b
              >!
            </ion-label>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>