import { Component, OnInit, NgZone, OnDestroy } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { Router, ActivatedRoute } from "@angular/router";
import { ModalComponent } from "src/app/shared/modal/modal.component";
import { MerchantBusinessService } from "src/app/shared/services/merchant-business.service";
import { EventEmitterService } from "src/app/shared/services/event-emitter.service";
import { MERCHANTS_BY_ID_QUERY } from "src/app/feature/merchant-details/merchant-queries";
import { CatalogueService } from "src/app/shared/services/catalogue.service";
import { Merchant } from "src/app/feature/merchant-details/merchant.model";
import { Subscription } from "rxjs";
import { FormGroup, FormControl } from "@angular/forms";
import { FeatureToggleService } from "src/app/shared/services/feature-toggle.service";
import { UpdateFeatureComponent } from "src/app/feature/update-feature/update-feature.component";

@Component({
  selector: "app-action-cells",
  templateUrl: "./action-cells.component.html",
  styleUrls: ["./action-cells.component.scss"],
})
export class ActionCellsComponent implements OnInit, OnDestroy {
  data: any;
  params: any;
  business: any;
  allmerchant: Merchant[];
  rowData: any[];
  duplicateCatalogue: any;
  allBusinessData: any;
  liveTypeId: string;
  servicePlatIndex: number;

  liveHeaderDisable: string;
  liveHeaderEnable: string;

  private querySubscription: Subscription;

  constructor(
    public alertController: AlertController,
    public router: Router,
    private ngZone: NgZone,
    public modalController: ModalController,
    private merchantBusinessService: MerchantBusinessService,
    private catalogueService: CatalogueService,
    private eventEmitterService: EventEmitterService,
    private route: ActivatedRoute,
    private featureService: FeatureToggleService
  ) { }

  duplicateCatalogueForm = new FormGroup({
    name: new FormControl(),
  });

  public navigate(path: string): void {
    this.ngZone
      .run(() => this.router.navigateByUrl(this.router.url + path))
      .then();
  }

  agInit(params) {
    this.params = params;
  }

  ngOnInit() { }

  async presentUpdateFeature(params) {
    const modal = await this.modalController.create({
      component: UpdateFeatureComponent,
      componentProps: {
        paramID: "updateFeature",
        paramTitle: "update feature",
        editID: params.data.id,
        featureName: params.data.name,
        featureBusinessbckup: params.data.activeFor,
      },
    });

    return await modal.present();
  }
  async presentEditMerchant(params) {
    const rowData = this.params;
    const modal = await this.modalController.create({
      component: ModalComponent,
      componentProps: {
        paramID: "merchantEditModal",
        paramTitle: "Edit Merchant",
        editID: params.data.id,
        merchantName: params.data.name,
      },
    });

    return await modal.present();
  }
  async presentDeleteMerchant(merchantId) {
    const rowData = this.params;
    const i = rowData.rowIndex;

    const alert = await this.alertController.create({
      cssClass: "simpayx-alert",
      header: `Are you sure you want to delete this merchant: ${rowData.data.name}?`,
      message: "All associated businesses will be removed",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            console.log("Cancelled");
          },
        },
        {
          cssClass: "btn-danger",
          text: "Delete",
          handler: () => {
            this.querySubscription = this.merchantBusinessService
              .deleteMerchant(merchantId)
              .subscribe((response: any) => {
                this.merchantBusinessService
                  .getAllMerchants()
                  .valueChanges.subscribe((allmerchant: any) => {
                    if (allmerchant.data !== undefined) {
                      this.allmerchant = allmerchant.data.GetAllMerchants;
                      this.rowData = allmerchant.data.GetAllMerchants;
                      // this.eventEmitterService.refreshGrid(this.allmerchant);
                    }

                    console.log("got all merchants");
                  });
              });
            console.log("Confirm Deletion");
          },
        },
      ],
    });

    await alert.present();
  }

  async presentDeleteFeature(name) {
    console.log("tets", this.params);

    const rowData = this.params;
    const i = rowData.rowIndex;

    const alert = await this.alertController.create({
      cssClass: "simpayx-alert",
      header: `Are you sure you want to delete feature : ${rowData.data.name}?`,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
          },
        },
        {
          cssClass: "btn-danger",
          text: "Delete",
          handler: () => {
            this.querySubscription = this.featureService
              .deleteFeature(name)
              .subscribe()
          }
        }
      ]
    });

    await alert.present();
  }

  async presentCreateAccount(allRowData) {
    const modal = await this.modalController.create({
      component: ModalComponent,
      componentProps: {
        paramID: "accountCreateModal",
        paramTitle: "Create Account Settlement",
        allData: allRowData,
      },
    });

    return await modal.present();
  }

  async presentEditBusiness(allRowData) {
    console.log(allRowData);
    const modal = await this.modalController.create({
      component: ModalComponent,
      componentProps: {
        paramID: "businessModal",
        paramTitle: "Edit Business",
        connectedId: allRowData.merchantId,
        allData: allRowData
      },
      cssClass: "full-width-modal",
    });

    return await modal.present();
  }

  async presentOnboardBusiness(allRowData) {
    console.log(allRowData);
    const modal = await this.modalController.create({
      component: ModalComponent,
      componentProps: {
        paramID: "businessOnboardModal",
        paramTitle: "Business Onboarding",
        allData: allRowData,
      },
      cssClass: "full-width-modal",
    });

    return await modal.present();
  }
  async presentDeleteBusiness(allRowData) {
    const rowData = this.params;
    const i = rowData.rowIndex;
    console.log(rowData);
    const alert = await this.alertController.create({
      cssClass: "simpayx-alert",
      header: `Are you sure you want to delete this business: ${rowData.data.name}?`,
      message: ``,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            console.log("Cancelled");
            console.log(allRowData);
          },
        },
        {
          cssClass: "btn-danger",
          text: "Delete",
          handler: () => {
            this.merchantBusinessService
              .deleteBusiness(allRowData.merchantId, allRowData.data.id)
              .subscribe((response: any) => {
                setTimeout(() => {
                  this.merchantBusinessService
                    .getAllBusinessesForMerchant(allRowData.merchantId)
                    .valueChanges.subscribe((businessData: any) => {
                      if (businessData !== undefined) {
                        const getAllBusinessesData =
                          businessData.data.GetAllBusinessesForMerchant;
                        // this.eventEmitterService.refreshGrid(getAllBusinessesData);
                        console.log(getAllBusinessesData);
                      }
                    });
                }, 250);
              });

            console.log("Confirm Deletion");
          },
        },
      ],
    });

    await alert.present();
  }

  async presentEditCatalogue(allRowData) {
    const rowData = this.params;
    const i = rowData.rowIndex;
    rowData.data.businessId = this.route.snapshot.params.businessId;
    const modal = await this.modalController.create({
      component: ModalComponent,
      componentProps: {
        paramID: "catalogueEditModal",
        paramTitle: "Edit Catalogue",
        allData: allRowData,
      },
    });

    return await modal.present();
  }

  async setLiveCatalogueId(e) {
    const rowData = this.params;
    const i = rowData.rowIndex;
    rowData.data.merchantId = this.route.snapshot.params.merchantId;
    rowData.data.businessId = this.route.snapshot.params.businessId;

    this.getBusinessById(rowData.data.merchantId, rowData.data.businessId);
    if (rowData.data.isEnabled) {
      // Display popup messaging and set the current live ID (Depending on Service Platform) to the liveTypeId.
      // Let's use this liveTypeId to check against.
      if (e.target.id === "table-trigger") {
        this.liveTypeId = rowData.data.liveTableId;
        this.servicePlatIndex = 0;

        this.liveHeaderDisable = `${rowData.data.name} is already live for Dine-In?`;
        this.liveHeaderEnable = `Would you like to set the catalogue, ${rowData.data.name} live for Dine-in?`;
      } else if (e.target.id === "pickup-trigger") {
        this.liveTypeId = rowData.data.livePickupId;
        this.servicePlatIndex = 1;

        this.liveHeaderDisable = `${rowData.data.name} is already live for Pickup?`;
        this.liveHeaderEnable = `Would you like to set the catalogue, ${rowData.data.name} live for Pickup?`;
      } else if (e.target.id === "takeout-trigger") {
        this.liveTypeId = rowData.data.liveTakeoutId;
        this.servicePlatIndex = 2;

        this.liveHeaderDisable = `${rowData.data.name} is already live for Takeout?`;
        this.liveHeaderEnable = `Would you like to set the catalogue, ${rowData.data.name} live for Takeout?`;
      } else {
        this.liveTypeId = rowData.data.liveReservationId;
        this.servicePlatIndex = 3;

        this.liveHeaderDisable = `${rowData.data.name} is already live for Reservation?`;
        this.liveHeaderEnable = `Would you like to set the catalogue, ${rowData.data.name} live for Reservation?`;
      }

      // liveTypeId is set.
      // Live catalogue is set but user trying to set again
      if (this.liveTypeId === rowData.data.id) {
        const alert = await this.alertController.create({
          cssClass: "simpayx-alert",
          header: this.liveHeaderDisable,
          message: `Would you like to disable this?`,
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              handler: () => {
                console.log("Cancelled");
                console.log("compare ", this.liveTypeId);
                console.log("to ", rowData.data.id);
              },
            },
            {
              cssClass: "btn-danger",
              text: "Disable",
              handler: () => {
                // TEMP - This was left in current servicePlatforms but removed from Schema
                this.deleteAvgServiceTime();

                // Remove the servicePlatform's Live Catalogue ID
                this.allBusinessData.businessAttributes.servicePlatforms[
                  this.servicePlatIndex
                ].liveCatalogueId = "";

                console.log("business", this.allBusinessData);
                this.merchantBusinessService
                  .editBusiness(
                    rowData.data.merchantId,
                    rowData.data.businessId,
                    this.allBusinessData
                  )
                  .subscribe((businessResponse: any) => {
                    console.log("businessResponse", businessResponse);
                    this.getBusinessById(
                      rowData.data.merchantId,
                      rowData.data.businessId
                    );
                  });
                console.log("Confirm Catalogue Disabled");
              },
            },
          ],
        });
        await alert.present();
      } else {
        // Live catalogue not set, user wants to set
        const alert = await this.alertController.create({
          cssClass: "simpayx-alert",
          header: this.liveHeaderEnable,
          message: `Only one catalogue and one live type can be active at a time`,
          buttons: [
            {
              text: "Cancel",
              role: "cancel",
              handler: () => {
                console.log("Cancelled");
              },
            },
            {
              cssClass: "btn-success",
              text: "Go Live",
              handler: () => {
                // TEMP - This was left in current servicePlatforms but removed from Schema
                this.deleteAvgServiceTime();

                this.allBusinessData.businessAttributes.servicePlatforms[
                  this.servicePlatIndex
                ].liveCatalogueId = rowData.data.id;

                console.log("business", this.allBusinessData);
                this.merchantBusinessService
                  .editBusiness(
                    rowData.data.merchantId,
                    rowData.data.businessId,
                    this.allBusinessData
                  )
                  .subscribe((businessResponse: any) => {
                    console.log("businessResponse", businessResponse);
                  });
                setTimeout(() => {
                  this.getBusinessById(
                    rowData.data.merchantId,
                    rowData.data.businessId
                  );
                }, 500);
                console.log("Confirm Catalogue LIVE");
              },
            },
          ],
        });
        await alert.present();
      }
    } else {
      // User wants to set live catalogue but the catalogue is disabled
      const alert = await this.alertController.create({
        cssClass: "simpayx-alert",
        header: `The ${rowData.data.name} catalogue is currently disabled.`,
        message: `Please enable this catalogue first.`,
        buttons: [
          {
            text: "Ok",
            role: "cancel",
            handler: () => {
              console.log("Cancelled");
            },
          },
        ],
      });
      await alert.present();
    }
  }

  async presentDuplicateCatalogue() {
    const rowData = this.params;
    const i = rowData.rowIndex;
    rowData.data.businessId = this.route.snapshot.params.businessId;
    console.log(rowData);
    console.log(rowData.data.businessId);
    const alert = await this.alertController.create({
      cssClass: "simpayx-alert",
      header: `Duplicate this catalogue, ${rowData.data.name}?`,
      message: `Choose a desired name
      `,
      inputs: [
        {
          name: "dupName",
          type: "text",
          value: `${rowData.data.name} - copy`,
        },
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            console.log("Cancelled");
          },
        },
        {
          cssClass: "btn-success",
          text: "Create",
          handler: (alertData) => {
            this.duplicateCatalogue = JSON.parse(JSON.stringify(rowData.data));
            this.duplicateCatalogue.name = alertData.dupName;
            delete this.duplicateCatalogue.id;
            delete this.duplicateCatalogue.liveCatId;
            delete this.duplicateCatalogue.liveTableId;
            delete this.duplicateCatalogue.livePickupId;
            delete this.duplicateCatalogue.liveTakeoutId;
            delete this.duplicateCatalogue.liveReservationId;
            delete this.duplicateCatalogue.merchantId;
            console.log(this.duplicateCatalogue);
            this.catalogueService
              .createCatalogueForBusiness(this.duplicateCatalogue)
              .subscribe((response: any) => {
                console.log(response);
              });
            setTimeout(() => {
              // tslint:disable-next-line: max-line-length
              this.catalogueService
                .getAllCataloguesForBusiness(rowData.data.businessId)
                .valueChanges.subscribe((catalogues: any) => {
                  if (catalogues.data !== undefined) {
                    console.log(catalogues.data.GetAllCatalogues);
                    const GetCatalogues = catalogues.data.GetAllCatalogues;
                    //this.eventEmitterService.refreshGrid(GetCatalogues);
                  }
                });
            }, 50);
            console.log("Confirm Duplication");
          },
        },
      ],
    });

    await alert.present();
  }

  async presentDeleteCatalogue(allRowData) {
    const rowData = this.params;
    const i = rowData.rowIndex;
    rowData.data.businessId = this.route.snapshot.params.businessId;
    console.log(rowData);
    console.log(rowData.data.businessId);
    const alert = await this.alertController.create({
      cssClass: "simpayx-alert",
      header: `Are you sure you want to delete this catalogue, ${rowData.data.name}?`,
      message: `This catalogue will be removed along with all associated items.`,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            console.log("Cancelled");
          },
        },
        {
          cssClass: "btn-danger",
          text: "Delete",
          handler: () => {
            this.catalogueService
              .deleteCatalogue(rowData.data.businessId, rowData.data.id)
              .subscribe((response: any) => {
                console.log(response);
              });
            setTimeout(() => {
              // tslint:disable-next-line: max-line-length
              this.catalogueService
                .getAllCataloguesForBusiness(rowData.data.businessId)
                .valueChanges.subscribe((catalogues: any) => {
                  if (catalogues.data !== undefined) {
                    console.log(catalogues.data.GetAllCatalogues);
                    const GetCatalogues = catalogues.data.GetAllCatalogues;
                    this.eventEmitterService.refreshGrid(GetCatalogues);
                  }
                });
            }, 50);
            console.log("Confirm Deletion");
          },
        },
      ],
    });

    await alert.present();
  }

  getBusinessById(merchantId, businessId) {
    this.merchantBusinessService
      .getBusinessById(merchantId, businessId)
      .valueChanges.subscribe((businessByIdData: any) => {
        console.log(businessByIdData);
        this.allBusinessData = businessByIdData.data.GetBusinessById;
      });
  }

  ngOnDestroy() {
    if (this.eventEmitterService.gridDataSubscription) {
      this.eventEmitterService.gridDataSubscription.unsubscribe();
    }
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
  }

  // Temporary due to Schema and current servicePlatforms out of sync
  deleteAvgServiceTime() {
    // This was left in current servicePlatforms but removed from Schema
    delete this.allBusinessData.businessAttributes.servicePlatforms[0]
      .avgServiceTimeInMin;
    delete this.allBusinessData.businessAttributes.servicePlatforms[1]
      .avgServiceTimeInMin;
    delete this.allBusinessData.businessAttributes.servicePlatforms[2]
      .avgServiceTimeInMin;
    delete this.allBusinessData.businessAttributes.servicePlatforms[3]
      .avgServiceTimeInMin;
  }
}
