<section class="form-modal-content">
  <form [formGroup]="editCatalogueForm" (ngSubmit)="onEditCatalogueSubmit()">
        <fieldset>
          <legend>
            <h3>CATALOGUE</h3>
          </legend>
          <div>
            <ion-list lines="full" class="ion-no-margin ion-no-padding">
              <!-- <ion-item>
                <ion-label position="floating"> Business ID <ion-text color="danger">*</ion-text>
                </ion-label>
                <ion-input required type="text" formControlName="businessId"></ion-input>
              </ion-item>
              <ion-item>
                <ion-label position="floating"> Catalogue ID <ion-text color="danger">*</ion-text>
                </ion-label>
                <ion-input required type="text" formControlName="id"></ion-input>
              </ion-item> -->
              <ion-item>
                <ion-label position="floating"> Catalogue Name <ion-text color="danger">*</ion-text>
                </ion-label>
                <ion-input required type="text" formControlName="name"></ion-input>
              </ion-item>
              <ion-item>
                <ion-label>Enabled</ion-label>
                <ion-toggle checked formControlName="isEnabled"></ion-toggle>
            </ion-item>
            </ion-list>
          </div>
        </fieldset><br>
    <ion-button expand="block" type="submit" [disabled]="!editCatalogueForm.valid">Edit catalogue</ion-button>
  </form>
</section>