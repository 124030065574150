import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { BusinessService } from "src/app/shared/services/business.service";
import { FeatureToggleService } from "src/app/shared/services/feature-toggle.service";
import {
  BusinessAddress,
  BusinessInfo,
  FeatureToggleModel,
} from "src/app/toggle-feature/feature-toggle.model";

@Component({
  selector: "app-update-feature",
  templateUrl: "./update-feature.component.html",
  styleUrls: ["./update-feature.component.scss"],
})
export class UpdateFeatureComponent implements OnInit {
  featureName: String;
  businesses: BusinessInfo[] = [];
  featureBusinesses: BusinessInfo[] = [];
  isShowingSearchResults = false;

  businessBckup: BusinessInfo[] = [];
  featureBusinessbckup: BusinessInfo[] = [];

  constructor(
    private businessService: BusinessService,
    private featureToggleService: FeatureToggleService,
    public modalController: ModalController
  ) {}

  ngOnInit() {
    if (this.featureBusinessbckup) {
      this.featureBusinessbckup.forEach((busines) =>
        this.featureBusinesses.push(busines)
      );
    }
    this.businessService
      .getAllBusinesses()
      .valueChanges.subscribe((data: any) => {
        if (data.data !== undefined) {
          this.businesses = [];
          const tempData: [] = data.data.GetAllBusinesses;
          tempData.forEach((data: any) => {
            let line1 = data.address.line1;
            let line2 = data.address.line2;
            let businessAdress = new BusinessAddress(line1, line2);
            let featureBusiness = new BusinessInfo(
              data.id,
              data.name,
              businessAdress
            );
            this.businesses.push(featureBusiness);
          });
          this.businessBckup = this.businesses;
        }
      });
  }
  addBusiness(business: BusinessInfo) {
    if (
      !this.featureBusinesses.find(
        (currentBusiness) => currentBusiness.id == business.id
      )
    ) {
      this.featureBusinesses.push(business);
    }
    this.isShowingSearchResults = false;
  }
  onClear(event: any) {
    this.isShowingSearchResults = false;
  }

  filterList(event: any) {
    this.businesses = this.businessBckup;
    const searchTerm = event.srcElement.value;
    if (!searchTerm || searchTerm.length < 3) {
      this.isShowingSearchResults = false;
      return;
    }

    this.businesses = this.businesses.filter((currentBusiness) => {
      if (
        (currentBusiness.name && searchTerm) ||
        currentBusiness.id == searchTerm
      ) {
        return (
          currentBusiness.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >
            -1 ||
          currentBusiness.id.toLowerCase().indexOf(searchTerm.toLowerCase()) >
            -1
        );
      }
    });
    this.isShowingSearchResults = true;
  }

  getStatus(id: String) {
    return this.featureBusinesses.find((business) => business.id == id);
  }

  closeModal() {
    this.featureBusinesses = this.featureBusinessbckup;
    this.modalController.dismiss();
  }
  saveChanges() {
    const feature = new FeatureToggleModel(this.featureName);
    feature.activeFor = this.featureBusinesses;
    this.featureToggleService
      .updateFeature(this.featureName, feature)
      .subscribe((feature) => {
        this.modalController.dismiss();
      });
  }

  removeBusiness(businessInfo: BusinessInfo) {
    this.featureBusinesses = this.featureBusinesses.filter(
      (business) => business.id != businessInfo.id
    );
  }
}

export enum Type {
  all = "all",
  dev = "dev",
  restaurants = "restaurants",
}
