import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

@Injectable()
export class AuthService {
  constructor(private route: Router, private popoverController: PopoverController) { }
  sendToken(token: string) {
    localStorage.setItem('LoggedInUser', token);
  }
  getToken() {
    return localStorage.getItem('LoggedInUser');
  }
  isLoggedIn() {
    return this.getToken() !== null;
  }
  logout() {
    this.popoverController.dismiss();
    localStorage.removeItem('LoggedInUser');
    this.route.navigateByUrl('login');
  }
}
