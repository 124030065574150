<ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      <ion-icon name="clipboard"></ion-icon> {{ modalTitle }}</ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
  <app-create-account
    [allData]="allData"
    *ngIf="modalId === 'accountCreateModal'"
  ></app-create-account>
  <app-create-merchant
    *ngIf="modalId === 'merchantModal'"
  ></app-create-merchant>
  <app-create-vertical
    *ngIf="modalId === 'verticalModal'"
  ></app-create-vertical>
  <app-create-feature *ngIf="modalId === 'feature-toggle'"></app-create-feature>
  <!-- <app-create-business [connectedId]='connectedId' *ngIf="modalId === 'businessModal'"></app-create-business> -->
  <app-business-onboard
    [merchantId]="connectedId"
    [allData]="allData"
    *ngIf="modalId === 'businessModal'"
  ></app-business-onboard>
  <app-create-catalogue
    (closeModalEvent)="closeModal()"
    [connectedId]="connectedId"
    [allData]="allData"
    *ngIf="modalId === 'catalogueModal'"
  ></app-create-catalogue>

  <app-edit-merchant
    [merchantNameId]="merchantNameId"
    *ngIf="modalId === 'merchantEditModal'"
  ></app-edit-merchant>
  <app-edit-business
    [allData]="allData"
    *ngIf="modalId === 'businessOnboardModal'"
  ></app-edit-business>
  <app-edit-catalogue
    [allData]="allData"
    *ngIf="modalId === 'catalogueEditModal'"
  ></app-edit-catalogue>

  <app-create-category
    *ngIf="modalId === 'categoryModal'"
  ></app-create-category>
  <app-create-item *ngIf="modalId === 'itemModal'"></app-create-item>
  <!-- <div *ngIf="modalId !== 'businessModal'" class="ion-text-center ion-margin-bottom"><a role="button" (click)='closeModal();'>Cancel</a></div> -->
</ion-content>
