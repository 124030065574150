import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-master',
  templateUrl: './modal-master.component.html',
  styleUrls: ['./modal-master.component.scss']
})
export class ModalMasterComponent implements OnInit {

  modalTitle: string;
  modalId: string;
  editId: string;
  merchantId: string;
  businessId: string;
  businessIdMaster: string;
  connectedId: string;
  merchantNameId: any;
  allData: any;

  constructor(private modalController: ModalController, private navParams: NavParams) {

  }

  ngOnInit() {
    console.table(this.navParams);
    console.log(this.businessId);
    this.modalId = this.navParams.data.paramID;
    this.modalTitle = this.navParams.data.paramTitle;
    this.connectedId = this.navParams.data.connectedId;
    this.editId = this.navParams.data.editID;
    this.merchantNameId = {
      merchantName: this.navParams.data.merchantName,
      editId: this.navParams.data.editID
    };
    this.merchantId = this.navParams.data.merchantID;
    this.businessId = this.navParams.data.businessID;
    this.allData = this.navParams.data.allData;
    this.businessIdMaster = this.navParams.data.businessId;
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}
