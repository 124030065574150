import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { Subscription } from 'rxjs';
import { TagInput } from 'src/app/feature/catalogue/categories/category/menu-items/item/tag/tagInput.model';

@Component({
  selector: 'app-create-tag-master',
  templateUrl: './create-tag-master.component.html',
  styleUrls: ['./create-tag-master.component.scss'],
})
export class CreateTagMasterComponent implements OnInit, OnDestroy {
  @Input() businessIdMaster;
  createTagQuerySubscription: Subscription;
  tag: TagInput;
  
  tagMasterForm = new FormGroup({
    id: new FormControl('', [Validators.required, Validators.minLength(10)]),
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    abbreviation: new FormControl(''),
    businessId: new FormControl('', [Validators.required, Validators.minLength(10)]),
    description: new FormControl('')
  });
  constructor(
    private modalController: ModalController,
    private catalogueService: CatalogueService) { }
  
  ngOnDestroy(): void {
    if (this.createTagQuerySubscription) {
      this.createTagQuerySubscription.unsubscribe();
    }
  }
  ngOnInit() {
    this.tagMasterForm.get('id').setValue(this.businessIdMaster);
    this.tagMasterForm.get('businessId').setValue(this.businessIdMaster);
  }

  onCreateTagMasterSubmit() {
    this.catalogueService.createTag(this.tagMasterForm.value).subscribe((tagMasterResponse) => {
    console.log(tagMasterResponse);
    }, (err) => {console.log(err)});

    this.modalController.dismiss();
  }

}
