import { MerchantBusinessService } from "src/app/shared/services/merchant-business.service";
import { Component, Input, OnChanges, OnInit, OnDestroy } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import {
  BusinessTimingDetails,
  BusinessInput,
  PlatformType,
  BusinessHours,
  BusinessPatchInput,
  ServicePlatformPatch,
  Slot,
  ServicePlatformInput,
  ServicePlatformSubType,
  ResourcesInput,
} from "src/app/feature/business-details/business.model";
import { BusinessService } from "src/app/shared/services/business.service";
import { ReservationSlotService } from "./reservation-slot.service";
import { SelectDayComponent } from "./select-day/select-day.component";
import { Subscription } from "rxjs";

@Component({
  selector: "simpayx-business-hours",
  templateUrl: "./business-hours.component.html",
  styleUrls: ["./business-hours.component.scss"],
})
export class BusinessHoursComponent implements OnInit, OnChanges, OnDestroy {
  @Input() businessTimings: Map<String, BusinessTimingDetails> = new Map<
    String,
    BusinessTimingDetails
  >();
  @Input() business: BusinessInput;
  @Input() type: PlatformType;
  @Input() expanded: boolean = true;
  @Input() isAddedHour: boolean;
  @Input() selectedAverageServiceTime: string;
  @Input() serviceCapacity: number;
  selectDay: String;
  customDays: String[] = [];
  //variable maintained to show picker on custom option selection
  customDateValue = Date.now();
  selection: String = "Select day";
  selector = ["Daily", "Weekdays(Mon-Fri)", "Weekend(Sat-Sun)", "Custom"];
  selectorValue = [
    "Daily",
    "Weekdays(Mon-Fri)",
    "Weekend(Sat-Sun)",
    this.customDateValue,
  ];
  weekday = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  isCustom = false;
  additionTiming: BusinessHours[] = [];
  timers = 1;
  showAdditionalBusinessHourClose: boolean = false;
  showAdditionalBusinessHourOpen: boolean = false;
  isTimeOverlaps = null;
  averageServiceTimes = ["15 min", "30 min", "45 min", "60 min"];
  platformType = PlatformType;
  patchResourceQuerySubscription: Subscription;

  constructor(
    private businessService: BusinessService,
    private merchantBusinessService: MerchantBusinessService,
    public alertController: AlertController,
    public modalController: ModalController,
    private reservationSlotService: ReservationSlotService
  ) {}

  ngOnInit() {
    let businessHour = new BusinessHours();
    this.additionTiming.push(businessHour);
    this.selectDay = "Select Day";
  }

  ngOnChanges() {
    console.log("ngOnchanges exp" + this.expanded);
  }

  addRows() {
    let businessHour = new BusinessHours();
    console.log(businessHour);
    if (businessHour) {
      this.additionTiming.push(businessHour);
      this.showAdditionalBusinessHourOpen = false;
      this.showAdditionalBusinessHourClose = false;
    }
  }
  removeRows(index) {
    this.additionTiming.splice(index, 1);
  }
  openTimeAdd(event, i) {
    let openTime = event.target.value.split("T")[1].split(".")[0];
    openTime = openTime
      .split(":", 3)[0]
      .concat(":")
      .concat(openTime.split(":", 3)[1])
      .concat(":")
      .concat("00");
    this.additionTiming[i].opensAt = openTime;
    if (openTime) {
      this.showAdditionalBusinessHourOpen = true;
    }
    console.log(event, i, openTime);
  }
  closeTimeAdd(event, i) {
    let closeTime = event.target.value.split("T")[1].split(".")[0];
    closeTime = closeTime
      .split(":", 3)[0]
      .concat(":")
      .concat(closeTime.split(":", 3)[1])
      .concat(":")
      .concat("00");
    this.additionTiming[i].closesAt = closeTime;
    if (closeTime) {
      this.showAdditionalBusinessHourClose = true;
    }
    console.log(event, i, closeTime);
  }
  removeTimeSlot(day, i) {
    console.log(day, "index", i);
    console.log(this.businessTimings[day].businessHours[i]);
    let msg =
      "Timeslot " +
      JSON.stringify(this.businessTimings[day].businessHours[i].opensAt) +
      " - " +
      JSON.stringify(this.businessTimings[day].businessHours[i].closesAt) +
      " for " +
      day +
      " will be removed";

    this.openRemoveAlert("Remove Time", msg, day, i);
  }
  async openRemoveAlert(headerMsg: string, msg: string, day, i) {
    const alert = await this.alertController.create({
      header: headerMsg,
      message: msg,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Ok",
          handler: () => {
            this.businessTimings[day].businessHours.splice(i, 1);
            console.log(this.businessTimings);
            this.saveHours();
          },
        },
      ],
    });
    await alert.present();
  }
  currentBusiness(event) {
    this.isCustom = false;
    switch (event.target.value) {
      case "Daily":
        this.selection = event.target.value;
        this.customDays = this.weekday;
        console.log("daily" + this.customDays);

        break;
      case "Weekdays(Mon-Fri)":
        this.selection = event.target.value;
        this.customDays = [
          "MONDAY",
          "TUESDAY",
          "WEDNESDAY",
          "THURSDAY",
          "FRIDAY",
        ];
        console.log("Weekdays(Mon-Fri)" + this.customDays);

        break;
      case "Weekend(Sat-Sun)":
        this.selection = event.target.value;
        this.customDays = ["SATURDAY", "SUNDAY"];
        console.log("Weekend(Sat-Sun)" + this.customDays);

        break;
      default:
        console.log(event.target.value);
        this.selection = "Custom";
        this.customDays = [];
        this.modalController.getTop().then((lastData) => {
          if (lastData === undefined) {
            this.customDaySelection();
          }
        });
        console.log("Custom");

        break;

      // default:
      // break;
    }
  }

  onSelectBlur() {
    console.log(this.selection);
    this.customDateValue = Date.now();
    this.selectorValue[this.selectorValue.length - 1] = this.customDateValue;
  }

  addtiming() {
    if (this.type === PlatformType.RESERVATION) {
      if (!this.selectedAverageServiceTime) {
        this.validationAlert(
          "Invalid",
          "Please provide a proper value for Average service time"
        );
        return;
      } else if (!this.serviceCapacity) {
        this.validationAlert(
          "Invalid",
          "Please provide a proper value for number of tables alloted for reservation"
        );
        return;
      }
    }
    let isProperSelection = true;
    if (this.customDays.length === 0) {
      this.alertOpen("Please select days");
      isProperSelection = false;
    } else {
      this.additionTiming.forEach((time) => {
        if (time.closesAt === undefined || time.opensAt === undefined) {
          this.alertOpen("Please select proper timings");
          isProperSelection = false;
        }
      });
      this.customDays.forEach((day) => {
        if (!this.businessTimings[day + ""].isOpen) {
          this.alertOpen(day + " is closed");
          isProperSelection = false;
        }
      });

      if (isProperSelection) {
        let timing = "";
        this.additionTiming.forEach((times) => {
          timing +=
            times.opensAt
              .toString()
              .split(":", 3)[0]
              .concat(":")
              .concat(times.opensAt.toString().split(":", 3)[1]) +
            " to " +
            times.closesAt
              .toString()
              .split(":", 3)[0]
              .concat(":")
              .concat(times.closesAt.toString().split(":", 3)[1]);
          if (this.additionTiming.length > 1) {
            timing += ", ";
          }
        });
        let headerMsg = "Add Timings";
        let msg = "Business hours " + timing + " will be added for ";
        let days = "";
        if (this.selection != "Custom") msg += this.selection;
        else {
          this.customDays.forEach((day) => {
            days += day;
            if (this.customDays.length > 1) {
              days += ", ";
            }
          });
          msg += days;
        }
        this.openAddTimeAlert(headerMsg, msg);
      }
    }
  }
  async alertOpen(msg: string) {
    const alert = await this.alertController.create({
      header: "Alert",
      message: msg,
      buttons: [
        {
          text: "Ok",
          role: "cancel",
        },
      ],
    });
    await alert.present();
  }
  async openTimeLapsAlert() {
    const alert = await this.alertController.create({
      header: "Invalid business hours ",
      message:
        "The hours slots you have mentioned overlaps, please provide valid business hours.",
      buttons: ["OK"],
    });
    await alert.present();
  }
  async openAddTimeAlert(headerMsg: string, msg: string) {
    const alert = await this.alertController.create({
      header: headerMsg,
      message: msg,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Ok",
          handler: () => {
            this.isAddedHour = true;
            let isAllOpen = true;
            let hours: BusinessHours[] = [];
            hours = hours.concat([...this.additionTiming]);

            this.customDays.forEach((customDay) => {
              if (this.businessTimings[customDay + ""].isOpen) {
                let dayHour: BusinessHours[] = this.businessTimings[
                  customDay + ""
                ].businessHours;

                this.isTimeOverlaps = false;
                dayHour.forEach((slot) => {
                  if (
                    slot.opensAt <= hours[0].closesAt &&
                    slot.closesAt >= hours[0].opensAt
                  ) {
                    this.isTimeOverlaps = true;
                  }
                });
                if (!this.isTimeOverlaps) {
                  dayHour = dayHour.concat([...hours]);
                  this.businessTimings[
                    customDay + ""
                  ].businessHours = Array.apply([], dayHour);
                }
              } else {
                console.log("close for the day");
                isAllOpen = false;
              }
            });
            if (this.isTimeOverlaps) {
              this.openTimeLapsAlert();
            } else if (isAllOpen) {
              this.saveHours();
            }
          },
        },
      ],
    });
    await alert.present();
  }

  closedCheck(day) {
    console.log(day);

    if (!this.businessTimings[day + ""].isOpen) {
      this.openClosedAlert(
        "Open",
        "Business for " + day + " will be Open",
        day
      );
    } else {
      this.openClosedAlert(
        "Closed",
        "Business for " + day + " will be closed",
        day
      );
    }
  }
  async openClosedAlert(headerMsg: string, msg: string, day) {
    const alert = await this.alertController.create({
      header: headerMsg,
      message: msg,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Ok",
          handler: () => {
            this.businessTimings[day + ""].isOpen = !this.businessTimings[
              day + ""
            ].isOpen;
            console.log(this.businessTimings);
            this.saveHours();
          },
        },
      ],
    });
    await alert.present();
  }
  checkForClosed(day) {
    if (
      this.businessTimings[day] != null &&
      !this.businessTimings[day].isOpen
    ) {
      return true;
    } else {
      return false;
    }
  }
  saveHours() {
    console.log(this.businessTimings);
    console.log("service time", this.selectedAverageServiceTime);

    let subType: ServicePlatformSubType =
      ServicePlatformSubType.RESOURCE_PLATFORM;
    if (this.type === PlatformType.RESERVATION) {
      subType = ServicePlatformSubType.SLOT_PLATFORM;
    }
    let businessPatchInput: BusinessPatchInput = new BusinessPatchInput();
    let servicePlatforms: ServicePlatformInput = new ServicePlatformInput(
      this.type,
      subType
    );
    let servicePlatformPatch: ServicePlatformPatch = new ServicePlatformPatch();

    let slots: any = new Object();
    if (this.type === PlatformType.RESERVATION) {
      // servicePlatforms.subtype = ServicePlatformSubType.SLOT_PLATFORM;
      businessPatchInput.operationType = "CREATE";
      businessPatchInput.operationField = "RESOURCES";
      this.weekday.forEach((day) => {
        let slot: Slot = new Slot();
        slot.businessTimingDetails = this.businessTimings[day];
        let filteredPlatform = this.business.businessAttributes.servicePlatforms.find(
          (platform) => platform.type === PlatformType.RESERVATION
        );

        let serviceTimeSplit = this.selectedAverageServiceTime.split(" ");
        slot.resources = this.reservationSlotService.createReservationResources(
          day,
          slot.businessTimingDetails.businessHours,
          parseInt(serviceTimeSplit[0]),
          this.serviceCapacity
        );

        slots[day] = slot;
      });
      servicePlatforms.slots = slots;
    } else {
      // servicePlatforms.subtype = ServicePlatformSubType.RESOURCE_PLATFORM;
      businessPatchInput.operationType = "UPDATE";
      businessPatchInput.operationField = "SERVICE_PLATFORM";
      servicePlatforms.businessTimings = this.businessTimings;
    }
    servicePlatformPatch.servicePlatforms = servicePlatforms;
    businessPatchInput.servicePlatformPatch = servicePlatformPatch;
    this.patchResourceQuerySubscription = this.businessService
      .patchResource(
        this.business.merchantId,
        this.business.id,
        businessPatchInput
      )
      .subscribe((result: any) => {
        console.log(result.data.PatchBusiness);
        this.merchantBusinessService.getBusinessForMerchant(
          this.business.merchantId
        );
      });
  }

  async customDaySelection() {
    let dismissdata: any;
    const modal = await this.modalController.create({
      component: SelectDayComponent,
      cssClass: "fixed-height-width",
      backdropDismiss: false,
      componentProps: {
        weekday: this.weekday,
      },
    });
    modal.onDidDismiss().then((data) => {
      dismissdata = data;
      if (dismissdata.data.length > 0) {
        this.customDays = dismissdata.data;
        this.isCustom = true;
      } else {
      }
    });
    return await modal.present();
  }

  async validationAlert(header: string, msg: string) {
    const alert = await this.alertController.create({
      header: header,
      message: msg,
      buttons: [
        {
          text: "Ok",
        },
      ],
    });
    await alert.present();
  }

  ngOnDestroy() {
    if (this.patchResourceQuerySubscription) {
      this.patchResourceQuerySubscription.unsubscribe();
    }
  }
}
