<section class="form-modal-content">
  <form [formGroup]="featureForm" (ngSubmit)="onSubmit()">
    <fieldset>
      <div>
        <ion-list lines="full" class="ion-no-margin ion-no-padding">
          <ion-item>
            <ion-label position="floating"> Name <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input required type="text" formControlName="name"></ion-input>
          </ion-item>
        </ion-list>
      </div>
    </fieldset>
    <ion-button expand="block" type="submit" [disabled]="!featureForm.valid">Create Feature</ion-button>
  </form>
</section>