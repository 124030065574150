import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { ICountry, IState, ICity } from "country-state-city";
import csc from "country-state-city";

@Component({
  selector: "app-address",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.scss"],
})
export class AddressComponent implements OnInit {
  @Input() businessForm: FormGroup;
  @Input() stepper: MatStepper;

  countries: ICountry[] = [];
  states: IState[] = [];
  cities: ICity[] = [];
  selectedCountry: ICountry;
  selectedState: IState;
  selectedCity: ICity;

  constructor() {}

  ngOnInit() {
    this.getCountriesList();
    
    if (this.businessForm.value.address.country) {
      console.log("PRESENT");
      
      this.selectedCountry = this.countries.filter(
        (country) => country.name === this.businessForm.value.address.country
      )[0];
      this.getStatesForCountry(this.selectedCountry.isoCode);
      this.selectedState = this.states.filter(
        (state) => state.name === this.businessForm.value.address.state
      )[0];
      this.getCitiesOfState(
        this.selectedState.countryCode,
        this.selectedState.isoCode
      );
    }
  }

  /**
   * Address helper methods
   */
  getCountriesList() {
    // this.countries = csc.getAllCountries();
    let filteredCountries = [];
    filteredCountries.push(csc.getCountryByCode("US"));
    filteredCountries.push(csc.getCountryByCode("IN"));
    this.countries = filteredCountries;
  }

  getStatesForCountry(countryCode: string) {
    this.states = csc.getStatesOfCountry(countryCode);
  }

  getCitiesOfState(countryCode: string, stateCode: string) {
    this.cities = csc.getCitiesOfState(countryCode, stateCode);
  }

  onCountryChanged(event) {
    console.log(event);
    this.selectedCountry = this.countries.find(
      (country) => country.name === event.target.value
    );

    this.getStatesForCountry(this.selectedCountry.isoCode);
    console.log(this.selectedCountry.name);
    this.businessForm.controls.address["controls"].state.setValue("");
    this.businessForm.controls.address["controls"].city.setValue("");
    this.businessForm.controls.address["controls"].timeZone.setValue("");
    console.log(this.businessForm);
  }

  onStateChanged(event) {
    if (event.target.value.length > 0) {
      let selectedState: IState = this.states.find(
        (state) => state.name === event.target.value
      );

      this.getCitiesOfState(selectedState.countryCode, selectedState.isoCode);
      console.log(selectedState.name);
    }
  }

  onAddressNextClicked(stepper: MatStepper) {
    stepper.next();
  }

  onPreviousClicked(stepper: MatStepper) {
    stepper.previous();
  }
}
