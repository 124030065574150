export class UserInput {
    id: string;
    merchantId: string;
    businesses: UserBusinessDetailsInput[];
}

export class UserBusinessDetailsInput {
    id: string;
    email:string;
    isActive:boolean;
    businessName: string;
    uid: string;
}