

<ion-header>
    <ion-toolbar color="primary">
      <ion-title><ion-icon name="clipboard"></ion-icon> {{modalTitle}}</ion-title>
    </ion-toolbar>
  </ion-header>
  
  <ion-content fullscreen>
  <app-create-category-master [businessIdMaster]='businessIdMaster' *ngIf="modalId === 'categoryMasterModal'"></app-create-category-master>
  <app-create-category-standalone *ngIf="modalId === 'categoryModal'"></app-create-category-standalone>
  <app-create-item-master [businessIdMaster]='businessIdMaster' *ngIf="modalId === 'itemMasterModal'"></app-create-item-master>
  <app-create-item-standalone *ngIf="modalId === 'itemModal'"></app-create-item-standalone>
  <app-create-tag-master [businessIdMaster]='businessIdMaster' *ngIf="modalId === 'tagMasterModal'"></app-create-tag-master>
  <app-create-tag-standalone [businessIdMaster]='businessIdMaster' *ngIf="modalId === 'tagModal'"></app-create-tag-standalone>
  <app-create-custom-master [businessIdMaster]='businessIdMaster' *ngIf="modalId === 'customMasterModal'"></app-create-custom-master>
  <app-create-custom-standalone [businessIdMaster]='businessIdMaster' *ngIf="modalId === 'customModal'"></app-create-custom-standalone>
  <div class="ion-text-center ion-margin-bottom"><a role="button" (click)='closeModal();'>Cancel</a></div>
  </ion-content>
  
  