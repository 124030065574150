<section class="form-modal-content">
  <form [formGroup]="businessForm" (ngSubmit)="onBusinessSubmit()">
    <div>
      <fieldset>
        <legend>
          <h4>Business</h4>
        </legend>
        <div>
          <ion-list lines="full" class="ion-no-margin ion-no-padding">
            <ion-item hidden>
              <ion-label position="floating"> Merchant ID
                <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-input required type="text" formControlName="merchantId"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="floating"> Company Name
                <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-input required type="text" formControlName="name"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="floating"> Vertical
                <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-input type="text" formControlName="vertical">
              </ion-input>
            </ion-item>

            <ion-item>
              <ion-label>Enabled</ion-label>
              <ion-toggle checked formControlName="isEnabled"></ion-toggle>
            </ion-item>
            <ion-item>
              <ion-label position="stacked">Address <ion-text color="danger">*</ion-text>
              </ion-label>
              <div formGroupName="address">
                <ion-input placeholder="Address Line 1" type="text" formControlName="line1"></ion-input>
                <ion-input placeholder="Address Line 2" type="text" formControlName="line2"></ion-input>
                <ion-input placeholder="City" type="text" formControlName="city"></ion-input>
                <ion-input placeholder="State" type="text" formControlName="state"></ion-input>
                <ion-input placeholder="Zip Code" type="text" formControlName="zip"></ion-input>
                <ion-input placeholder="Country" type="text" formControlName="country"></ion-input>
              </div>
            </ion-item>

            <!-- Digital address fields -->
            <div formArrayName="digitalAddresses">
              <div formGroupName="0">
                <ion-item>
                  <ion-label position="floating"> Digital Address Type </ion-label>
                  <ion-input readonly placeholder="EMAIL" type="text" formControlName="addressType" value="EMAIL">
                  </ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating"> Email Address </ion-label>
                  <ion-input placeholder="john.doe@simpayx.com" type="text" formControlName="address">
                  </ion-input>
                </ion-item>
                <ion-item>
                  <ion-label>Prefer Email Contact?</ion-label>
                  <ion-toggle checked formControlName="preferred"></ion-toggle>
                </ion-item>
              </div>
              <div formGroupName="1">
                <ion-item>
                  <ion-label position="floating"> Digital Address Type </ion-label>
                  <ion-input readonly placeholder="CELL" type="text" formControlName="addressType" value="CELL">
                  </ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating"> Phone </ion-label>
                  <ion-input placeholder="444-444-4444" type="text" formControlName="address"></ion-input>
                </ion-item>
                <ion-item>
                  <ion-label>Preferred Phone Contact?</ion-label>
                  <ion-toggle checked formControlName="preferred"></ion-toggle>
                </ion-item>
              </div>
              <div formGroupName="2">
                <ion-item>
                  <ion-label position="floating"> Digital Address Type </ion-label>
                  <ion-input readonly type="text" formControlName="addressType" placeholder="WEBSITE">
                  </ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating"> URL </ion-label>
                  <ion-input placeholder="https://example.com" type="text" formControlName="address"></ion-input>
                </ion-item>
              </div>
              <div formGroupName="3">
                <ion-item>
                  <ion-label position="floating"> Digital Address Type </ion-label>
                  <ion-input readonly type="text" formControlName="addressType" placeholder="LOCATION URL">
                  </ion-input>
                </ion-item>
                <ion-item>
                  <ion-label position="floating"> Location </ion-label>
                  <ion-input placeholder="" type="text" formControlName="address"></ion-input>
                </ion-item>
              </div>
            </div>
            <!-- Digital address end -->

            <div formGroupName="businessAttributes">
              <!--Taxes-->
              <div formArrayName="taxes">
                <div
                  *ngFor="let tax of businessForm.controls.businessAttributes['controls'].taxes['controls']; let i=index">
                  <div>
                    <ion-button size="small" fill="clear" (click)="removeTaxes(i)">
                      <ion-icon slot="start" name="remove-circle-outline"></ion-icon>
                      Clear Tax
                    </ion-button>
                  </div>
                  <div [formGroupName]="i">
                    <ion-item>
                      <ion-label position="floating"> Tax Name </ion-label>
                      <ion-input placeholder="Sales Tax" type="text" formControlName="name"></ion-input>
                    </ion-item>
                    <ion-item>
                      <ion-label position="floating"> Tax Rate </ion-label>
                      <ion-input placeholder="8.6" type="number" formControlName="rate"></ion-input>
                    </ion-item>
                  </div>
                </div>
                <div>
                  <ion-button size="small" fill="clear" (click)="addTaxes()">
                    <ion-icon slot="start" name="add-circle-outline"></ion-icon>
                    Add Tax
                  </ion-button>
                </div>
              </div>
            </div>
          </ion-list>
        </div>
      </fieldset>
    </div>
    <br>
    <ion-button expand="block" type="submit" [disabled]="!businessForm.valid">Create business</ion-button>
  </form>
</section>