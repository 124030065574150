<ion-grid>
    <ion-row>
        <ion-col size="8" offset="2">
            <ion-card [class.red-border]="this.businessForm.controls.basicDetails.invalid">
                <ion-card-header>
                    <ion-grid class="ion-no-padding ion-no-margin">
                        <ion-row>
                            <ion-col size="11">
                                <ion-label class="section-title">Basic Details</ion-label>
                            </ion-col>
                            <ion-col size="1" class="ion-text-end" (click)="onEditStep(stepper, 0)">
                                <ion-icon name="create-outline"></ion-icon>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-card-header>

                <ion-card-content>
                    <ion-grid>
                        <ion-row>
                            <ion-col size="4">
                                <ion-label>Business Name</ion-label>
                            </ion-col>
                            <ion-col size="1">
                                <ion-label>:</ion-label>
                            </ion-col>
                            <ion-col size="7">
                                <ion-label class="preview-value">{{ businessForm?.get("basicDetails").get("name").value }}
                                </ion-label>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col size="4">
                                <ion-label>Vertical</ion-label>
                            </ion-col>
                            <ion-col size="1">
                                <ion-label>:</ion-label>
                            </ion-col>
                            <ion-col size="7">
                                <ion-label class="preview-value">{{ businessForm?.get("basicDetails").get("vertical").value }}
                                </ion-label>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col size="4">
                                <ion-label>Enabled</ion-label>
                            </ion-col>
                            <ion-col size="1">
                                <ion-label>:</ion-label>
                            </ion-col>
                            <ion-col size="7">
                                <ion-label class="preview-value" *ngIf="
                    businessForm?.get('basicDetails').get('isEnabled').value
                  ">Yes</ion-label>
                                <ion-label class="preview-value" *ngIf="
                    !businessForm?.get('basicDetails').get('isEnabled').value
                  ">No</ion-label>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-card-content>
            </ion-card>
        </ion-col>
    </ion-row>

    <ion-row>
        <ion-col size="8" offset="2">
            <ion-card [class.red-border]="this.businessForm.controls.address.invalid">
                <ion-card-header>
                    <ion-grid class="ion-no-padding ion-no-margin">
                        <ion-row>
                            <ion-col size="11">
                                <ion-label class="section-title">Address Details</ion-label>
                            </ion-col>
                            <ion-col size="1" class="ion-text-end" (click)="onEditStep(stepper, 1)">
                                <ion-icon name="create-outline"></ion-icon>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-card-header>
                <ion-card-content>
                    <ion-grid>
                        <ion-row>
                            <ion-col size="4">
                                <ion-label>Address</ion-label>
                            </ion-col>
                            <ion-col size="1">
                                <ion-label>:</ion-label>
                            </ion-col>
                            <ion-col size="7">
                                <ion-label class="preview-value">{{ businessForm?.get("address").get("line1").value + " " + businessForm.get("address").get("line2").value }}
                                </ion-label>
                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col size="4">
                                <ion-label>City</ion-label>
                            </ion-col>
                            <ion-col size="1">
                                <ion-label>:</ion-label>
                            </ion-col>
                            <ion-col size="7">
                                <ion-label class="preview-value">{{ businessForm?.get("address").get("city").value }}
                                </ion-label>
                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col size="4">
                                <ion-label>State</ion-label>
                            </ion-col>
                            <ion-col size="1">
                                <ion-label>:</ion-label>
                            </ion-col>
                            <ion-col size="7">
                                <ion-label class="preview-value">{{ businessForm?.get("address").get("state").value }}
                                </ion-label>
                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col size="4">
                                <ion-label>Country</ion-label>
                            </ion-col>
                            <ion-col size="1">
                                <ion-label>:</ion-label>
                            </ion-col>
                            <ion-col size="7">
                                <ion-label class="preview-value">{{ businessForm.get("address").get("country").value }}
                                </ion-label>
                            </ion-col>
                        </ion-row>

                        <ion-row>
                            <ion-col size="4">
                                <ion-label>Zip Code</ion-label>
                            </ion-col>
                            <ion-col size="1">
                                <ion-label>:</ion-label>
                            </ion-col>
                            <ion-col size="7">
                                <ion-label class="preview-value">{{ businessForm.get("address").get("zip").value }}
                                </ion-label>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-card-content>
            </ion-card>
        </ion-col>
    </ion-row>

    <ion-row>
        <ion-col size="8" offset="2">
            <ion-card [class.red-border]="this.businessForm.controls.digitalAddresses.invalid">
                <ion-card-header>
                    <ion-grid class="ion-no-padding ion-no-margin">
                        <ion-row>
                            <ion-col size="11">
                                <ion-label class="section-title">Digital Address</ion-label>
                            </ion-col>
                            <ion-col size="1" class="ion-text-end" (click)="onEditStep(stepper, 2)">
                                <ion-icon name="create-outline"></ion-icon>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-card-header>
                <ion-card-content>
                    <div *ngFor="let address of businessForm.value.digitalAddresses">
                        <ion-row>
                            <ion-col size="4">
                                <ion-label>{{ address?.addressType | titlecase }}</ion-label>
                            </ion-col>
                            <ion-col size="1">
                                <ion-label>:</ion-label>
                            </ion-col>
                            <ion-col size="7">
                                <ion-label class="preview-value">{{ address?.address }}
                                </ion-label>
                            </ion-col>
                        </ion-row>
                    </div>
                </ion-card-content>
            </ion-card>
        </ion-col>
    </ion-row>

    <ion-row>
        <ion-col size="8" offset="2">
            <ion-card [class.red-border]="this.businessForm.controls.businessAttributes.invalid">
                <ion-card-header>
                    <ion-grid class="ion-no-padding ion-no-margin">
                        <ion-row>
                            <ion-col size="11">
                                <ion-label class="section-title">Taxes</ion-label>
                            </ion-col>
                            <ion-col size="1" class="ion-text-end" (click)="onEditStep(stepper, 3)">
                                <ion-icon name="create-outline"></ion-icon>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-card-header>
                <ion-card-content>
                    <div *ngFor="let tax of businessForm?.value?.businessAttributes?.taxes">
                        <ion-row>
                            <ion-col size="4">
                                <ion-label>{{ tax?.name }}</ion-label>
                            </ion-col>
                            <ion-col size="1">
                                <ion-label>:</ion-label>
                            </ion-col>
                            <ion-col size="7">
                                <ion-label class="preview-value">{{ tax?.rate }}%</ion-label>
                            </ion-col>
                        </ion-row>
                    </div>
                </ion-card-content>
            </ion-card>
        </ion-col>
    </ion-row>
</ion-grid>

<div class="ion-text-center ion-padding ion-margin">
    <ion-button *ngIf="!allData" (click)="submitBusinessDetails()" color="success">
        Submit
    </ion-button>
    <ion-button [disabled]="!businessForm.valid" *ngIf="allData" (click)="updateBusinessDetails()" color="success">
        Update
    </ion-button>
</div>