import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ModalComponent } from "../modal/modal.component";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-fab",
  templateUrl: "./fab.component.html",
  styleUrls: ["./fab.component.scss"],
})
export class FabComponent implements OnInit {
  dataReturned: any;
  constructor(
    public modalController: ModalController,
    public router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {}

  async openModal(e) {
    console.log(e);
    let modal;
    if (this.getTitle(e) === "Create Catalogue") {
      modal = await this.modalController.create({
        component: ModalComponent,
        componentProps: {
          paramID: e.currentTarget.id,
          paramTitle: this.getTitle(e),
          connectedId: this.route.snapshot.params.businessId,
          merchantId: this.route.snapshot.params.merchantId,
        },
        cssClass: "full-width-modal",
      });
    } else {
      modal = await this.modalController.create({
        component: ModalComponent,
        componentProps: {
          paramID: e.currentTarget.id,
          paramTitle: this.getTitle(e),
          connectedId: this.route.snapshot.params.merchantId,
        },
        cssClass: "full-width-modal",
      });
    }

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });

    return await modal.present();
  }

  getTitle(e) {
    switch (e.currentTarget.id) {
      case "merchantModal":
        return "Create Merchant";
      case "businessModal":
        return "Create Business";
      case "catalogueModal":
        return "Create Catalogue";
      case "categoryModal":
        return "Create Category";
      case "itemModal":
        return "Create Item";
      default:
        return "Create Merchant";
    }
  }
}
