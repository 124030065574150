<div [formGroup]="businessForm">
  <div formGroupName="businessAttributes">
    <div formArrayName="taxes">
      <div *ngFor="let tax of businessForm.controls.businessAttributes['controls'].taxes['controls']; let i=index">
        <div>
          <ion-button size="small" fill="clear" (click)="removeTaxes(i)">
            <ion-icon slot="start" name="remove-circle-outline"></ion-icon>
            Remove Tax
          </ion-button>
        </div>
        <div [formGroupName]="i">
          <ion-item>
            <ion-label position="floating"> Tax Name
              <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input placeholder="Sales Tax" type="text" formControlName="name"></ion-input>
          </ion-item>

          <app-control-messages [control]="tax.get('name')"></app-control-messages>

          <ion-item>
            <ion-label position="floating"> Tax Rate
              <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-grid class="ion-no-padding ion-no-margin">
              <ion-row>
                <ion-col size="1">
                  <ion-input placeholder="8.6" type="text" formControlName="rate"></ion-input>
                </ion-col>
                <ion-col>
                  <ion-input readonly value="%"></ion-input>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
          <app-control-messages [control]="tax.get('rate')"></app-control-messages>
        </div>
      </div>

      <div>
        <ion-button size="small" fill="clear" (click)="addTaxes()">
          <ion-icon slot="start" name="add-circle-outline"></ion-icon>
          Add Tax
        </ion-button>
      </div>

      <ion-grid>
        <ion-row>
  
            <ion-col class="ion-text-start ion-padding ion-margin">
              <ion-button (click)="onPreviousClicked(stepper)">
                Previous
                <ion-icon slot="start" name="arrow-back-outline"></ion-icon>
              </ion-button>
            </ion-col>
        
            <ion-col class="ion-text-end ion-padding ion-margin">
              <ion-button (click)="onTaxesNextClicked(stepper)">
                Next
                <ion-icon slot="end" name="arrow-forward-outline"></ion-icon>
              </ion-button>
          </ion-col>
          
        </ion-row>
      </ion-grid>

    </div>
  </div>
</div>