import { FormControl } from "@angular/forms";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      required: "This field is required",
      invalidEmailAddress: "Invalid email address",
      minlength: `Minimum length ${validatorValue.requiredLength}`,
      invalidTax: "Invalid Tax rate",
      invalidPhone: "Invalid phone number",
      invalidZip: "Invalid zip code",
      invalidPermalink: "Invalid permalink",
    };

    return config[validatorName];
  }

  static emailValidator(control) {
    console.log(control.value.length);

    if (control.value.length == 0) {
      return null;
    }
    // RFC 2822 compliant regex
    if (
      control.value.match(
        /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/
      )
    ) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  static taxValidator(control) {
    // RFC 2822 compliant regex
    if (control.value.match("^[1-9]+(.[1-9]{1,2})?$")) {
      return null;
    } else {
      return { invalidTax: true };
    }
  }

  static phoneValidator(control) {
    if (control.value.length == 0) {
      return null;
    }

    // RFC 2822 compliant regex
    var regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    if (control.value.match(regex)) {
      return null;
    } else {
      return { invalidPhone: true };
    }
  }

  static zipCodeValidator(control) {
    // RFC 2822 compliant regex
    var regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    if (control.value.match(regex)) {
      return null;
    } else {
      return { invalidZip: true };
    }
  }

  static permalinkValidator(control: FormControl) {
    var regex = /^[a-z0-9\-]+$/;
    if (control.value.match(regex)) {
      return null;
    } else {
      return { invalidPermalink: true };
    }
  }
}
