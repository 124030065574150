export class FeatureToggleModel {
    isEnabled: boolean
    activeFor: BusinessInfo[] = []
    constructor(public name: String) { }
}

export class BusinessInfo {
    constructor(public id: String, public name: String, public address: BusinessAddress) { }
}

export class BusinessAddress {
    constructor(public addressLine1, public addressLine2) { }
}