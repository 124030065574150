<div [formGroup]="businessForm">
    <div formGroupName="digitalAddresses">
        <div *ngFor="let d_address of digitalAddresses.controls; index as i">
            <div [formGroupName]="i">
                <ion-item>
                    <ion-label position="floating">
                        {{ d_address.value.addressType | titlecase }}
                    </ion-label>
                    <div class="d-flex input-width">
                        <ion-input type="text" formControlName="address"> </ion-input>
                        <!-- <div> -->
                        <ion-item *ngIf="
                d_address.value.addressType === 'EMAIL' ||
                d_address.value.addressType === 'CELL'
              ">
                            <ion-label class="margin-label"> Preferred </ion-label>
                            <ion-checkbox formControlName="preferred" value="d_address.value.preferred"></ion-checkbox>
                        </ion-item>
                        <!-- </div> -->
                    </div>
                </ion-item>
                <app-control-messages [control]="d_address.get('address')"></app-control-messages>
            </div>
        </div>

        <ion-grid>
            <ion-row>
                <ion-col class="ion-text-start ion-padding ion-margin">
                    <ion-button (click)="onPreviousClicked(stepper)">
                        Previous
                        <ion-icon slot="start" name="arrow-back-outline"></ion-icon>
                    </ion-button>
                </ion-col>

                <ion-col class="ion-text-end ion-padding ion-margin">
                    <ion-button (click)="onDigitalAddressNextClicked(stepper)">
                        Next
                        <ion-icon slot="end" name="arrow-forward-outline"></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</div>