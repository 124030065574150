import { Component, Input } from "@angular/core";
import { FormGroup, FormArray, FormBuilder } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";

@Component({
  selector: "app-digital-address",
  templateUrl: "./digital-address.component.html",
  styleUrls: ["./digital-address.component.scss"],
})
export class DigitalAddressComponent {
  @Input() businessForm: FormGroup;
  @Input() stepper: MatStepper;

  constructor(private fb: FormBuilder) {}

  onDigitalAddressNextClicked(stepper: MatStepper) {
    this.businessForm.getRawValue();
    stepper.next();
  }

  onPreviousClicked(stepper: MatStepper) {
    stepper.previous();
  }

  get digitalAddresses(): FormArray {
    return this.businessForm.get("digitalAddresses") as FormArray;
  }
}
