<div #expandWrapper class='expand-wrapper' [class.collapsed]="!expanded">

  <div *ngIf="type === platformType.RESERVATION">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label position="floating"> Average service time
              <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-select mode="ios" interface="alert" [(ngModel)]="selectedAverageServiceTime">
              <ion-select-option *ngFor="let serviceTime of averageServiceTimes" value={{serviceTime}}>{{serviceTime}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label position="floating"> Tables alloted for reservation
              <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input [(ngModel)]="serviceCapacity" required type="number"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <ion-card>
    <ion-card-header class="d-flex ion-align-items-center">
      <ion-icon name="restaurant-outline" color="primary" size="large"></ion-icon>
      <ion-label class="ion-padding-start spx-bold spx-font-color-black spx-font-16">Manage business hours for {{type}}</ion-label>
    </ion-card-header>

    <ion-card-content>
      <ng-container *ngFor="let time of additionTiming; index as i">
        <ion-row>
          <ion-col size="4" class="d-flex ion-align-items-center ion-justify-content-start">
            <ion-item lines="none" class="day-selector">
              <ion-label *ngIf="i===0">{{selection}}
                <span class="margin-left-6" *ngIf="isCustom && i===0" class="d-flex spx-font-color-black">(<p class="margin-top-2" *ngFor="let customDay of customDays; index as i">{{customDay | slice:0:3}}<span *ngIf="i !== (customDays.length-1)">,</span> </p>)
              </span>
              </ion-label>  
            <ion-select class="spx-font-color-black myCustomSelect" *ngIf="i===0" mode="ios" value="selection" placeholder="select Day" interface="action-sheet"
              (ionChange)="currentBusiness($event)" (ionBlur)="onSelectBlur()">
              <ion-select-option  *ngFor="let day of selector; index as i" [value]="selectorValue[i]">{{day}}</ion-select-option>
            </ion-select>
          </ion-item>
          </ion-col>
          <ion-col size="3" class="d-flex ion-align-items-center ion-justify-content-start">
            <ion-datetime class="spx-font-color-black" placeholder="Open At" displayFormat="h:mm A" pickerFormat="h mm A"
              (ionChange)="openTimeAdd($event,i)"></ion-datetime>
          </ion-col>
          <ion-col size="3" class="d-flex ion-align-items-center ion-justify-content-start">
            <ion-datetime class="spx-font-color-black" placeholder="Close At" displayFormat="h:mm A" pickerFormat="h mm A"
              (ionChange)="closeTimeAdd($event,i)"></ion-datetime>
          </ion-col>
          <ion-col size="2" *ngIf="i === 0" class="ion-align-items-center d-flex ion-justify-content-center"> 
            <ion-button size="small"  (click)="addtiming()">Save</ion-button>
          </ion-col>
          <ion-col size="2" class="ion-text-left" class="ion-align-items-center d-flex ion-justify-content-center" *ngIf="i>0">
              <span>
              <ion-icon size="small" (click)="removeRows(i)" slot="icon-only" name="trash-outline"></ion-icon>
              </span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="4"></ion-col>
          <ion-col size="3"  class="ion-align-items-center d-flex " *ngIf="showAdditionalBusinessHourOpen && showAdditionalBusinessHourClose && i === (additionTiming.length-1)">
            <span><ion-icon class="add-icon" name="add-circle-outline"></ion-icon></span><a class="underline" (click)="addRows()"> business hours</a>
          </ion-col>
          <ion-col size="5"></ion-col>
        </ion-row>
      </ng-container>
      <ion-row>
        <ion-col class="ion-text-center" size="12">
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>
  <ion-card>
    <ion-row *ngIf="!isAddedHour"><ion-col class="ion-padding">Please add the business hours</ion-col></ion-row>
    <ion-card-content *ngIf="isAddedHour">
      <ion-grid>
        <ion-row *ngFor="let day of weekday;index as j" style="border-bottom: 1px solid #cccccc;">
          <ion-col size="4">
            <ion-item lines="none">
              <ion-label class="ion-no-margin"><h4>{{day}}</h4></ion-label>
            </ion-item>
          </ion-col>
          <ion-col size="3" *ngIf="!checkForClosed(day);" class="ion-no-padding">
            <ion-item lines="none" *ngFor="let hours of businessTimings[day].businessHours;index as i" class="ion-text-right ion-no-padding">
              <ion-label class="ion-no-margin" [ngClass]="{'closed': checkForClosed(day)}" *ngIf="!checkForClosed(day) && businessTimings[day].businessHours.length > 0"><h4>{{hours.opensAt.split(':', 3)[0].concat(':').concat(hours.opensAt.split(':', 3)[1])}} - {{hours.closesAt.split(':', 3)[0].concat(':').concat(hours.closesAt.split(':', 3)[1])}}</h4></ion-label>
            </ion-item>
            <ion-item lines="none" *ngIf="businessTimings[day].businessHours.length === 0" class="ion-text-right ion-no-padding">
              <ion-label class="margin-right-35"><h1>-</h1></ion-label>
            </ion-item>
          </ion-col>
          <!-- <ng-template #elseBlockGrid> -->
            <ion-col size="5" *ngIf="checkForClosed(day);" class="ion-no-padding ion-align-items-center d-flex ion-justify-content-center padding-left-24">
                <ion-label class="ion-no-margin" [ngClass]="{'closed': checkForClosed(day)}" *ngIf="checkForClosed(day)">Closed for day</ion-label>
            </ion-col>
            <!-- </ng-template> -->
          <ion-col size="2" *ngIf="!checkForClosed(day)">
            <ion-item lines="none" *ngFor="let hours of businessTimings[day].businessHours;index as i" class="ion-text-left ion-no-padding">
                <span class="remove-icon">
                <ion-icon color="danger"  (click)="removeTimeSlot(day,i)" size="large" slot="icon-only" name="close-outline" class="ion-text-left ion-no-padding icon-cross"></ion-icon>
              </span>
            </ion-item>
          </ion-col>
          <!-- <ion-col size="" *ngIf="checkForClosed(day)"></ion-col> -->
          <ion-col size="3" *ngIf="checkForClosed(day)" class="ion-no-padding ion-align-items-center d-flex ion-justify-content-end">
            <ion-button color="danger" class="ion-no-margin width-64" size="small"(click)="closedCheck(day)">Close</ion-button>
          </ion-col>
          <ion-col size="3" *ngIf="!checkForClosed(day)" class="ion-no-padding ion-align-items-center d-flex ion-justify-content-end">
              <ion-button color="success" class="ion-no-margin width-64" size="small"(click)="closedCheck(day)">Open</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-row>
        <ion-col class="ion-text-center">
        </ion-col>
      </ion-row>
    </ion-card-content>
  </ion-card>
</div>