<section class="form-modal-content">
  <form [formGroup]="verticalForm" (ngSubmit)="onCreateVerticalSubmit()">

    <fieldset>
      <legend>
        <h3>CREATE VERTICAL: </h3>
      </legend>
      <div>
        <ion-list lines="full" class="ion-no-margin ion-no-padding">
          <ion-item>
            <ion-label position="floating"> Type <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input required type="text" formControlName="type"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating"> Name </ion-label>
            <ion-input type="text" formControlName="subType"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>Enabled</ion-label>
            <ion-toggle checked formControlName="isEnabled"></ion-toggle>
        </ion-item>
        </ion-list>
      </div>
    </fieldset>
    <ion-button expand="block" type="submit" [disabled]="!verticalForm.valid">Create Vertical</ion-button>
  </form>
</section>