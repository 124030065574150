import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { IsEnabledCatalogueComponent } from './is-enabled-catalogue.component';



@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    FormsModule
  ],
  entryComponents: [IsEnabledCatalogueComponent],
  declarations: [IsEnabledCatalogueComponent],
  exports: [IsEnabledCatalogueComponent]
})
export class IsEnabledCatalogueModule { }
