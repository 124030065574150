import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ActionCellsComponent } from './action-cells.component';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule
  ],
  entryComponents: [ActionCellsComponent],
  declarations: [ActionCellsComponent],
  exports: [ActionCellsComponent]
})
export class ActionCellsModule { }
