import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CustomizationInput } from 'src/app/feature/catalogue/categories/category/menu-items/item/customization/optionsInput.model';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-custom-standalone',
  templateUrl: './create-custom-standalone.component.html',
  styleUrls: ['./create-custom-standalone.component.scss'],
})
export class CreateCustomStandaloneComponent implements OnInit {
  customization: CustomizationInput;
  choiceLimitSubscription: Subscription;
  
  @Input() businessIdMaster;
  isDefault = false;
  isDefaultNum = 0;
  choiceLimitNum = 1;
  choiceLimitCarrier: number;

  data = [{
    name: '',
    isEnabled: true,
    options: [
      {
        name: '',
        price: 0,
        isDefault: false
      }
    ],
    description: '',
    choiceLimit: 10
  }];
  customStandaloneForm = new FormGroup({});
  
  constructor(private fb: FormBuilder,
              private modalController: ModalController) {
                this.customStandaloneForm = this.fb.group({
                  customization: this.fb.array([]),
                });
     }
     ngOnInit() {
      this.setCustomization();
      // tslint:disable-next-line: no-string-literal
      this.choiceLimitSubscription = this.customStandaloneForm['controls'].customization['controls'][0].controls.choiceLimit.valueChanges.subscribe(val => {
  
        val = Math.round(val);
        this.choiceLimitCarrier = val;
  
        if (val > this.choiceLimitNum) {
          // tslint:disable-next-line: max-line-length
          this.customStandaloneForm['controls'].customization['controls'][0].controls.choiceLimit._parent.get('choiceLimit').setValue(this.choiceLimitNum);
        }
      });
    }
    setCustomization() {
      const control = this.customStandaloneForm.controls.customization as FormArray;
      this.data.forEach(x => {
        control.push(this.fb.group({
          businessId: new FormControl(this.businessIdMaster, [ Validators.required, Validators.minLength(10)]),
          name: new FormControl(x.name, [ Validators.required, Validators.minLength(4)]),
          description: new FormControl(x.description),
          isEnabled: new FormControl(x.isEnabled),
          options: this.setOptions(x),
          choiceLimit: new FormControl(this.isDefaultNum, [
            Validators.pattern("^[0-9]*$"),
            Validators.min(this.isDefaultNum),
            Validators.max(this.choiceLimitNum)
          ])
          }));
      });
    }
  
    // Item Methods
    addNewOption(control) {
      control.push(
        this.fb.group({
          name: [''],
          price: [],
          isDefault: [false]
        }));
      this.choiceLimitNum = control.value.length;
      // tslint:disable-next-line: no-string-literal
      this.customStandaloneForm['controls'].customization['controls'][0].controls.choiceLimit.setValidators(
      [Validators.pattern("^[0-9]*$"),
      Validators.min(this.isDefaultNum),
      Validators.max(this.choiceLimitNum),
      Validators.maxLength(1)]
    );
      this.customStandaloneForm.updateValueAndValidity();
    }
  
    deleteOption(control, index) {
      control.removeAt(index);
      this.isDefaultNum = 0;
      control.value.forEach(defaultItem => {
        if (defaultItem.isDefault){
          this.isDefaultNum += 1;
        }
      });
      this.choiceLimitNum = control.value.length;
      // tslint:disable-next-line: no-string-literal
      this.customStandaloneForm['controls'].customization['controls'][0].controls.choiceLimit.setValidators(
      [Validators.pattern("^[0-9]*$"),
      Validators.min(this.isDefaultNum),
      Validators.max(this.choiceLimitNum),
      Validators.maxLength(1)]
    );
      this.customStandaloneForm.updateValueAndValidity();
    }
  
    updateDefaultOptionNum(control) {
      console.log(control.value);
      this.isDefaultNum = 0;
      setTimeout(() => {
        control.value.forEach(defaultItem => {
          if (defaultItem.isDefault){
            this.isDefaultNum += 1;
          }
        });
        this.choiceLimitNum = control.value.length;
      }, 50);
    }
  
    setOptions(x) {
      const arr = new FormArray([]);
      x.options.forEach(y => {
        arr.push(this.fb.group({
          name: y.name,
          price: y.price,
          isDefault: y.isDefault
        }));
      });
      return arr;
    }

    onCreateCustomStandaloneSubmit() {
      this.modalController.dismiss(this.customStandaloneForm.value);
    }
  }
