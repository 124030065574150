import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Catalogue } from 'src/app/feature/catalogue/catalogue.model';

@Injectable({
  providedIn: 'root'
})
export class CataloguePackageService {

  private messageSource = new BehaviorSubject([]);
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(catPackage: any[]) {
    this.messageSource.next(catPackage);
  }
}
