<mat-horizontal-stepper #stepper>
    <mat-step>
        <ng-template matStepLabel>Business Attributes</ng-template>
        <ion-grid>
            <!-- <simpayx-information *ngIf="allData" [business]="allData.data"></simpayx-information> -->
            <simpayx-application-theme *ngIf="allData" [business]="allData.data"></simpayx-application-theme>
            <simpayx-service-platform *ngIf="allData" [business]="allData.data"></simpayx-service-platform>
        </ion-grid>
    </mat-step>

    <mat-step>
        <ng-template matStepLabel>Business Permalink</ng-template>
        <ion-grid>
            <simpayx-permalink *ngIf="allData" [business]="allData.data"></simpayx-permalink>
        </ion-grid>
    </mat-step>

    <mat-step>
        <ng-template matStepLabel>Login Credentials</ng-template>
        <!-- ------------------------------------ Create  user for business---------------------------------->
        <!-- <ion-grid> -->
        <simpayx-business-login *ngIf="allData" [business]="allData.data"></simpayx-business-login>
        <!-- </ion-grid> -->
    </mat-step>

    <mat-step>
        <ng-template matStepLabel>Settlement Account</ng-template>
        <app-create-account [allData]='allData'></app-create-account>
    </mat-step>

</mat-horizontal-stepper>