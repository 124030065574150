import { MerchantBusinessService } from "src/app/shared/services/merchant-business.service";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import {
  BusinessInput,
  BusinessPatchInput,
} from "../../business-details/business.model";
import { MatStepper } from "@angular/material/stepper";
import { FormGroup } from "@angular/forms";
import { BusinessService } from "src/app/shared/services/business.service";
import { ModalController } from "@ionic/angular";
import { Subscription } from "rxjs";

@Component({
  selector: "app-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
})
export class PreviewComponent implements OnInit, OnDestroy {
  @Input() businessForm: FormGroup;
  @Input() stepper: MatStepper;
  @Input() merchantId: string;
  @Input() allData: any;
  business: BusinessInput;
  createBusinessQuerySubscription: Subscription;

  constructor(
    private businessService: BusinessService,
    private merchantBusinessService: MerchantBusinessService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    console.log(this.businessForm.controls);

    console.log(this.businessForm.controls.address.valid);
    console.log(this.businessForm.controls.basicDetails.valid);
    console.log(this.businessForm.controls.businessAttributes.valid);
    console.log(this.businessForm.controls.digitalAddresses.valid);

    this.business = new BusinessInput();
  }

  onEditStep(stepper: MatStepper, index: number) {
    stepper.selectedIndex = index;
  }

  submitBusinessDetails() {
    // create business call
    console.log(this.businessForm.value);
    this.business.merchantId = this.merchantId;
    this.business.name = this.businessForm.value.basicDetails.name;
    this.business.vertical = this.businessForm.value.basicDetails.vertical;
    this.business.isEnabled = this.businessForm.value.basicDetails.isEnabled;
    this.business.address = this.businessForm.value.address;
    this.business.businessAttributes.taxes = this.businessForm.value.businessAttributes.taxes;
    this.business.businessAttributes.taxes.forEach((tax) => {
      tax.rate = Number(tax.rate);
    });
    this.business.digitalAddresses = this.businessForm.value.digitalAddresses;
    console.log(this.business);
    this.createBusinessQuerySubscription = this.businessService
      .createBusinessForMerchant(this.business)
      .subscribe((response: any) => {
        console.log("Business created", response);
        this.business = response.data.CreateBusiness;
        this.modalCtrl.dismiss();
      });
  }

  updateBusinessDetails() {
    console.log("UPDATE");
    this.business.id = this.allData.data.id;
    this.business.merchantId = this.merchantId;
    this.business.name = this.businessForm.value.basicDetails.name;
    this.business.vertical = this.businessForm.value.basicDetails.vertical;
    this.business.isEnabled = this.businessForm.value.basicDetails.isEnabled;
    this.business.address = this.businessForm.value.address;
    this.business.businessAttributes.taxes = this.businessForm.value.businessAttributes.taxes;
    this.business.businessAttributes.taxes.forEach((tax) => {
      tax.rate = Number(tax.rate);
    });
    this.business.digitalAddresses = this.businessForm.value.digitalAddresses;
    console.log(this.business);

    this.createBusinessQuerySubscription = this.businessService
      .updateBusiness(this.merchantId, this.allData.data.id, this.business)
      .subscribe((response: any) => {
        console.log("Business updated", response);
        this.merchantBusinessService.getBusinessForMerchant(this.merchantId);
        this.modalCtrl.dismiss();
      });
  }

  ngOnDestroy(): void {
    if (this.createBusinessQuerySubscription) {
      this.createBusinessQuerySubscription.unsubscribe();
    }
  }
}
