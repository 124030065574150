<section class="form-modal-content">
  <form [formGroup]="categoryForm" (ngSubmit)="onCategorySubmit()">
      <div>
        <fieldset>
          <legend>
            <h4>Category</h4>
          </legend>
          <div>
            <ion-list lines="full" class="ion-no-margin ion-no-padding">
              <ion-item>
                <ion-label position="floating"> Category Name <ion-text color="danger">*</ion-text>
                </ion-label>
                <ion-input required type="text" formControlName="categoryName"></ion-input>
              </ion-item>
            </ion-list>
          </div>
        </fieldset>
      </div>
    <br>
    <ion-button expand="block" type="submit" [disabled]="!categoryForm.valid">Create category</ion-button>
  </form>
</section>