import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { Subscription } from 'rxjs';
import { ItemInput } from 'src/app/feature/catalogue/categories/category/menu-items/item/itemInput.model';

@Component({
  selector: 'app-create-item-master',
  templateUrl: './create-item-master.component.html',
  styleUrls: ['./create-item-master.component.scss'],
})
export class CreateItemMasterComponent implements OnInit, OnDestroy  {
  @Input() businessIdMaster;
  createItemQuerySubscription: Subscription;
  item: ItemInput;

  itemMasterForm = new FormGroup({
    // id: new FormControl('', [Validators.required, Validators.minLength(10)]),
    name: new FormControl('', [Validators.required, Validators.minLength(4)]),
    description: new FormControl(''),
    price: new FormControl(''),
    isEnabled: new FormControl(true),
  });

  constructor(private fb: FormBuilder,
              private modalController: ModalController,
              private catalogueService: CatalogueService) { }

  ngOnDestroy(): void {
    if (this.createItemQuerySubscription) {
      this.createItemQuerySubscription.unsubscribe();
    }
  }

  ngOnInit() {
    // this.itemMasterForm.get('id').setValue(this.businessIdMaster);
  }

  formatDollarsToCents(value) {
    value = value.toString();
    value = (value + '').replace(/[^\d.-]/g, '');
    if (value && value.includes('.')) {
      value = value.substring(0, value.indexOf('.') + 3);
    }
    return value ? Math.round(parseFloat(value) * 100) : 0;
  }

  onCreateItemMasterSubmit() {
    const formattedItem = JSON.stringify(this.itemMasterForm.value, (key, value) => {
      if (key === 'price') {
        value = this.formatDollarsToCents(value);
        value = Number(value);
        return value;
      }
      return value;
    });
    const formatItemForUpdate = JSON.parse(formattedItem);
    console.log(formatItemForUpdate);
    console.log(this.itemMasterForm.value);
    this.item = formatItemForUpdate;
    this.item.price = Number(this.item.price);
    this.catalogueService.createItem(this.businessIdMaster, this.item).subscribe((itemMasterResponse) => {
    console.log(itemMasterResponse);
    }, (err) => {console.log(err)});
  
    this.modalController.dismiss();
  }

}
