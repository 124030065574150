import { MerchantBusinessService } from "src/app/shared/services/merchant-business.service";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { BusinessService } from "src/app/shared/services/business.service";
import {
  BusinessInput,
  BusinessPatchInput,
} from "../../business-details/business.model";
import { Subscription } from 'rxjs';

@Component({
  selector: "simpayx-application-theme",
  templateUrl: "./application-theme.component.html",
  styleUrls: ["./application-theme.component.scss"],
})
export class ApplicationThemeComponent implements OnDestroy {
  @Input() business: BusinessInput;
  patchResourceQuerySubscription: Subscription;
  themes: any[] = [
    {
      name: "Flamingo Orange",
      value: "orange-theme",
    },
    {
      name: "Amaranth Red",
      value: "red-theme",
    },
    {
      name: "Lime Green",
      value: "green-theme",
    },
    {
      name: "Dodger Blue",
      value: "blue-theme",
    },
    {
      name: "Red Robin Brown",
      value: "brown-theme",
    },
    {
      name: "Ash Black",
      value: "black-theme",
    },
  ];

  constructor(
    private businessService: BusinessService,
    private merchantBusinessService: MerchantBusinessService
  ) {}

  onThemeChange(value: string) {
    let businessPatchInput: BusinessPatchInput = new BusinessPatchInput();
    this.business.businessAttributes.theme = value;
    businessPatchInput.theme = value;
   this.patchResourceQuerySubscription =  this.businessService
      .patchResource(
        this.business.merchantId,
        this.business.id,
        businessPatchInput
      )
      .subscribe((result: any) => {
        console.log(result.data.PatchBusiness);
        this.merchantBusinessService.getBusinessForMerchant(this.business.merchantId);
      });
  }

  ngOnDestroy(){
    if(this.patchResourceQuerySubscription){
      this.patchResourceQuerySubscription.unsubscribe();
    }
  }
}
