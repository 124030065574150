import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";

import { AgGridModule } from "ag-grid-angular";
import { HttpClientModule } from "@angular/common/http";
import { ActionCellsComponent } from "./fragment/action-cells/action-cells.component";
import { IsEnabledComponent } from "./fragment/is-enabled/is-enabled.component";
import { GraphQLModule } from "./graphql.module";
import { EventEmitterService } from "./shared/services/event-emitter.service";
import { ActionCellsModule } from "./fragment/action-cells/action-cells.module";
import { IsEnabledModule } from "./fragment/is-enabled/is-enabled.module";
import { IsEnabledBusinessModule } from "./fragment/is-enabled-business/is-enabled-business.module";
import { IsEnabledBusinessComponent } from "./fragment/is-enabled-business/is-enabled-business.component";
import { IsEnabledCatalogueComponent } from "./fragment/is-enabled-catalogue/is-enabled-catalogue.component";
import { IsEnabledCatalogueModule } from "./fragment/is-enabled-catalogue/is-enabled-catalogue.module";
import { DragulaModule } from "ng2-dragula";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from "@angular/forms";
import { environment } from "src/environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import firebase from "firebase";

firebase.initializeApp(environment.firebase);
@NgModule({
  declarations: [AppComponent],
  entryComponents: [
    ActionCellsComponent,
    IsEnabledComponent,
    IsEnabledBusinessComponent,
    IsEnabledCatalogueComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    AgGridModule.withComponents([]),
    GraphQLModule,
    ActionCellsModule,
    IsEnabledModule,
    IsEnabledBusinessModule,
    IsEnabledCatalogueModule,
    DragulaModule.forRoot(),
    ReactiveFormsModule,
    AngularFireAuthModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    EventEmitterService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
