import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { DataService } from './../../shared/services/data.service';

@Component({
  selector: 'app-create-item',
  templateUrl: './create-item.component.html',
  styleUrls: ['./create-item.component.scss'],
})
export class CreateItemComponent implements OnInit {
  message: Array<any>;
  itemList = [];
  itemForm = new FormGroup({
    itemName: new FormControl('', [
      Validators.required,
      Validators.minLength(1)]),
  });
    constructor(private itemData: DataService) {

      this.itemData.currentMessage.subscribe(message => this.message = message);
    }

  ngOnInit() {}

  onItemSubmit() {
    // Submit API

  }

}
