import { Component, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ModalComponent } from '../../shared/modal/modal.component';
import { ProfilePopoverComponent } from '../../profile-popover/profile-popover.component';

@Component({
  selector: 'app-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss'],
})
export class GlobalHeaderComponent implements OnInit {

  dataReturned: any;

  constructor(public modalController: ModalController, public popoverController: PopoverController) {}

  ngOnInit() {}

  async presentPopover() {
    const popover = await this.popoverController.create({
      component: ProfilePopoverComponent,
      event
    });
    return await popover.present();
  }

}
