import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { DataService } from './../../shared/services/data.service';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss'],
})
export class CreateCategoryComponent implements OnInit {
  message: Array<any>;
  categoryList = [];
  categoryForm = new FormGroup({
    categoryName: new FormControl('', [
      Validators.required,
      Validators.minLength(1)]),
  });
    constructor(private categoryData: DataService) {

      this.categoryData.currentMessage.subscribe(message => this.message = message);
    }

  ngOnInit() {}

  onCategorySubmit() {
    // Submit API

  }

}
