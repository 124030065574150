import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-builder-popover',
  templateUrl: './builder-popover.component.html',
  styleUrls: ['./builder-popover.component.scss'],
})
export class BuilderPopoverComponent implements OnInit, OnDestroy {
  @Input() sectionType;
  @Input() section;
  @Input() targetCategoryPosition;
  @Input() targetItemPosition;
  @Input() targetCustomizationPosition;
  @Input() businessId;
  createCategoryQuerySubscription: Subscription;
  createItemQuerySubscription: Subscription;
  createCustomizationQuerySubscription: Subscription;

  duplicateData: any;

  constructor(private popoverController: PopoverController, private catalogueService: CatalogueService) { }

  ngOnInit() {}
  ngOnDestroy() {
    if (this.createCategoryQuerySubscription) {
      this.createCategoryQuerySubscription.unsubscribe();
    }
    if (this.createItemQuerySubscription) {
      this.createItemQuerySubscription.unsubscribe();
    }
    if (this.createCustomizationQuerySubscription) {
      this.createCustomizationQuerySubscription.unsubscribe();
    }
  }
  // This option allows the user to save Categories, Items etc as a Master from the builder.
  saveNewPortion(sectionType, section, targetCategoryPosition, targetItemPosition, targetCustomizationPosition, businessId) {
    const formattedSection = this.sectionFormatter(section, businessId);

    if (sectionType === 'category') {
      console.log('sectionFormatter', formattedSection);
      // tslint:disable-next-line: max-line-length
      this.createCategoryQuerySubscription = this.catalogueService.createCategory(formattedSection, businessId).subscribe((categoryMasterResponse) => {
        console.log(categoryMasterResponse);
        }, (err) => {console.log(err)});
    } else if (sectionType === 'item') {
      
      // tslint:disable-next-line: max-line-length
      this.createItemQuerySubscription = this.catalogueService.createItem(businessId, formattedSection).subscribe((itemMasterResponse) => {
        console.log(itemMasterResponse);
        }, (err) => {console.log(err)});
    }else {
      this.createCustomizationQuerySubscription = this.catalogueService
      .createCustomization(formattedSection).subscribe((response: any) => {
        console.log('custom', response);
      }, (err) => {console.log(err)});
    }

    this.popoverController.dismiss('saveNew');
  }

  duplicatePortion(sectionType, section, targetCategoryPosition, targetItemPosition, targetCustomizationPosition, bussinessId) {
    this.duplicateData = JSON.parse(JSON.stringify(section));
    this.duplicateData.id = this.makeid(24);
    this.duplicateData.name = this.duplicateData.name + ' - Copy';
    this.popoverController.dismiss('duplication', this.duplicateData);
  }

  sectionFormatter(query, businessId) {
    
    return JSON.parse(JSON.stringify(query, (key, value) => {
      if (key === 'price') {
        value = this.formatDollarsToCents(value);
        value = Number(value);
        return value;
      }
      if (key === 'id' && value === null || key === 'id' && value === undefined || key === 'id' && value === ''){
        value = this.makeid(24);
        return value;
      }
      if (key === 'businessId') {
        value = businessId;
        return value;
      }
      if (key === 'imageUrl') {
        value = '';
        return value;
      }
      return value;
    }));
  }

  formatDollarsToCents(value) {
    value = value.toString();
    value = (value + '').replace(/[^\d.-]/g, '');
    if (value && value.includes('.')) {
      value = value.substring(0, value.indexOf('.') + 3);
    }
    return value ? Math.round(parseFloat(value) * 100) : 0;
  }

  makeid(length) {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

}
