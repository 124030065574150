import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { IsEnabledBusinessComponent } from './is-enabled-business.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    FormsModule
  ],
  entryComponents: [IsEnabledBusinessComponent],
  declarations: [IsEnabledBusinessComponent],
  exports: [IsEnabledBusinessComponent]
})
export class IsEnabledBusinessModule { }
