export class BusinessInput {
  id: string;
  name: string;
  merchantId: string;
  vertical: string;
  address: AddressInput = new AddressInput();
  isEnabled: boolean = true;
  digitalAddresses: DigitalAddressInput[] = [
    new DigitalAddressInput(AddressType.EMAIL),
    new DigitalAddressInput(AddressType.CELL),
    new DigitalAddressInput(AddressType.WEBSITE),
    new DigitalAddressInput(AddressType.LOCATION_URL),
  ];
  businessAttributes: BusinessAttributesInput = new BusinessAttributesInput();

  // constructor(
  //   id: string,
  //   name: string,
  //   merchantId: string,
  //   vertical: string,
  //   address: AddressInput,
  //   isEnabled: boolean,
  //   digitalAddresses: DigitalAddressInput,
  //   businessAttributes: BusinessAttributesInput){}
}

export class AddressInput {
  zip: string = "";
  line1: string = "";
  line2: string = "";
  state: string;
  city: string;
  country: string = "";
  timeZone: string = "";
  locationUrl: string = "";

  //constructor(zip: string, line1: string, line2: string, state: string, city: string, country: string, timeZone: string){}
}
export class DigitalAddressInput {
  addressType: AddressType;
  address: string;
  preferred: boolean;

  constructor(addressType: AddressType) {
    this.addressType = addressType;
    this.address = "";
    this.preferred = false;
  }
}
export class BusinessAttributesInput {
  catalogues: string[] = new Array<string>();
  taxes: TaxInput[] = new Array<TaxInput>();
  theme: string = "red-theme";
  servicePlatforms: ServicePlatformInput[] = [
    new ServicePlatformInput(
      PlatformType.DINE_IN,
      ServicePlatformSubType.RESOURCE_PLATFORM
    ),
    new ServicePlatformInput(
      PlatformType.PICKUP,
      ServicePlatformSubType.RESOURCE_PLATFORM
    ),
    new ServicePlatformInput(
      PlatformType.TAKEOUT,
      ServicePlatformSubType.RESOURCE_PLATFORM
    ),
    new ServicePlatformInput(
      PlatformType.RESERVATION,
      ServicePlatformSubType.SLOT_PLATFORM
    ),
  ];

  //constructor(catalogues: string[], taxes: TaxInput, theme: string, servicePlatforms: ServicePlatformInput){}
}
export class ServicePlatformInput {
  type: PlatformType;
  subtype: ServicePlatformSubType;
  liveCatalogueId: string = "";
  platformFees: number = 0;
  isEnabled: boolean = false;
  resources: ResourcesInput[] = [];
  businessTimings: Map<String, BusinessTimingDetails>;
  avgServiceTimeInMin: number = 30;
  slots: Map<String, Slot> = new Map([]);

  //constructor(type: string, liveCatalogueId: string, platformFees: number, isEnabled: boolean, resources: ResourcesInput, businessTimings: JSON, avgServiceTimeInMin: number){}
  constructor(type: PlatformType, subType: ServicePlatformSubType) {
    this.type = type;
    this.subtype = subType;
  }
}

export enum ServicePlatformSubType {
  RESOURCE_PLATFORM = "ResourcePlatform",
  SLOT_PLATFORM = "SlotPlatform",
}

export class Slot {
  businessTimingDetails: BusinessTimingDetails;
  resources: ResourcesInput[];
}

export class BusinessTimingDetails {
  isOpen: Boolean;
  businessHours: BusinessHours[];
}

export class BusinessHours {
  opensAt: Date;
  closesAt: Date;
}

export class TaxInput {
  name: string;
  rate: number;

  //constructor(name: string, rate: number){}
}

export class ResourcesInput {
  id: string;
  name: string;
  isEnabled: boolean;
  attributes: Map<string, string> = new Map<string, string>([]);
  serviceCapacity: number;
  serviceTimeInMin: number;

  //constructor(id: string, name: string, isEnabled: boolean, attributes: JSON, serviceCapacity: number, serviceTimeInMin: number){}
}

export class LiveCatalogue {
  catalogueId: string;
  type: string;

  //constructor(catalogueId: string, type: string){}
}

export enum AddressType {
  EMAIL = "EMAIL",
  CELL = "CELL",
  WEBSITE = "WEBSITE",
  LOCATION_URL = "LOCATION_URL",
}

export enum PlatformType {
  DINE_IN = "DINE_IN",
  TAKEOUT = "TAKEOUT",
  PICKUP = "PICKUP",
  RESERVATION = "RESERVATION",
}

export const ServingPlatform = {
  DINE_IN: "Dine-In",
  TAKE_OUT: "Takeout",
  ONLINE: "Online",
};

export class BusinessPatchInput {
  operationType: string;
  operationField: string;
  // operations: Object;
  isEnabled: Boolean;
  digitalAddresses: DigitalAddressInput[];
  catalogues: String[];
  taxes: TaxInput[];
  platformFees: number;
  theme: String;
  // servicePlatforms: ServicePlatformInput;
  servicePlatformPatch: ServicePlatformPatch;
  address: AddressInput;
}

export class ServicePlatformPatch {
  servicePlatforms: ServicePlatformInput;
  isEnabled: Boolean;
  platformFees: number;
}

export class PermalinkMetaDataInput {
  id: string;
  shortName: string;
  isDefault: boolean;
  businessDetails: BusinessMetaData;
}

export class BusinessMetaData {
  merchantId: string;
  businessId: string;
}
