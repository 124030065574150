<section class="form-modal-content">
  <form [formGroup]="itemStandaloneForm" (ngSubmit)="onCreateItemStandaloneSubmit()">

    <fieldset>
      <legend>
        <h3>NEW ITEM: </h3>
      </legend>
      <div>
        <ion-list lines="full" class="ion-no-margin ion-no-padding">
          <!-- <ion-item hidden>
            <ion-label position="floating"> Business ID
                <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input required type="text" formControlName="id"></ion-input>
          </ion-item> -->
          <ion-item hidden>
            <ion-label position="floating"> ID
            </ion-label>
            <ion-input formControlName="id" type="number"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating"> Name <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input required type="text" formControlName="name"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating"> Description 
            </ion-label>
            <ion-input type="text" formControlName="description"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating"> Price
            </ion-label>
            <ion-input formControlName="price" type="number"></ion-input>
          </ion-item>
        </ion-list>
      </div>
    </fieldset>
    <ion-button expand="block" type="submit" [disabled]="!itemStandaloneForm.valid">Add Item</ion-button>
  </form>
</section>