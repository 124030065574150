import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { Subscription } from 'rxjs';
import { CategoryInput } from 'src/app/feature/catalogue/categories/categoryInput.model';

@Component({
  selector: 'app-create-category-master',
  templateUrl: './create-category-master.component.html',
  styleUrls: ['./create-category-master.component.scss'],
})
export class CreateCategoryMasterComponent implements OnInit, OnDestroy {
  @Input() businessIdMaster;
  createCategoryQuerySubscription: Subscription;
  category: CategoryInput;

  categoryMasterForm = new FormGroup({
    // id: new FormControl('', [Validators.required, Validators.minLength(10)]),
    name: new FormControl('', [Validators.required, Validators.minLength(4)]),
    description: new FormControl(''),
    isEnabled: new FormControl(true),
  });


  constructor(private fb: FormBuilder,
              private modalController: ModalController,
              private catalogueService: CatalogueService,
              private eventEmitterService: EventEmitterService) {

   }

  ngOnDestroy() {
    if (this.createCategoryQuerySubscription) {
      this.createCategoryQuerySubscription.unsubscribe();
    }
  }

  ngOnInit() {
    // this.categoryMasterForm.get('id').setValue(this.businessIdMaster);
    
  }

  onCreateCategoryMasterSubmit() {
    this.category = this.categoryMasterForm.value;
    this.catalogueService.createCategory(this.category, this.businessIdMaster).subscribe((categoryMasterResponse) => {
    console.log(categoryMasterResponse);
    }, (err) => {console.log(err)});

    this.modalController.dismiss();
  }

}
