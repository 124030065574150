import { BusinessLoginComponent } from './../feature/edit-business/business-login/business-login.component';
import { PermalinkComponent } from './../feature/edit-business/permalink/permalink.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { GlobalHeaderComponent } from './global-header/global-header.component';
import { ProfilePopoverComponent } from './../profile-popover/profile-popover.component';
import { RouterModule } from '@angular/router';
import { ModalComponent } from './modal/modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateMerchantComponent } from '../feature/create-merchant/create-merchant.component';
import { EditMerchantComponent } from './../feature/edit-merchant/edit-merchant.component';
import { CreateBusinessComponent } from '../feature/create-business/create-business.component';
import { CreateCatalogueComponent } from '../feature/create-catalogue/create-catalogue.component';
import { CreateCategoryComponent } from '../feature/create-category/create-category.component';
import { CreateItemComponent } from '../feature/create-item/create-item.component';
import { FabComponent } from './fab/fab.component';
import { AggridComponent } from './aggrid/aggrid.component';
import { AgGridModule } from 'ag-grid-angular';
import { EditBusinessComponent } from '../feature/edit-business/edit-business.component';
import { EditCatalogueComponent } from '../feature/edit-catalogue/edit-catalogue.component';
import { CreateCategoryMasterComponent } from '../catalogue-builder/create-category-master/create-category-master.component';
import { ModalMasterComponent } from '../catalogue-builder/modal/modal-master.component';
import { CreateItemMasterComponent } from '../catalogue-builder/create-item-master/create-item-master.component';
import { CreateTagMasterComponent } from '../catalogue-builder/create-tag-master/create-tag-master.component';
import { CreateCustomMasterComponent } from '../catalogue-builder/create-custom-master/create-custom-master.component';
import { CreateCategoryStandaloneComponent } from '../catalogue-builder/create-category-standalone/create-category-standalone.component';
import { CreateItemStandaloneComponent } from '../catalogue-builder/create-item-standalone/create-item-standalone.component';
import { CreateTagStandaloneComponent } from '../catalogue-builder/create-tag-standalone/create-tag-standalone.component';
import { CreateCustomStandaloneComponent } from '../catalogue-builder/create-custom-standalone/create-custom-standalone.component';
import { BuilderPopoverComponent } from '../catalogue-builder/builder-popover/builder-popover.component';
import { ModalModule } from './modal/modal.module';
import { CreateAccountComponent } from '../feature/create-account/create-account.component';
import { InformationComponent } from '../feature/edit-business/business-information/business-information.component';
import { ApplicationThemeComponent } from '../feature/edit-business/application-theme/application-theme.component';
import { ServicePlatformComponent } from '../feature/edit-business/service-platform/service-platform.component';
import { BusinessHoursComponent } from '../feature/edit-business/service-platform/business-hours/business-hours.component';
import { SelectDayComponent } from '../feature/edit-business/service-platform/business-hours/select-day/select-day.component';
import { BusinessOnboardComponent } from '../feature/business-onboard/business-onboard.component';
import { MatStepperModule } from '@angular/material/stepper';
import { BasicDetailsComponent } from '../feature/business-onboard/basic-details/basic-details.component';
import { PreviewComponent } from '../feature/business-onboard/preview/preview.component';
import { AddressComponent } from '../feature/business-onboard/address/address.component';
import { TaxesComponent } from '../feature/business-onboard/taxes/taxes.component';
import { DigitalAddressComponent } from '../feature/business-onboard/digital-address/digital-address.component';
import { ControlMessagesComponent } from './validators/control-messages/control-messages.component';
import { CreateFeatureComponent } from '../feature/create-feature/create-feature.component';
import { UpdateFeatureComponent } from '../feature/update-feature/update-feature.component';
import { CreateVerticalComponent } from '../feature/create-vertical/create-vertical.component';


@NgModule({
  declarations: [
    GlobalHeaderComponent,
    ProfilePopoverComponent,
    ModalComponent,
    ModalMasterComponent,
    CreateMerchantComponent,
    CreateVerticalComponent,
    EditMerchantComponent,
    EditBusinessComponent,
    EditCatalogueComponent,
    CreateBusinessComponent,
    CreateCatalogueComponent,
    CreateCategoryComponent,
    CreateItemComponent,
    FabComponent,
    AggridComponent,
    CreateCategoryMasterComponent,
    CreateCategoryStandaloneComponent,
    CreateItemMasterComponent,
    CreateItemStandaloneComponent,
    CreateTagMasterComponent,
    CreateTagStandaloneComponent,
    CreateCustomMasterComponent,
    CreateCustomStandaloneComponent,
    BuilderPopoverComponent,
    CreateAccountComponent,
    InformationComponent,
    ApplicationThemeComponent,
    ServicePlatformComponent,
    BusinessHoursComponent,
    SelectDayComponent,
    BusinessOnboardComponent,
    BasicDetailsComponent,
    AddressComponent,
    DigitalAddressComponent,
    PreviewComponent,
    TaxesComponent,
    ControlMessagesComponent,
    PermalinkComponent,
    BusinessLoginComponent,
    CreateFeatureComponent,
    UpdateFeatureComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    AgGridModule.withComponents([]),
    MatStepperModule
  ],
  exports: [
    GlobalHeaderComponent,
    ProfilePopoverComponent,
    ModalComponent,
    ModalMasterComponent,
    IonicModule,
    RouterModule,
    CreateMerchantComponent,
    CreateVerticalComponent,
    EditMerchantComponent,
    EditBusinessComponent,
    EditCatalogueComponent,
    CreateBusinessComponent,
    CreateCatalogueComponent,
    CreateCategoryComponent,
    CreateItemComponent,
    FabComponent,
    AggridComponent,
    CreateCategoryMasterComponent,
    CreateCategoryStandaloneComponent,
    CreateItemMasterComponent,
    CreateItemStandaloneComponent,
    CreateTagMasterComponent,
    CreateTagStandaloneComponent,
    CreateCustomMasterComponent,
    CreateCustomStandaloneComponent,
    BuilderPopoverComponent,
    CreateAccountComponent,
    InformationComponent,
    ApplicationThemeComponent,
    ServicePlatformComponent,
    BusinessHoursComponent,
    SelectDayComponent,
    BusinessOnboardComponent,
    BusinessLoginComponent,
    CreateFeatureComponent,
    UpdateFeatureComponent
  ]
})
export class SharedModule { }
