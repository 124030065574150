import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserInput, UserBusinessDetailsInput } from './user.model';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { BusinessService } from 'src/app/shared/services/business.service';
import * as firebase from 'firebase';
import { AlertController, LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-edit-business',
  templateUrl: './edit-business.component.html',
  styleUrls: ['./edit-business.component.scss'],
})
export class EditBusinessComponent {
  @Input() allData: any;
  business: any;
  public businessResponse: BehaviorSubject<any>;
  userForm: FormGroup;
  passwordType: string = 'password';
  passwordIcon: string = 'eye-off';
  confirmPasswordIcon: string = 'eye-off';
  confirmPasswordType: string = 'password';
  userAccountCreated = false;
  loadingControllerInstance: any;
  //userInput: UserInput;
  // userBusiness: UserBusinessDetailsInput;
  constructor(private businessService: BusinessService,
    public alertController: AlertController,
    public loadingController: LoadingController) { }

  ngOnInit() {
    this.business = this.allData.data;
    this.userForm = new FormGroup({
      emailId: new FormControl('', [Validators.required, ValidationService.emailValidator]),
      password: new FormControl('', Validators.required),
      confirmPassword: new FormControl('', [Validators.required, this.confirmPasswordValidator]),
    });
  }

  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  hideShowConfirmPassword() {
    this.confirmPasswordType = this.confirmPasswordType === 'text' ? 'password' : 'text';
    this.confirmPasswordIcon = this.confirmPasswordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  onUserSubmit() {
    this.presentLoading();
    firebase.auth().createUserWithEmailAndPassword(this.userForm.get('emailId').value, this.userForm.get('password').value)
      .then((userCredential) => {
        // Signed in 
        var user = userCredential.user;
        user.updateProfile({ displayName: this.business.merchantId });
        console.log('user created', user);
        this.checkIfUserExists(user.uid);
      })
      .catch((error1) => {
        if (error1.code === 'auth/email-already-in-use') {
          firebase.auth().signInWithEmailAndPassword(this.userForm.get('emailId').value, this.userForm.get('password').value)
            //ToDo: check if wrong password
            .then(response => {
              let user = response.user;
              if (user.displayName === this.business.merchantId) {
                this.patchBusinessForUser(user.uid)
              }
              else {
                var errorMessage = 'This email is already being used by some other merchant';
                this.dismissLoadingAndShowAlert(errorMessage)
              }
            })
            .catch((error2) => {
              if (error2.code === 'auth/wrong-password') {
                var errorMessage = 'Invalid password for existing email account';
                this.dismissLoadingAndShowAlert(errorMessage)
              }
              else {
                this.dismissLoadingAndShowAlert(error2.message)
              }
            });
        }
        else {
          this.dismissLoadingAndShowAlert(error1.message)
        }
      });
  }

  dismissLoadingAndShowAlert(message: string){
    this.loadingControllerInstance.dismiss();
    this.showAlert('Error', message);
  }

  checkIfUserExists(uid: string) {
    this.businessService.checkUserExist(this.business.merchantId)
      .valueChanges.subscribe((responseData: any) => {
        let userExists = responseData.data.CheckUserExist;
        console.log('checkUserExist', responseData.data.CheckUserExist);
        if (userExists) {
          this.patchBusinessForUser(uid);
        }
        else {
          this.createUser(uid);
        }
      });
  }

  createUser(uid: string) {
    let userBusiness = new UserBusinessDetailsInput();
    userBusiness.id = this.business.id;
    userBusiness.businessName = this.business.name;
    userBusiness.uid = uid;

    let userInput = new UserInput();
    userInput.merchantId = this.business.merchantId;
    userInput.businesses = [];
    userInput.businesses.push(userBusiness);

    console.log(userInput);
    this.businessService.createUser(userInput)
      .subscribe((result: any) => {

        //ToDo check if user is created or else delete user from firestore
        // and notify user with proper message
        this.loadingControllerInstance.dismiss();
        console.log(result.data.CreateNewUser);
        this.userAccountCreated = true;
      })
  }

  patchBusinessForUser(uid: string) {
    let userBusiness = new UserBusinessDetailsInput();
    userBusiness.id = this.business.id;
    userBusiness.businessName = this.business.name;
    userBusiness.uid = uid;

    console.log(userBusiness);
    this.businessService.patchBusinessForUser(this.business.merchantId, userBusiness)
      .subscribe((result: any) => {

        //ToDo check if user is created or else delete user from firestore
        // and notify user with proper message
        this.loadingControllerInstance.dismiss();
        console.log(result.data.CreateNewUser);
        this.userAccountCreated = true;
      })
  }

  confirmPasswordValidator(control) {
    if (control.value === control._parent?.controls.password.value) {
      return null;
    }
    else {
      return { "invalidConfirmPassword": true };
    }
  }

  async showAlert(header: string, msg: string) {
    const alert = await this.alertController.create({
      header: header,
      message: msg,
      buttons: [
        {
          text: "Ok",
        },
      ],
    });
    await alert.present();
  }

  async presentLoading() {
    this.loadingControllerInstance = await this.loadingController.create({
      message: 'Please wait...'
    });
    await this.loadingControllerInstance.present();
  }
}
