<form id="businessForm" [formGroup]="businessForm">
    <mat-horizontal-stepper [linear]="!hasBusinessData" #stepper>
        <!-- Step 1 -->
        <mat-step formGroupName="basicDetails" [stepControl]="businessForm.get('basicDetails')">
            <ng-template matStepLabel>Basic Details*</ng-template>
            <app-basic-details [stepper]="stepper" [businessForm]="businessForm"></app-basic-details>
        </mat-step>

        <!-- Step 2 -->
        <mat-step formGroupName="address" [stepControl]="businessForm.get('address')">
            <ng-template matStepLabel>Address*</ng-template>
            <app-address [stepper]="stepper" [businessForm]="businessForm"></app-address>
        </mat-step>

        <!-- Step 3 -->
        <mat-step formArrayName="digitalAddresses" [stepControl]="businessForm.get('digitalAddresses')">
            <ng-template matStepLabel>Digital Address</ng-template>
            <app-digital-address [stepper]="stepper" [businessForm]="businessForm"></app-digital-address>
        </mat-step>

        <!-- Step 4 -->
        <div formGroupName="businessAttributes">
            <mat-step formArrayName="taxes" [stepControl]="businessForm.get('taxes')">
                <ng-template matStepLabel>Taxes</ng-template>
                <app-taxes [stepper]="stepper" [businessForm]="businessForm"></app-taxes>
            </mat-step>
        </div>

        <!-- Preview -->
        <mat-step>
            <ng-template matStepLabel>Preview</ng-template>
            <app-preview [merchantId]="merchantId" [allData]="allData" [businessForm]="businessForm" [stepper]="stepper"></app-preview>
        </mat-step>
    </mat-horizontal-stepper>
</form>