<ion-header>
  <ion-toolbar color="primary">
    <ion-title slot="start">SimPayX</ion-title>
    <ion-segment slot="end" value="" style="max-width: 550px">
      <ion-segment-button
        [routerLink]="['/admin-dashboard']"
        [routerLinkActive]="'segment-button-checked'"
        [routerLinkActiveOptions]="{ exact: true }"
        value="dashboard"
        >Dashboard</ion-segment-button
      >
      <ion-segment-button
        [routerLink]="['/admin-dashboard/operations']"
        [routerLinkActive]="'segment-button-checked'"
        [routerLinkActiveOptions]="{ exact: false }"
        value="operations"
        >Operations</ion-segment-button
      >
      <ion-segment-button
        [routerLink]="['/admin-dashboard/toggle-feature']"
        [routerLinkActive]="'segment-button-checked'"
        [routerLinkActiveOptions]="{ exact: false }"
        value="featureToggle"
        >Feature-Toggling</ion-segment-button
      >
      <ion-segment-button
      [routerLink]="['/admin-dashboard/manage-vertical']"
      [routerLinkActive]="'segment-button-checked'"
      [routerLinkActiveOptions]="{ exact: false }"
      value="manageVertical"
      >Manage Verticals
      </ion-segment-button
    >
    </ion-segment>
    <ion-buttons slot="end">
      <ion-button (click)="presentPopover()">
        <ion-icon slot="icon-only" name="person-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
