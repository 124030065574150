<ion-buttons *ngIf="this.router.url == '/admin-dashboard/operations'">
    <ion-button data-tooltip="Details" size="small" (click)="navigate('/merchant-details/' + this.params.data.id)">
        <ion-icon slot="icon-only" name="eye-outline"></ion-icon>
    </ion-button>
    <ion-button data-tooltip="Edit" size="small" (click)="presentEditMerchant(this.params)">
        <ion-icon slot="icon-only" name="create-outline"></ion-icon>
    </ion-button>
    <ion-button data-tooltip="Delete" size="small" (click)="presentDeleteMerchant(this.params.data.id)">
        <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
    </ion-button>
</ion-buttons>

<ion-buttons *ngIf="this.router.url == '/admin-dashboard/toggle-feature'">
    <ion-button data-tooltip="Edit" size="small" (click)="presentUpdateFeature(this.params)">
        <ion-icon slot="icon-only" name="create-outline"></ion-icon>
    </ion-button>
    <ion-button data-tooltip="Delete" size="small" (click)="presentDeleteFeature(this.params.data.name)">
        <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
    </ion-button>
</ion-buttons>


<ion-buttons *ngIf="
    this.router.url.indexOf('merchant-details') !== -1 &&
    this.router.url.indexOf('business-details') === -1
  ">

    <ion-button data-tooltip="Edit Business" size="small" (click)="presentEditBusiness(this.params)">
        <ion-icon slot="icon-only" name="create-outline"></ion-icon>
    </ion-button>


    <ion-button data-tooltip="Catalogues" size="small" (click)="navigate('/business-details/' + this.params.data.id)">
        <ion-icon slot="icon-only" name="book-outline"></ion-icon>
    </ion-button>
    <!-- <ion-button data-tooltip="Create Account" size="small" (click)="presentCreateAccount(this.params)">
      <ion-icon slot="icon-only" name="finger-print-outline"></ion-icon>
    </ion-button> -->
    <ion-button data-tooltip="Business Onboard" size="small" (click)="presentOnboardBusiness(this.params)">
        <ion-icon slot="icon-only" name="cog-outline"></ion-icon>
    </ion-button>
    <ion-button data-tooltip="Delete" size="small" (click)="presentDeleteBusiness(this.params)">
        <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
    </ion-button>
</ion-buttons>

<ion-buttons *ngIf="this.router.url.indexOf('business-details') !== -1">
    <ion-button data-tooltip="Details" size="small" (click)="navigate('/catalogue/' + this.params.data.id)">
        <ion-icon slot="icon-only" name="eye-outline"></ion-icon>
    </ion-button>
    <ion-button data-tooltip="Duplicate" size="small" (click)="presentDuplicateCatalogue()">
        <ion-icon slot="icon-only" name="duplicate-outline"></ion-icon>
    </ion-button>
    <ion-button data-tooltip="Set Dine-in" id="table-trigger" size="small" (click)="setLiveCatalogueId($event)">
        <ion-icon slot="icon-only" name="restaurant-outline"></ion-icon>
    </ion-button>
    <ion-button data-tooltip="Set Pickup" id="pickup-trigger" size="small" (click)="setLiveCatalogueId($event)">
        <ion-icon slot="icon-only" name="bag-handle-outline"></ion-icon>
    </ion-button>
    <ion-button data-tooltip="Set Takeout" id="takeout-trigger" size="small" (click)="setLiveCatalogueId($event)">
        <ion-icon slot="icon-only" name="footsteps-outline"></ion-icon>
    </ion-button>
    <ion-button data-tooltip="Set Reservation" id="reservation-trigger" size="small" (click)="setLiveCatalogueId($event)">
        <ion-icon slot="icon-only" name="time-outline"></ion-icon>
    </ion-button>
    <ion-button data-tooltip="Delete" size="small" (click)="presentDeleteCatalogue(this.params)">
        <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
    </ion-button>
</ion-buttons>