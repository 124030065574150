import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLinkModule, HttpLink} from 'apollo-angular-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import { environment } from 'src/environments/environment';

const uri = `${environment.catalogueApiUrl}`; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink) {
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all'
    }
  }

  return {
    link: httpLink.create({uri}),
    cache: new InMemoryCache({addTypename:false}),
    defaultOptions
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          link: httpLink.create({uri}),
          cache: new InMemoryCache({addTypename:false}),
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
