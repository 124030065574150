import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormArray, Validators, FormBuilder } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { ValidationService } from "src/app/shared/services/validation.service";

@Component({
  selector: "app-taxes",
  templateUrl: "./taxes.component.html",
  styleUrls: ["./taxes.component.scss"],
})
export class TaxesComponent implements OnInit {
  @Input() businessForm: FormGroup;
  @Input() stepper: MatStepper;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    console.log(this.businessForm.value);
  }

  onTaxesNextClicked(stepper: MatStepper) {
    this.businessForm.getRawValue();
    stepper.next();
  }

  onPreviousClicked(stepper: MatStepper) {
    stepper.previous();
  }

  setTaxes() {
    return this.fb.group({
      name: ["", Validators.required],
      rate: ["", [Validators.required, ValidationService.taxValidator]],
    });
  }

  addTaxes() {
    const control = this.businessForm.controls.businessAttributes["controls"]
      .taxes as FormArray;
    console.log(control);
    if (this.businessForm.controls.businessAttributes.valid) {
      control.push(this.setTaxes());
    } else {
      console.log("invalid");
    }
  }

  removeTaxes(i: number) {
    const control = this.businessForm.controls.businessAttributes["controls"]
      .taxes as FormArray;
    control.removeAt(i);
  }
}
