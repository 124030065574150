<section class="form-modal-content">
  <form [formGroup]="itemForm" (ngSubmit)="onItemSubmit()">
      <div>
        <fieldset>
          <legend>
            <h4>Item</h4>
          </legend>
          <div>
            <ion-list lines="full" class="ion-no-margin ion-no-padding">
              <ion-item>
                <ion-label position="floating"> Item Name <ion-text color="danger">*</ion-text>
                </ion-label>
                <ion-input required type="text" formControlName="itemName"></ion-input>
              </ion-item>
            </ion-list>
          </div>
        </fieldset>
      </div>
    <br>
    <ion-button expand="block" type="submit" [disabled]="!itemForm.valid">Create item</ion-button>
  </form>
</section>