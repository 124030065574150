import { Apollo } from "apollo-angular";
import {
  BusinessInput,
} from "./../../feature/business-details/business.model";
import { Injectable } from "@angular/core";
import gql from "graphql-tag";

import { BehaviorSubject, EMPTY } from "rxjs";

export const CREATE_VERTICAL = gql`
  mutation CreateVertical($vertical: VerticalInput) {
    CreateVertical(vertical: $vertical) {
        id
        type
        subType
        isEnabled
    }
  }
`;


export const GET_VERTICALS = gql`
  query GetVerticals {
    GetVerticals {
        id
        type
        subType
        isEnabled
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class VerticalService {
  private eventName : BehaviorSubject<string>
  private verticals: BehaviorSubject<String>
  constructor(public apollo: Apollo) {
    this.eventName = new BehaviorSubject<string>("");
    this.verticals = new BehaviorSubject<string>("");
  }

  createVertical(vertical: any) {
    console.log("inside create business");
    console.log(vertical);
    return this.apollo.mutate({
      mutation: CREATE_VERTICAL,
      variables: {
        vertical: vertical
      },
    });
  }


  getVerticals() {
    return this.apollo.query({
      query: GET_VERTICALS,
    }).subscribe(
      (res: any)=>{
        if(res){
          this.verticals.next(res.data.GetVerticals)
        }
      }
    );
  }

  getVerticalsSubscription(){
    if(!this.verticals.value){
      console.log("get verticals from backend")
      this.getVerticals()
    }
    return this.verticals.asObservable()
  }
}
