import { BusinessInput } from "src/app/feature/business-details/business.model";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { BusinessService } from "src/app/shared/services/business.service";
import { AlertController, LoadingController } from "@ionic/angular";
import { BehaviorSubject, Subscription } from "rxjs";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ValidationService } from "src/app/shared/services/validation.service";
import firebase from "firebase";
import { UserBusinessDetailsInput, UserInput } from "../user.model";

@Component({
  selector: "simpayx-business-login",
  templateUrl: "./business-login.component.html",
  styleUrls: ["./business-login.component.scss"],
})
export class BusinessLoginComponent implements OnInit, OnDestroy {
  @Input() business: BusinessInput;
  public businessResponse: BehaviorSubject<any>;
  userForm: FormGroup;
  passwordType: string = "password";
  passwordIcon: string = "eye-off";
  confirmPasswordIcon: string = "eye-off";
  confirmPasswordType: string = "password";
  userAccountCreated = false;
  loadingControllerInstance: any;
  userBusinessDetails: UserBusinessDetailsInput;
  businessUsers: UserBusinessDetailsInput[];
  existingSelectedUser: UserBusinessDetailsInput;
  addEmailFlag: boolean = false;
  getUsersByMerchantIdQuerySubscription: Subscription;
  checkUserExistQuerySubscription: Subscription;
  createUserQuerySubscription: Subscription;
  patchBusinessForUserQuerySubscription: Subscription;

  customActionSheetOptions: any = {
    header: "Colors",
    subHeader: "Select your favorite color",
  };

  constructor(
    private businessService: BusinessService,
    public alertController: AlertController,
    public loadingController: LoadingController
  ) {}

  ngOnInit() {
    this.userForm = new FormGroup({
      emailId: new FormControl("", [
        Validators.required,
        ValidationService.emailValidator,
      ]),
      password: new FormControl("", Validators.required),
      confirmPassword: new FormControl("", [
        Validators.required,
        this.confirmPasswordValidator,
      ]),
    });
    this.getUsersByMerchantId();
  }

  getUsersByMerchantId() {
    this.getUsersByMerchantIdQuerySubscription = this.businessService
      .getUsersByMerchantId(this.business.merchantId)
      .valueChanges.subscribe(
        (result) => {
          if (result) {
            let businessUsers = result.data["GetUserByMerchantId"].businesses;
            let userBusinessDetails: UserBusinessDetailsInput[] = businessUsers.filter(
              (userBusiness) => userBusiness.id === this.business.id
            );
            let userFlag = {};
            this.businessUsers = businessUsers.filter((user) => {
              if (userFlag[user.email] || !user.isActive) {
                return false;
              }
              userFlag[user.email] = true;
              return true;
            });
            this.userBusinessDetails = userBusinessDetails[0];
          }
        },
        (err) => console.log(err)
      );
  }

  onEmailChanged(event) {
    if (event.target.value && typeof event.target.value !== "string") {
      this.existingSelectedUser = event.target.value;
      this.userForm.patchValue({ emailId: event.target.value.email });
      this.addEmailFlag = false;
    } else if (event.target.value && typeof event.target.value === "string") {
      this.addEmailFlag = true;
      this.existingSelectedUser = undefined;
      this.userForm.patchValue({ emailId: "" });
    }
  }

  hideShowPassword() {
    this.passwordType = this.passwordType === "text" ? "password" : "text";
    this.passwordIcon = this.passwordIcon === "eye-off" ? "eye" : "eye-off";
  }

  hideShowConfirmPassword() {
    this.confirmPasswordType =
      this.confirmPasswordType === "text" ? "password" : "text";
    this.confirmPasswordIcon =
      this.confirmPasswordIcon === "eye-off" ? "eye" : "eye-off";
  }

  onUserSubmit() {
    this.presentLoading();
    firebase
      .auth()
      .createUserWithEmailAndPassword(
        this.userForm.get("emailId").value,
        this.userForm.get("password").value
      )
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        user.updateProfile({ displayName: this.business.merchantId });
        console.log("user created", user);
        this.checkIfUserExists(user.uid);
      })
      .catch((error1) => {
        if (error1.code === "auth/email-already-in-use") {
          firebase
            .auth()
            .signInWithEmailAndPassword(
              this.userForm.get("emailId").value,
              this.userForm.get("password").value
            )
            //ToDo: check if wrong password
            .then((response) => {
              let user = response.user;
              if (user.displayName === this.business.merchantId) {
                this.patchBusinessForUser(user.uid);
              } else {
                var errorMessage =
                  "This email is already being used by some other merchant";
                this.dismissLoadingAndShowAlert(errorMessage);
              }
            })
            .catch((error2) => {
              if (error2.code === "auth/wrong-password") {
                var errorMessage =
                  "Invalid password for existing email account";
                this.dismissLoadingAndShowAlert(errorMessage);
              } else {
                this.dismissLoadingAndShowAlert(error2.message);
              }
            });
        } else {
          this.dismissLoadingAndShowAlert(error1.message);
        }
      });
  }

  dismissLoadingAndShowAlert(message: string) {
    this.loadingControllerInstance.dismiss();
    this.showAlert("Error", message);
  }

  checkIfUserExists(uid: string) {
    this.checkUserExistQuerySubscription = this.businessService
      .checkUserExist(this.business.merchantId)
      .valueChanges.subscribe((responseData: any) => {
        let userExists = responseData.data.CheckUserExist;
        console.log("checkUserExist", responseData.data.CheckUserExist);
        if (userExists) {
          this.patchBusinessForUser(uid);
        } else {
          this.createUser(uid);
        }
      });
  }

  createUser(uid: string) {
    let userBusiness = new UserBusinessDetailsInput();
    userBusiness.id = this.business.id;
    userBusiness.businessName = this.business.name;
    userBusiness.uid = uid;
    userBusiness.email = this.userForm.value.emailId;

    let userInput = new UserInput();
    userInput.merchantId = this.business.merchantId;
    userInput.businesses = [];
    userInput.businesses.push(userBusiness);

    console.log(userInput);
    this.createUserQuerySubscription = this.businessService
      .createUser(userInput)
      .subscribe((result: any) => {
        //ToDo check if user is created or else delete user from firestore
        // and notify user with proper message
        this.loadingControllerInstance.dismiss();
        console.log(result.data.CreateNewUser);
        this.userAccountCreated = true;
        this.getUsersByMerchantId();
      });
  }

  patchBusinessForUser(uid: string) {
    if (this.loadingControllerInstance === undefined) {
      this.presentLoading();
    }
    let userBusiness = new UserBusinessDetailsInput();
    userBusiness.id = this.business.id;
    userBusiness.businessName = this.business.name;
    userBusiness.uid = uid;
    userBusiness.email = this.userForm.value.emailId;

    this.patchBusinessForUserQuerySubscription = this.businessService
      .patchBusinessForUser(this.business.merchantId, userBusiness)
      .subscribe((result: any) => {
        //ToDo check if user is created or else delete user from firestore
        // and notify user with proper message
        console.log(result.data.CreateNewUser);
        this.userAccountCreated = true;
        this.loadingControllerInstance.dismiss();
        this.getUsersByMerchantId();
      });
  }

  confirmPasswordValidator(control) {
    if (control.value === control._parent?.controls.password.value) {
      return null;
    } else {
      return { invalidConfirmPassword: true };
    }
  }

  async showAlert(header: string, msg: string) {
    const alert = await this.alertController.create({
      header: header,
      message: msg,
      buttons: [
        {
          text: "Ok",
        },
      ],
    });
    await alert.present();
  }

  async presentLoading() {
    this.loadingControllerInstance = await this.loadingController.create({
      message: "Please wait...",
    });
    await this.loadingControllerInstance.present();
  }

  ngOnDestroy() {
    if (this.patchBusinessForUserQuerySubscription) {
      this.patchBusinessForUserQuerySubscription.unsubscribe();
    } else if (this.createUserQuerySubscription) {
      this.createUserQuerySubscription.unsubscribe();
    } else if (this.getUsersByMerchantIdQuerySubscription) {
      this.getUsersByMerchantIdQuerySubscription.unsubscribe();
    } else if (this.checkUserExistQuerySubscription) {
      this.checkUserExistQuerySubscription.unsubscribe();
    }
  }
}
