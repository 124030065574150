import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';

import { AgGridAngular } from 'ag-grid-angular';
import { DataService } from '../services/data.service';
import { ModalController } from '@ionic/angular';
import { ActionCellsComponent } from 'src/app/fragment/action-cells/action-cells.component';
import { Router } from '@angular/router';

import { CatalogueService } from '../services/catalogue.service';
import { Subscription } from 'rxjs';
import { Catalogue } from './../../feature/catalogue/catalogue.model';
import { MerchantBusinessService } from '../services/merchant-business.service';
import { Merchant } from 'src/app/feature/merchant-details/merchant.model';
import { EventEmitterService } from '../services/event-emitter.service';

@Component({
  selector: 'app-aggrid',
  templateUrl: './aggrid.component.html',
  styleUrls: ['./aggrid.component.scss'],
})
export class AggridComponent implements OnInit, OnDestroy {
  @ViewChild('agGrid') agGrid: AgGridAngular;
  title = 'app';

  
  @Input() rowData: any[];
  @Input() columnDefs: any[];
  @Input() defaultColDef: any;
  // @Input() gridOptions: any;

  catalogues: Catalogue[];

  private gridApi;
  gridColumnApi;

  // columnDefs;
  // defaultColDef;
  // rowData;
  catalogueQuerySubscription: Subscription;
  businessQuerySubscription: Subscription;
  businessIdQuerySubscription: Subscription;
  private querySubscription: Subscription;
  gridDataSubscription: Subscription;
  allmerchant: Merchant[];

  // Incoming Merchant Data
  businesses: any;
  businessOwner: any;

  constructor(
    public merchData: DataService,
    public modalController: ModalController,
    private router: Router,

    private eventEmitterService: EventEmitterService,
    private catalogueService: CatalogueService,
    private merchantBusinessService: MerchantBusinessService
  ) {

      const params = {
      force: true,
      suppressFlash: true,
    };
  }

  ngOnInit() {
    
    console.log('gridDataSubscription ' + this.eventEmitterService.gridDataSubscription);
    this.gridDataSubscription = this.eventEmitterService.
      invokeGridUpdate.subscribe((newData) => {
        console.log('INVOKE');
        this.updateGrid(newData);
      });
    

    // this.getAllMerchants();
    // this.rowData = this.message;
    // console.log(this.message);


    // Display Grid columns based on active page
    
  }

  // getAllCataloguesForBusiness() {
  //   this.catalogueQuerySubscription = this.catalogueService
  //     .getAllCataloguesForBusiness()
  //     .valueChanges.subscribe((catalogues: any) => {
  //       this.message = catalogues.data.GetAllCatalogues;
  //       console.log('GetAllCatalogues', this.message);
  //     });
  // }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    console.log(params);

  }

  // TODO: This should update the grid with new subscription
  updateGrid(newData) {
    console.log('update');
    if (!this.gridApi) {
      console.log('init data');
      console.log(newData);
      this.rowData = newData;
    } else {
      console.log('update data');
      console.log(newData);
      this.gridApi.setRowData(newData);
    }
    console.log('finished');
  }

  onFirstDataRendered(params): void {
    params.api.sizeColumnsToFit();
  }
  ngOnDestroy() {
    if (this.gridDataSubscription) {
      console.log('grid destroyed');
      this.gridDataSubscription.unsubscribe();
    }

    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }

    if (this.catalogueQuerySubscription) {
      this.catalogueQuerySubscription.unsubscribe();
    }

    if (this.businessQuerySubscription) {
      this.businessQuerySubscription.unsubscribe();
    }

    if (this.businessIdQuerySubscription) {
      this.businessIdQuerySubscription.unsubscribe();
    }
  }
}
