<div [formGroup]="businessForm">
    <div formGroupName="basicDetails">

        <ion-item>
            <ion-label position="floating"> Business Name
                <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-input [disabled]="isDisabled" required type="text" formControlName="name"></ion-input>
        </ion-item>
        <app-control-messages [control]="businessForm.get('basicDetails').get('name')"></app-control-messages>

        <ion-item>
            <ion-label position="floating"> Vertical
                <ion-text color="danger">*</ion-text>
            </ion-label>
            <ion-select
                [disabled]="isDisabled" 
                mode="ios"
                formControlName="vertical"
                interface="alert"
              >
                <ion-select-option
                  *ngFor="let vertical of verticals; index as i"
                  [value]="vertical.name"
                  >{{ vertical.name }}</ion-select-option
                >
              </ion-select>
        </ion-item>
        <app-control-messages [control]="businessForm.get('basicDetails').get('vertical')"></app-control-messages>

        <ion-item>
            <ion-label>Enabled</ion-label>
            <ion-toggle checked formControlName="isEnabled"></ion-toggle>
        </ion-item>
    </div>
</div>
<div class="ion-text-center ion-padding ion-margin">
    <ion-button matStepperNext (click)="onBasicDetailsNextClicked(stepper)">
        Next
        <ion-icon slot="end" name="arrow-forward-outline"></ion-icon>
    </ion-button>
</div>