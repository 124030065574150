import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";

import { AlertController, ToastController } from "@ionic/angular";
import { Subscription, throwError } from "rxjs";
import { catchError } from "rxjs/internal/operators/catchError";

import { BusinessService } from "src/app/shared/services/business.service";
import { ValidationService } from "src/app/shared/services/validation.service";
import {
  BusinessInput,
  PlatformType,
} from "src/app/feature/business-details/business.model";
import {
  BusinessMetaData,
  PermalinkMetaDataInput,
} from "./../../business-details/business.model";

@Component({
  selector: "simpayx-permalink",
  templateUrl: "./permalink.component.html",
  styleUrls: ["./permalink.component.scss"],
})
export class PermalinkComponent implements OnInit, OnDestroy {
  @Input() business: BusinessInput;

  simpayxLink: string = "https://restaurants.simpayx.com";
  platformType = PlatformType;
  linkArray: PermalinkMetaDataInput[] = [];
  linkName = new FormControl("", [
    Validators.required,
    ValidationService.permalinkValidator,
  ]);
  defaultLinkName = new FormControl("", [
    Validators.required,
    ValidationService.permalinkValidator,
  ]);
  createQuerySubscription: Subscription;
  getQuerySubscription: Subscription;
  removeQuerySubscription: Subscription;
  copiedLink: string;
  defaultLink: boolean = false;
  editableLinkArraylength: number = 0;
  errorMessage: string;

  constructor(
    private businessService: BusinessService,
    public alertController: AlertController,
    public toastController: ToastController
  ) {}

  ngOnInit() {
    this.getAllPermaLinks();
  }

  getAllPermaLinks() {
    this.getQuerySubscription = this.businessService
      .getAllPermalinksForBusiness(this.business.id)
      .valueChanges.subscribe((result) => {
        this.linkArray = result.data["GetAllMetadataByBusinessId"];
        console.log("PERMA", this.linkArray);
        this.checkIfDefaultLinkPresent();
      });
  }

  checkIfDefaultLinkPresent() {
    this.linkArray.forEach((link) => {
      if (link.isDefault) {
        this.defaultLink = true;
        this.editableLinkArraylength = this.linkArray.length - 1;
        return;
      }
    });
  }

  removeLink(permalinkId: string) {
    this.removeQuerySubscription = this.businessService
      .deletePermaLinkForBusiness(permalinkId)
      .subscribe((result) => {
        this.getAllPermaLinks();
      });
  }

  addLink(isDefault: boolean) {
    if (isDefault) {
      let permalinkMetadata = new PermalinkMetaDataInput();
      let businessDetails = new BusinessMetaData();
      permalinkMetadata.shortName = this.defaultLinkName.value;
      businessDetails.businessId = this.business.id;
      businessDetails.merchantId = this.business.merchantId;
      permalinkMetadata.businessDetails = businessDetails;
      permalinkMetadata.isDefault = true;
      this.createQuerySubscription = this.businessService
        .createPermalinkForBusiness(permalinkMetadata)
        .subscribe((result) => {
          this.getAllPermaLinks();
        });
      this.defaultLinkName.reset();
    } else {
      let permalinkMetadata = new PermalinkMetaDataInput();
      let businessDetails = new BusinessMetaData();
      permalinkMetadata.shortName = this.linkName.value;
      businessDetails.businessId = this.business.id;
      businessDetails.merchantId = this.business.merchantId;
      permalinkMetadata.businessDetails = businessDetails;
      permalinkMetadata.isDefault = false;
      this.createQuerySubscription = this.businessService
        .createPermalinkForBusiness(permalinkMetadata)
        .pipe(
          catchError((err) => {
            this.errorMessage =
              err.graphQLErrors[0].extensions.response.body.message;
            this.presentDuplicationAlert();
            return throwError(err);
          })
        )
        .subscribe((result) => {
          console.log(result);
          this.getAllPermaLinks();
        });
      this.linkName.reset();
    }
  }

  copyToClipboard(link: string) {
    this.copiedLink = link;
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.presentToast();
  }

  async presentDeleteAlert(permalinkId: string) {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Confirm!",
      message: "Are you sure?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {},
        },
        {
          text: "Delete",
          handler: () => {
            this.removeLink(permalinkId);
          },
        },
      ],
    });

    await alert.present();
  }

  async presentDuplicationAlert() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "Alert!",
      message: this.errorMessage,
      buttons: [
        {
          text: "Ok",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {},
        },
      ],
    });

    await alert.present();
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: "Link copied to clipboard!",
      position: "top",
      duration: 2000,
    });
    toast.present();
  }

  ngOnDestroy() {
    if (this.getQuerySubscription) {
      this.getQuerySubscription.unsubscribe();
    }
    if (this.createQuerySubscription) {
      this.createQuerySubscription.unsubscribe();
    }
    if (this.removeQuerySubscription) {
      this.removeQuerySubscription.unsubscribe();
    }
  }
}
