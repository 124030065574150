import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { FeatureToggleModel } from 'src/app/toggle-feature/feature-toggle.model';

export const GET_ALL_FEATURES = gql`
  query allfeatures{
    GetAllFeatures{
      name
      isEnabled
      activeFor{
        id
        name
        address{
          addressLine1
          addressLine2
        }
      }
    }
  }
`;

export const CREATE_FEATURE = gql`
  mutation CreateFeature($featureContext: FeatureContextInput) {
    CreateFeature(featureContext: $featureContext){
      name
    }
  }
`;

export const UPDATE_FEATURE = gql`
  mutation UpdateFeature($name:String,$featureContext: FeatureContextInput) {
    UpdateFeature(name:$name,featureContext: $featureContext){
      name
    }
  }
`;
export const TOGGLE_FEATURE = gql`
  mutation ToggleFeature($name:String,$value: Boolean) {
    ToggleFeature(name:$name,value: $value){
      name
    }
  }
`;

export const DELETE_FEATURE = gql`
  mutation DeleteFeature($name:String) {
    DeleteFeature(name: $name)
  }
`;
@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {

  constructor(private apollo: Apollo) { }

  getAllFeatures() {
    return this.apollo.watchQuery({
      query: GET_ALL_FEATURES,
      fetchPolicy: "cache-and-network",
    })
  }

  createFeature(feature: FeatureToggleModel) {
    return this.apollo.mutate({
      mutation: CREATE_FEATURE,
      refetchQueries: ['allfeatures'],
      variables: {
        featureContext: feature,

      }
    })
  }
  updateFeature(name:String,feature: FeatureToggleModel) {
    return this.apollo.mutate({
      mutation: UPDATE_FEATURE,
      refetchQueries: ['allfeatures'],
      variables: {
        name:name,
        featureContext: feature,

      }
    })
  }
  deleteFeature(name: String) {
    return this.apollo.mutate({
      mutation: DELETE_FEATURE,
      refetchQueries: ['allfeatures'],
      variables: {
        name: name
      }
    })
  }

  toggleFeature(name:String,value: boolean) {
    return this.apollo.mutate({
      mutation: TOGGLE_FEATURE,
      variables: {
        name:name,
        value: value
      }
    })
  }
}

