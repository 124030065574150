<ion-card>
    <ion-card-header class="d-flex ion-align-items-center">
        <ion-icon name="color-palette-outline" color="primary" size="large"></ion-icon>
        <ion-label class="ion-padding-start spx-bold spx-font-color-black spx-font-16">Application Theme</ion-label>
    </ion-card-header>
    <ion-card-content>
        <ion-grid>
            <ion-row class="d-flex ion-justify-content-between ion-align-self-auto">
                <ion-col size-xs="6" size-sm="3" size-md="2" size-lg="2" *ngFor="let theme of themes" class=" ion-no-padding" [class]="theme.value">
                    <div [class.selected-border]="business?.businessAttributes.theme === theme?.value">
                        <ion-card (click)="onThemeChange(theme.value)" color="primary" button="true" class="align-item-center">
                            <ion-card-content class="ion-text-center ion-no-padding">
                                <ion-label>
                                    <b>{{theme.name}}</b>
                                </ion-label>
                            </ion-card-content>
                        </ion-card>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-content>
</ion-card>