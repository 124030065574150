import { Component, OnInit, OnDestroy } from '@angular/core';
import { MerchantBusinessService } from 'src/app/shared/services/merchant-business.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-is-enabled-business',
  templateUrl: './is-enabled-business.component.html',
  styleUrls: ['./is-enabled-business.component.scss'],
})
export class IsEnabledBusinessComponent implements OnInit, OnDestroy {
  isEnabled: boolean;
  business: any;
  businessById: any;
  params: any;
  querySubscription: Subscription;
  businessIdQuerySubscription: Subscription;
  constructor(
    private merchantBusinessService: MerchantBusinessService
  ) { }

  ngOnDestroy(): void {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
    if (this.businessIdQuerySubscription){
      this.businessIdQuerySubscription.unsubscribe();
    }
  }

  agInit(params) {
    this.params = params;
    this.isEnabled = this.params.data.isEnabled;
    this.params.data.merchantId = this.params.merchantId;
    console.log('this.params', this.params);
  }

  ngOnInit() {

  }

  getBusinessById(merchantId, businessId){
    this.businessIdQuerySubscription = this.merchantBusinessService
      .getBusinessById(merchantId, businessId)
      .valueChanges.subscribe((businessByIdData: any) => {
        if (businessByIdData.data !== undefined){
          this.businessById = businessByIdData.data.GetBusinessById;
          this.businessById.isEnabled = this.isEnabled;
          console.log('business', this.businessById);
        }
      });
  }

  toggleBusinessEnabled(){
    this.isEnabled = !this.isEnabled;
    // this.getBusinessById(this.params.merchantId, this.params.data.id);
    this.params.data.isEnabled = this.isEnabled;
    this.business = {
      id: this.params.data.id,
      name: this.params.data.name,
      merchantId: this.params.merchantId,
      vertical: 'test',
      isEnabled: this.isEnabled,
    };
    
    this.querySubscription = this.merchantBusinessService
    .editBusiness(this.params.merchantId, this.params.data.id, this.params.data).subscribe((response: any) => {
      console.log(response);
    });
  }
}


