<!--FAB-->
<ion-fab class="fab-bottom-right" vertical="bottom" horizontal="end" slot="fixed">
  <ion-fab-button>Create</ion-fab-button>
  <ion-fab-list side="start">
    <ion-fab-button id="merchantModal" (click)="openModal($event)" data-tooltip="New Merchant">
      <ion-icon name="person-add-outline"></ion-icon>
    </ion-fab-button>
    <ion-fab-button *ngIf="this.router.url.indexOf('merchant-details') !== -1" id="businessModal" (click)="openModal($event)" data-tooltip="New Business">
      <ion-icon name="business-outline"></ion-icon>
    </ion-fab-button>
    <ion-fab-button *ngIf="this.router.url.indexOf('business-details') !== -1" id="catalogueModal" (click)="openModal($event)" data-tooltip="New Catalogue">
      <ion-icon name="file-tray-stacked-outline"></ion-icon>
    </ion-fab-button>
    <!-- <ion-fab-button id="categoryModal" (click)="openModal($event)" data-tooltip="New Category">
      <ion-icon name="file-tray-full-outline"></ion-icon>
    </ion-fab-button>
    <ion-fab-button id="itemModal" (click)="openModal($event)" data-tooltip="New Item">
      <ion-icon name="pricetag-outline"></ion-icon>
    </ion-fab-button> -->
  </ion-fab-list>
</ion-fab>