<section class="form-modal-content">
<form [formGroup]="editMerchantForm" (ngSubmit)="onEditMerchantSubmit()">

  <fieldset>
      <legend>
          <h3>EDIT MERCHANT PROFILE: </h3>
      </legend>
      <div>
          <ion-list lines="full" class="ion-no-margin ion-no-padding">
            <ion-item hidden class="faded">
                <ion-label position="floating">Merchant ID <ion-text color="danger">*</ion-text>
                </ion-label>
                <ion-input required type="text" formControlName="id"></ion-input>
            </ion-item>
              <ion-item hidden>
                  <ion-label position="floating"> Name <ion-text color="danger">*</ion-text>
                  </ion-label>
                  <ion-input required type="text" formControlName="name"></ion-input>
              </ion-item>
              <ion-item>
                <ion-label>Enabled</ion-label>
                <ion-toggle checked formControlName="isEnabled"></ion-toggle>
            </ion-item>
            <!-- <ion-item>
              <ion-label position="floating"> Digital Address <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-input required type="text" formControlName="name"></ion-input>
          </ion-item> -->
            </ion-list>
      </div>
  </fieldset>
<ion-button expand="block" type="submit" [disabled]="!editMerchantForm.valid">Confirm Edit</ion-button>
</form>
</section>