import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { TagInput } from 'src/app/feature/catalogue/categories/category/menu-items/item/tag/tagInput.model';


@Component({
  selector: 'app-create-tag-standalone',
  templateUrl: './create-tag-standalone.component.html',
  styleUrls: ['./create-tag-standalone.component.scss'],
})
export class CreateTagStandaloneComponent implements OnInit {

  @Input() businessIdMaster;
  createTagQuerySubscription: Subscription;
  tag: TagInput;
  randomizedId = '';

  tagStandaloneForm = new FormGroup({
    id: new FormControl('', [Validators.required, Validators.minLength(10)]),
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    abbreviation: new FormControl(''),
    businessId: new FormControl('', [Validators.required, Validators.minLength(10)]),
    description: new FormControl('')
  });
  constructor(
    private modalController: ModalController) { }

    ngOnInit() {
      this.randomizedId = this.makeid(24);
      this.tagStandaloneForm.get('id').setValue(this.randomizedId);
      this.tagStandaloneForm.get('businessId').setValue(this.businessIdMaster);
    }

    onCreateTagStandaloneSubmit() {

      // this.tagStandaloneForm.value.imageUrl = '';
      this.modalController.dismiss(this.tagStandaloneForm.value);
    }
    makeid(length) {
      let result = '';
      const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
   }
}
