<section class="form-modal-content">
  <form [formGroup]="accountForm" (ngSubmit)="onAccountSubmit()">
    <div>
        <div>
          <ion-list lines="full" class="ion-no-margin ion-no-padding">
            <ion-item hidden>
              <ion-label position="floating"> Business ID
                <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-input required type="text" formControlName="businessId"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="floating"> Account Type
                <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-input readonly required type="text" formControlName="accountType"></ion-input>
            </ion-item>
            <ion-item hidden>
              <ion-label position="floating"> ID
                <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-input required type="text" formControlName="id"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label position="floating"> Account ID
                <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-input required type="text" formControlName="accountId"></ion-input>
            </ion-item>
            <app-control-messages [control]="accountForm.get('accountId')"></app-control-messages>
            <ion-item>
              <ion-label position="floating"> Confirm Account ID
                <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-input required type="password" formControlName="confirm_accountId"></ion-input>
            </ion-item>

            <div *ngIf="f.confirm_accountId.touched && f.confirm_accountId.invalid" class="alert alert-danger">
              <ion-label *ngIf="f.confirm_accountId.errors.required" class="ion-padding" color="danger">
                This field is required.
              </ion-label>
              <ion-label *ngIf="f.confirm_accountId.errors.confirmedValidator" class="ion-padding" color="danger">Account ID and Confirm Account ID must be match.</ion-label>
            </div>
            
            <ion-item>
              <ion-label position="floating"> SimPayX Fee
                <ion-text color="danger">*</ion-text>
              </ion-label>
              <ion-grid class="ion-no-padding ion-no-margin">
                <ion-row>
                  <ion-col size="2">
                    <ion-input required formControlName="simPayXFee" type="number"></ion-input>
                  </ion-col>
                  <ion-col>
                    <ion-input readonly value="%"></ion-input>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>
            <app-control-messages [control]="accountForm.get('simPayXFee')"></app-control-messages>
            
          </ion-list>
        </div>
    </div>
    <br>
    <ion-button expand="block" type="submit" [disabled]="!accountForm.valid">Onboard business</ion-button>
  </form>
</section>