import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CustomizationInput } from 'src/app/feature/catalogue/categories/category/menu-items/item/customization/optionsInput.model';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-custom-master',
  templateUrl: './create-custom-master.component.html',
  styleUrls: ['./create-custom-master.component.scss'],
})
export class CreateCustomMasterComponent implements OnInit, OnDestroy {
  customization: CustomizationInput;
  createCustomizationQuerySubscription: Subscription;
  choiceLimitSubscription: Subscription;

  @Input() businessIdMaster;
  isDefault = false;
  isOptional = false;
  isDefaultNum = 0;
  choiceLimitNum = 1;
  choiceLimitCarrier: number;

  data = [{
    businessId: '',
    name: '',
    isEnabled: true,
    options: [
      {
        name: '',
        price: '',
        isDefault: false
      }
    ],
    description: '',
    choiceLimit: 10,
    choiceLowerLimit: 0,
    choiceUpperLimit: 10,
    isOptional: false
  }];
  customMasterForm = new FormGroup({});
  
  constructor(private fb: FormBuilder,
              private modalController: ModalController,
              private catalogueService: CatalogueService) {
                this.customMasterForm = this.fb.group({
                  customization: this.fb.array([]),
                });
     }

  ngOnDestroy(): void {
    if (this.createCustomizationQuerySubscription) {
      this.createCustomizationQuerySubscription.unsubscribe();
    }
    if (this.choiceLimitSubscription) {
      this.choiceLimitSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.setCustomization();
    // tslint:disable-next-line: no-string-literal
    this.choiceLimitSubscription = this.customMasterForm['controls'].customization['controls'][0].controls.choiceUpperLimit.valueChanges.subscribe(val => {

      val = Math.round(val);
      this.choiceLimitCarrier = val;

      if (val > this.choiceLimitNum) {
        // tslint:disable-next-line: max-line-length
        this.customMasterForm['controls'].customization['controls'][0].controls.choiceUpperLimit._parent.get('choiceUpperLimit').setValue(this.choiceLimitNum);
      }
    });
  }

  setCustomization() {
    const control = this.customMasterForm.controls.customization as FormArray;
    this.data.forEach(x => {
      control.push(this.fb.group({
        businessId: new FormControl(this.businessIdMaster, [ Validators.required, Validators.minLength(10)]),
        name: new FormControl(x.name, [ Validators.required, Validators.minLength(4)]),
        description: new FormControl(x.description),
        isOptional: new FormControl(x.isOptional),
        isEnabled: new FormControl(x.isEnabled),
        options: this.setOptions(x),
        choiceUpperLimit: new FormControl(this.isDefaultNum, [
          Validators.pattern("[0-9]"),
          Validators.min(this.isDefaultNum),
          Validators.max(this.choiceLimitNum)
        ]),
        choiceLowerLimit: new FormControl(this.isDefaultNum, [
          Validators.pattern("[0-9]"),
          Validators.min(this.isDefaultNum),
          Validators.max(this.choiceLimitNum)
        ])
        }));
    });
  }

  // Item Methods
  addNewOption(control) {
    // this.choiceLimitNum += 1;
    control.push(
      this.fb.group({
        name: [''],
        price: [],
        isDefault: [false]
      }));
    this.choiceLimitNum = control.value.length;
    // tslint:disable-next-line: no-string-literal
    this.customMasterForm['controls'].customization['controls'][0].controls.choiceUpperLimit.setValidators(
      [Validators.pattern("^[0-9]*$"),
      Validators.min(this.isDefaultNum),
      Validators.max(this.choiceLimitNum),
      Validators.maxLength(1)]
    );
    this.customMasterForm['controls'].customization['controls'][0].controls.choiceLowerLimit.setValidators(
      [Validators.pattern("^[0-9]*$"),
      Validators.min(this.isDefaultNum),
      Validators.max(this.choiceLimitNum),
      Validators.maxLength(1)]
    );
    this.customMasterForm.updateValueAndValidity();
  }

  deleteOption(control, index) {
    control.removeAt(index);
    this.isDefaultNum = 0;
    control.value.forEach(defaultItem => {
      if (defaultItem.isDefault){
        this.isDefaultNum += 1;
      }
    });
    this.choiceLimitNum = control.value.length;
    // tslint:disable-next-line: no-string-literal
    this.customMasterForm['controls'].customization['controls'][0].controls.choiceUpperLimit.setValidators(
      [Validators.pattern("^[0-9]*$"),
      Validators.min(this.isDefaultNum),
      Validators.max(this.choiceLimitNum),
      Validators.maxLength(1)]
    );
    this.customMasterForm['controls'].customization['controls'][0].controls.choiceLowerLimit.setValidators(
      [Validators.pattern("^[0-9]*$"),
      Validators.min(this.isDefaultNum),
      Validators.max(this.choiceLimitNum),
      Validators.maxLength(1)]
    );
    this.customMasterForm.updateValueAndValidity();
  }

  updateDefaultOptionNum(control) {
    console.log(control.value);
    this.isDefaultNum = 0;
    setTimeout(() => {
      control.value.forEach(defaultItem => {
        if (defaultItem.isDefault){
          this.isDefaultNum += 1;
        }
      });
      this.choiceLimitNum = control.value.length;
    }, 50);
  }

  setOptions(x) {
    const arr = new FormArray([]);
    x.options.forEach(y => {
      arr.push(this.fb.group({
        name: y.name,
        price: y.price,
        isDefault: y.isDefault
      }));
    });
    return arr;
  }

  // Formats prices to cents
  formatDollarsToCents(value) {
    value = (value + '').replace(/[^\d.-]/g, '');
    if (value && value.includes('.')) {
      value = value.substring(0, value.indexOf('.') + 3);
    }
    return value ? Math.round(parseFloat(value) * 100) : 0;
  }

  onCreateCustomMasterSubmit() {
    this.customMasterForm.value.customization[0].choiceLimit = this.customMasterForm.value.customization[0].choiceUpperLimit;
    const formattedCustomization = JSON.stringify(this.customMasterForm.value.customization[0], (key, value) => {
      if (key === 'price') {
        if (value === ''){
          value = 0;
        }
        value = this.formatDollarsToCents(value);
        value = Number(value);
        return value;
      }
      return value;
    });
    

    const formatCustomizationForUpdate = JSON.parse(formattedCustomization);
    this.createCustomizationQuerySubscription = this.catalogueService
    .createCustomization(formatCustomizationForUpdate).subscribe((response: any) => {
      console.log('custom', response);
    }, (err) => {console.log(err)});

    this.modalController.dismiss(this.choiceLimitCarrier);
  }
}

