import { MerchantBusinessService } from "src/app/shared/services/merchant-business.service";
import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { BusinessService } from "src/app/shared/services/business.service";
import { AlertController, ModalController } from "@ionic/angular";
import {
  BusinessInput,
  Slot,
  BusinessTimingDetails,
  BusinessPatchInput,
  PlatformType,
  ServingPlatform,
  ServicePlatformPatch,
  ServicePlatformInput,
  ServicePlatformSubType,
} from "../../business-details/business.model";
import { Subscription } from "rxjs";

@Component({
  selector: "simpayx-service-platform",
  templateUrl: "./service-platform.component.html",
  styleUrls: ["./service-platform.component.scss"],
})
export class ServicePlatformComponent implements OnInit, OnDestroy {
  @Input() business: BusinessInput;
  day = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  slots: Map<String, Slot>;
  businessTimings: Map<String, BusinessTimingDetails>;
  type: any;
  isAddedHour: boolean;
  businessPatchInput: BusinessPatchInput;
  platformType = PlatformType;
  selectedAverageServiceTime: string;
  serviceCapacity: number;
  patchResourceQuerySubscription: Subscription;

  constructor(
    private businessService: BusinessService,
    private merchantBusinessService: MerchantBusinessService,
    public alertController: AlertController,
    public modalController: ModalController
  ) {}

  servingPlatformConstants = ServingPlatform;
  ngOnInit() {
    this.businessTimings = this.business.businessAttributes.servicePlatforms[0].businessTimings;
    this.business.businessAttributes.servicePlatforms.forEach((platform) => {
      if (platform.type === PlatformType.RESERVATION) {
        this.slots = platform.slots;
      }
    });
  }

  onServiceStatusChange(platform: ServicePlatformInput) {
    this.confirmBusinessUpdate(platform);
  }

  async confirmBusinessUpdate(platform: ServicePlatformInput) {
    var message = this.getMessageForServiceChange(platform);
    const alert = await this.alertController.create({
      header: "Alert",
      message: "Are you sure you wish to " + message,
      buttons: [
        {
          text: "No",
          role: "cancel",
          handler: () => {},
        },
        {
          text: "Yes",
          handler: () => {
            //ok action
            let subType = ServicePlatformSubType.RESOURCE_PLATFORM;
            if (platform.type === PlatformType.RESERVATION) {
              subType = ServicePlatformSubType.SLOT_PLATFORM;
            }
            let businessPatchInput: BusinessPatchInput = new BusinessPatchInput();
            let servicePlatforms: ServicePlatformInput = new ServicePlatformInput(
              platform.type,
              subType
            );
            let servicePlatformPatch: ServicePlatformPatch = new ServicePlatformPatch();
            businessPatchInput.operationType = "UPDATE";
            businessPatchInput.operationField = "SERVICE_PLATFORM";
            servicePlatforms.type = platform.type;
            servicePlatforms.isEnabled = platform.isEnabled;

            servicePlatformPatch.isEnabled = servicePlatforms.isEnabled;
            servicePlatformPatch.servicePlatforms = servicePlatforms;
            businessPatchInput.servicePlatformPatch = servicePlatformPatch;
            this.patchResourceQuerySubscription = this.businessService
              .patchResource(
                this.business.merchantId,
                this.business.id,
                businessPatchInput
              )
              .subscribe((result: any) => {
                console.log(result.data.PatchBusiness);
                this.merchantBusinessService.getBusinessForMerchant(
                  this.business.merchantId
                );
              });
          },
        },
      ],
    });
    await alert.present();
  }

  getMessageForServiceChange(platform: ServicePlatformInput) {
    return platform.isEnabled
      ? "start accepting " + platform.type + " orders"
      : "stop accepting " + platform.type + " orders";
  }

  async manageHours(type, i) {
    if (this.type == type) {
      this.type = null;
      return;
    } else this.type = type;

    console.log(this.businessTimings);
    let close = [];
    let businessHour = { businessHours: close, isOpen: true };
    let closeTiming: BusinessTimingDetails = Object.assign({}, businessHour);
    let businessTimings: any = new Object();
    let servicePlatform = this.business.businessAttributes.servicePlatforms.find(
      (platform) => platform.type === type
    );
    if (servicePlatform.type === PlatformType.RESERVATION) {
      if (Object.keys(servicePlatform.slots).length > 0) {
        this.day.forEach((d) => {
          businessTimings[d] = servicePlatform.slots[d].businessTimingDetails;
          if (!this.selectedAverageServiceTime || !this.serviceCapacity) {
            if (servicePlatform.slots[d].resources.length > 0) {
              this.serviceCapacity =
                servicePlatform.slots[d].resources[0].serviceCapacity;
              this.selectedAverageServiceTime =
                servicePlatform.slots[d].resources[0].serviceTimeInMin + " min";
            }
          }
        });
        this.businessTimings = businessTimings;
        this.slots = servicePlatform.slots;
      } else {
        this.businessTimings = new Map([]);
      }
    } else {
      this.businessTimings = servicePlatform.businessTimings;
    }

    if (Object.keys(this.businessTimings).length === 0) {
      this.day.forEach((d) => {
        console.log(Object.keys(this.businessTimings).length);
        this.isAddedHour = false;
        this.businessTimings[d] = Object.assign({}, closeTiming);
      });
    } else {
      this.day.forEach((d) => {
        if (!this.businessTimings[d].businessHours[0]) {
          this.isAddedHour = true;
        } else {
          console.log(d);
          this.isAddedHour = true;
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.patchResourceQuerySubscription) {
      this.patchResourceQuerySubscription.unsubscribe();
    }
  }
}
