import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { IsEnabledComponent } from './is-enabled.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    FormsModule
  ],
  entryComponents: [IsEnabledComponent],
  declarations: [IsEnabledComponent],
  exports: [IsEnabledComponent]
})
export class IsEnabledModule { }
