import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { Catalogue } from "../catalogue/catalogue.model";
import { CatalogueService } from "src/app/shared/services/catalogue.service";
import { EventEmitterService } from "src/app/shared/services/event-emitter.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-create-catalogue",
  templateUrl: "./create-catalogue.component.html",
  styleUrls: ["./create-catalogue.component.scss"],
})
export class CreateCatalogueComponent implements OnInit, OnDestroy {
  createCatalogueQuerySubscription: Subscription;
  catalogue: Catalogue;
  @Input() connectedId: string;
  @Input() allData: string;
  @Output() closeModalEvent = new EventEmitter();

  data = [
    {
      // businessId: '',
      name: "",
      categories: [
        {
          id: "",
          name: "",
          description: "",
          items: [
            {
              id: "",
              name: "",
              description: "",
              price: null,
              tags: [
                {
                  id: "",
                  name: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  catalogueForm = new FormGroup({});
  constructor(
    private fb: FormBuilder,
    public modalController: ModalController,
    private catalogueService: CatalogueService,
    private eventEmitterService: EventEmitterService
  ) {
    this.catalogueForm = this.fb.group({
      catalogue: this.fb.array([]),
    });
  }
  ngOnDestroy(): void {
    if (this.createCatalogueQuerySubscription) {
      this.createCatalogueQuerySubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.setCatalogues();
  }

  // Catalogue Methods
  setCatalogues() {
    const control = this.catalogueForm.controls.catalogue as FormArray;
    this.data.forEach((x) => {
      control.push(
        this.fb.group({
          businessId: new FormControl(this.connectedId, [
            Validators.required,
            Validators.minLength(10),
          ]),
          name: new FormControl(x.name, [
            Validators.required,
            Validators.minLength(4),
          ]),
          categories: this.setCategories(x),
        })
      );
    });
  }

  // Category Methods
  addNewCategory(control) {
    control.push(
      this.fb.group({
        name: [""],
        description: [""],
        items: this.fb.array([]),
      })
    );
  }

  deleteCategory(control, index) {
    control.removeAt(index);
  }

  setCategories(x) {
    const arr = new FormArray([]);
    x.categories.forEach((y) => {
      arr.push(
        this.fb.group({
          name: y.name,
          description: y.description,
          items: this.setItems(y),
        })
      );
    });
    return arr;
  }

  // Item Methods
  addNewItem(control) {
    control.push(
      this.fb.group({
        name: [""],
        description: [""],
        price: [],
        tags: this.fb.array([]),
      })
    );
  }

  deleteItem(control, index) {
    control.removeAt(index);
  }

  setItems(y) {
    const arr = new FormArray([]);
    y.items.forEach((z) => {
      arr.push(
        this.fb.group({
          name: z.name,
          description: z.description,
          price: Number(z.price),
          tags: this.setTags(z),
        })
      );
    });
    return arr;
  }

  // Item Methods
  addNewTag(control) {
    control.push(
      this.fb.group({
        name: [""],
      })
    );
  }

  deleteTag(control, index) {
    control.removeAt(index);
  }

  setTags(z) {
    const arr = new FormArray([]);
    z.tags.forEach((a) => {
      arr.push(
        this.fb.group({
          name: a.name,
        })
      );
    });
    return arr;
  }

  // Formats prices to cents
  formatDollarsToCents(value) {
    value = (value + "").replace(/[^\d.-]/g, "");
    if (value && value.includes(".")) {
      value = value.substring(0, value.indexOf(".") + 3);
    }
    return value ? Math.round(parseFloat(value) * 100) : 0;
  }

  onCatalogueSubmit() {
    const formattedCatalogue = JSON.stringify(
      this.catalogueForm.value.catalogue[0],
      (key, value) => {
        if (key === "price") {
          value = this.formatDollarsToCents(value);
          value = Number(value);
          return value;
        }
        return value;
      }
    );
    const formatCatlogueForUpdate = JSON.parse(formattedCatalogue);
    formatCatlogueForUpdate.isEnabled = true;

    this.createCatalogueQuerySubscription = this.catalogueService
      .createCatalogueForBusiness(formatCatlogueForUpdate)
      .subscribe((response: any) => {});

    setTimeout(() => {
      // tslint:disable-next-line: max-line-length
      this.catalogueService
        .getAllCataloguesForBusiness(this.connectedId)
        .valueChanges.subscribe((catalogues: any) => {
          if (catalogues.data !== undefined) {
            const GetCatalogues = catalogues.data.GetAllCatalogues;
            // this.eventEmitterService.refreshGrid(GetCatalogues);
            this.closeModalEvent.emit(null);
            // this.modalController.dismiss();
            this.catalogueForm.reset();
          }
        });
    }, 50);
  }
}
