import {
  Input,
  AfterViewInit,
  ViewChild,
  DoCheck,
  AfterViewChecked,
} from "@angular/core";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { BusinessService } from "src/app/shared/services/business.service";
import { ValidationService } from "src/app/shared/services/validation.service";
import { BusinessInput } from "../business-details/business.model";
import { MatStepper } from "@angular/material/stepper";

@Component({
  selector: "app-business-onboard",
  templateUrl: "./business-onboard.component.html",
  styleUrls: ["./business-onboard.component.scss"],
})
export class BusinessOnboardComponent implements OnInit, AfterViewInit {
  @ViewChild("stepper") stepper: MatStepper;
  @Input() merchantId: string;
  @Input() allData: any;
  business: BusinessInput;
  hasBusinessData: boolean = true;
  example: [
    {
      Monday: {
        isOpen: false;
        businessHours: {
          opensAt: "";
          closesAt: "";
        };
      };
    }
  ];

  businessForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private businessService: BusinessService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    let taxes = [];
    if (this.allData === undefined) {
      this.hasBusinessData = false;
    } else {
      this.allData.data.businessAttributes.taxes.forEach((tax) => {
        let taxVar = this.setTaxValue(tax);
        taxes.push(taxVar);
      });
    }
    this.business = new BusinessInput();
    this.business.merchantId = this.merchantId;
    this.businessForm = this.fb.group({
      basicDetails: new FormGroup({
        // merchantId: new FormControl(this.business.merchantId, [Validators.required, Validators.minLength(10)]),
        name: new FormControl(this.business.name, [Validators.required]),
        vertical: new FormControl(this.business.vertical, Validators.required),
        isEnabled: new FormControl(this.business.isEnabled),
      }),
      address: new FormGroup({
        line1: new FormControl(
          this.business.address.line1,
          Validators.required
        ),
        line2: new FormControl(this.business.address.line2),
        city: new FormControl(this.business.address.city, Validators.required),
        state: new FormControl(
          this.business.address.state,
          Validators.required
        ),
        zip: new FormControl(this.business.address.zip, [
          Validators.required,
          ValidationService.zipCodeValidator,
        ]),
        country: new FormControl(
          this.business.address.country,
          Validators.required
        ),
        timeZone: new FormControl(
          this.business.address.timeZone,
          Validators.required
        ),
      }),

      digitalAddresses: this.fb.array([
        this.fb.group({
          addressType: [this.business.digitalAddresses[0].addressType],
          address: [
            this.business.digitalAddresses[0].address,
            ValidationService.emailValidator,
          ],
          preferred: [this.business.digitalAddresses[0].preferred],
        }),
        this.fb.group({
          addressType: [this.business.digitalAddresses[1].addressType],
          // address: [this.business.digitalAddresses[1].address, Validators.pattern('[- +()0-9]+')],
          address: [
            this.business.digitalAddresses[1].address,
            ValidationService.phoneValidator,
          ],
          preferred: [this.business.digitalAddresses[1].preferred],
        }),
        this.fb.group({
          addressType: [this.business.digitalAddresses[2].addressType],
          address: [this.business.digitalAddresses[2].address],
          preferred: [this.business.digitalAddresses[2].preferred],
        }),
        this.fb.group({
          addressType: [this.business.digitalAddresses[3].addressType],
          address: [this.business.digitalAddresses[3].address],
          preferred: [this.business.digitalAddresses[3].preferred],
        }),
      ]),

      businessAttributes: new FormGroup({
        taxes: this.fb.array(this.allData ? taxes : []),
      }),
    });
    // console.log(this.allData.data.businessAttributes.taxes);

    if (this.allData) {
      console.log(taxes);
      const digitalAddressOrder = ["EMAIL", "CELL", "WEBSITE", "LOCATION_URL"];
      const digitalAddresses = this.allData.data.digitalAddresses.sort(
        (a, b) =>
          digitalAddressOrder.indexOf(a.addressType) -
          digitalAddressOrder.indexOf(b.addressType)
      );
      this.businessForm.patchValue({
        basicDetails: {
          name: this.allData.data.name,
          vertical: this.allData.data.vertical,
          isEnabled: this.allData.data.isEnabled,
        },
        address: this.allData.data.address,
        // businessAttributes: {
        //   taxes: taxes,
        // },
        digitalAddresses: digitalAddresses,
      });
    }
    console.log("AFTER", this.businessForm.value);
  }

  ngAfterViewInit() {
    if (this.allData?.data) {
      setTimeout(() => {
        this.stepper.selectedIndex = 4;
      }, 50);
    }
  }

  //SET TAXES
  setTaxes() {
    // if(this.allData){
    //   return new Array(this.allData.data.businessAttributes.taxes)
    // }
    return this.fb.group({
      name: ["", Validators.required],
      rate: ["", [Validators.required, ValidationService.taxValidator]],
    });
  }

  setTaxValue(tax) {
    return this.fb.group({
      name: [tax.name, Validators.required],
      rate: [tax.rate.toString(), [Validators.required, ValidationService.taxValidator]],
    });
  }
}
