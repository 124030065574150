import { Injectable } from "@angular/core";
import { BusinessHours, ResourcesInput } from "src/app/feature/business-details/business.model";

@Injectable({
    providedIn: 'root'
})

export class ReservationSlotService {

    createReservationResources(day: string, businessHours: BusinessHours[], averageServiceTime: number, serviceCapacity: number) {
        let resources: ResourcesInput[] = [];

        businessHours.forEach(businessHour => {
            let differenceInMins = this.getTimeDifference(businessHour);
            let slotsCreatedTimeInMins = 0;
            while(differenceInMins >= averageServiceTime){
                var splittedOpensAt = businessHour.opensAt.toString().split(":");
                let slotTime = new Date();
                slotTime.setHours(+splittedOpensAt[0],+splittedOpensAt[1]+slotsCreatedTimeInMins);
                differenceInMins -= averageServiceTime;
                slotsCreatedTimeInMins += averageServiceTime;
                console.log("Start Time", slotTime);
                
                let resourceName = this.convertTimeToResource(slotTime.getHours(),+slotTime.getMinutes());
                console.log('resourceName',resourceName);

                //create resource object
                let resource: ResourcesInput = new ResourcesInput();
                resource.id = this.generateResourceId();
                resource.name = resourceName;
                resource.isEnabled = true;
                resource.serviceTimeInMin = averageServiceTime;
                resource.serviceCapacity = serviceCapacity;
                resource.attributes.set(day,day);
                console.log(resource);
                resources.push(resource);
            }
        });
        return resources;
    }


    getTimeDifference(businessHour: BusinessHours) {
        var splitted1 = businessHour.opensAt.toString().split(":");
        var splitted2 = businessHour.closesAt.toString().split(":");

        let opensAt = new Date();
        opensAt.setHours(+splitted1[0], +splitted1[1])

        let closesAt = new Date();
        closesAt.setHours(+splitted2[0], +splitted2[1]);


        let differenceInTime = closesAt.getTime() - opensAt.getTime();
        console.log('differenceInTime', differenceInTime);
        let differenceMins = differenceInTime / (1000 * 60);
        console.log('differenceHrs', differenceMins);
        return differenceMins;
    }

    private convertTimeToResource(hour:number, minutes:number) {
        let meridiem = '';
        if(hour > 12){
            hour = hour - 12;
            meridiem = 'PM'
        }
        else{
            meridiem = 'AM'
        }

        //convert numbers to 2 digits
        let hourStr = ("0" + hour).slice(-2);
        let minutesStr = ("0" + minutes).slice(-2);

        return hourStr+':'+minutesStr+' '+meridiem;
      }

    private generateResourceId() {
        return Math.floor(Math.random() * 1e16).toString();
    }
}