<ion-card>
  <ion-row>
    <ion-col size="12"><b>Select days</b></ion-col>
  </ion-row>
  <ion-row *ngFor="let day of weekday;index as i">
    <ion-col size="3" size-md="1" class="ion-text-center">
      <ion-checkbox name="day" mode="md" [value]="day" (ionChange)="checkMultiValue($event,i)"
        [checked]="isOptionSelected(day,i)"></ion-checkbox>
    </ion-col>
    <ion-col size="9" size-md="9" class="ion-padding-start">
      <ion-label class="text-color-black ion-text-uppercase">{{ day }}</ion-label>
    </ion-col>

  </ion-row>
  <ion-row>
    <ion-col size="6" class="ion-text-center">
      <ion-chip class="margin-padding" outline color="primary" (click)="doneCustom()">
        <ion-icon name="checkmark-outline"></ion-icon>
        <ion-label class="font-size">Done</ion-label>
      </ion-chip>
    </ion-col>
    <ion-col size="6" class="ion-text-center">
      <ion-chip class="margin-padding" outline color="primary" (click)="cancelCustom()">
        <ion-icon name="close-outline"></ion-icon>
        <ion-label class="font-size">Cancel</ion-label>
      </ion-chip>
    </ion-col>
  </ion-row>
</ion-card>


