import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  mockData: any[] = [
    {
    id: '000',
    name: 'Laxmi Mahate',
    isActive: true,
    isEnabled: true,
    businesses: [
      {
        id: '100',
      name: 'Mock Data',
      vertical: 'Restuarant',
      address: {
        line1: '',
      },
      isEnabled: true,
        catalogues: [
          {
            id: '00000',
            name: 'Lunch Menu',
            businessId: '1000',
            categories: [
              {
                id: '000',
                name: 'Tacos',
                description: 'Gourmet Street Tacos',
                items: [
                  {
                    id: '000',
                    name: 'Hard Shell',
                    description: 'Made from corn',
                    price: '$3.50',
                    tags: [
                      {
                        id: '000',
                        name: 'Veg',
                        businessId: '1000'
                      }
                    ]
                  },
                  {
                    id: '001',
                    name: 'Soft Shell',
                    description: 'Made from flour',
                    price: '$3.99',
                    tags: [
                      {
                        id: '000',
                        name: 'Veg',
                        businessId: '1000'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            id: '00000',
            name: 'Lunch Menu',
            businessId: '1000',
            categories: [
              {
                id: '000',
                name: 'Tacos',
                description: 'Gourmet Street Tacos',
                items: [
                  {
                    id: '000',
                    name: 'Hard Shell',
                    description: 'Made from corn',
                    price: '$3.50',
                    tags: [
                      {
                        id: '000',
                        name: 'Veg',
                        businessId: '1000'
                      }
                    ]
                  },
                  {
                    id: '001',
                    name: 'Soft Shell',
                    description: 'Made from flour',
                    price: '$3.99',
                    tags: [
                      {
                        id: '000',
                        name: 'Veg',
                        businessId: '1000'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            id: '00000',
            name: 'Lunch Menu',
            businessId: '1000',
            categories: [
              {
                id: '000',
                name: 'Tacos',
                description: 'Gourmet Street Tacos',
                items: [
                  {
                    id: '000',
                    name: 'Hard Shell',
                    description: 'Made from corn',
                    price: '$3.50',
                    tags: [
                      {
                        id: '000',
                        name: 'Veg',
                        businessId: '1000'
                      }
                    ]
                  },
                  {
                    id: '001',
                    name: 'Soft Shell',
                    description: 'Made from flour',
                    price: '$3.99',
                    tags: [
                      {
                        id: '000',
                        name: 'Veg',
                        businessId: '1000'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        id: 100,
      name: 'Mock Data',
      vertical: 'Restuarant',
      address: {
        line1: '',
      },
      isEnabled: true,
        catalogues: [
          {
            id: '00000',
            name: 'Breakfast Menu',
            businessId: '1000',
            categories: [
              {
                id: '',
                name: '',
                description: '',
                items: [
                  {
                    id: '',
                    name: '',
                    description: '',
                    price: '',
                    tags: [
                      {
                        id: '',
                        name: '',
                        businessId: ''
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            id: '00001',
            name: 'Lunch Menu',
            businessId: '1000',
            categories: [
              {
                id: '',
                name: '',
                description: '',
                items: [
                  {
                    id: '',
                    name: '',
                    description: '',
                    price: '',
                    tags: [
                      {
                        id: '',
                        name: '',
                        businessId: ''
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            id: '00002',
            name: 'Dinner Menu',
            businessId: '1000',
            categories: [
              {
                id: '',
                name: '',
                description: '',
                items: [
                  {
                    id: '',
                    name: '',
                    description: '',
                    price: '',
                    tags: [
                      {
                        id: '',
                        name: '',
                        businessId: ''
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
  ];
  private messageSource = new BehaviorSubject(this.mockData);
  currentMessage = this.messageSource.asObservable();

  constructor() {}

  changeMessage(mock: any[]) {
    this.messageSource.next(mock);
  }

}
