import { MerchantBusinessService } from "src/app/shared/services/merchant-business.service";
import {
  Component,
  OnInit,
  Input,
  SimpleChange,
  OnChanges,
} from "@angular/core";
import { BusinessService } from "src/app/shared/services/business.service";
import {
  BusinessInput,
  AddressType,
  DigitalAddressInput,
  BusinessPatchInput,
} from "../../business-details/business.model";
import { Subscription } from 'rxjs';

@Component({
  selector: "simpayx-information",
  templateUrl: "./business-information.component.html",
  styleUrls: ["./business-information.component.scss"],
})
export class InformationComponent implements OnInit {
  @Input() business: BusinessInput;
  website: string;
  location: string;
  patchResourceQuerySubscription: Subscription;


  constructor(
    private businessService: BusinessService,
    private merchantBusinessService: MerchantBusinessService
  ) {}

  ngOnInit() {
    console.log(this.business);
    this.business.digitalAddresses.forEach((digitalAddress) => {
      if (digitalAddress.addressType === AddressType.WEBSITE) {
        this.website = digitalAddress.address;
      } else if (digitalAddress.addressType === AddressType.LOCATION_URL) {
        this.location = digitalAddress.address;
      }
    });
  }
  // ngOnChanges(change: SimpleChange) {
  //   console.log(this.business);

  // }

  saveInformation() {
    console.log(this.website, this.location);
    let businessPatchInput: BusinessPatchInput = new BusinessPatchInput();
    businessPatchInput.digitalAddresses = this.business.digitalAddresses;
    if (this.website !== undefined) {
      var adress = businessPatchInput.digitalAddresses.find(
        (digitalAddres) => digitalAddres.addressType == AddressType.WEBSITE
      );
      if (adress == undefined) {
        let digitalAddresses: DigitalAddressInput = new DigitalAddressInput(
          AddressType.WEBSITE
        );
        digitalAddresses.address = this.website;
        businessPatchInput.digitalAddresses.push(digitalAddresses);
      } else {
        adress.address = this.website;
      }
    }

    if (this.location !== undefined) {
      var adress = businessPatchInput.digitalAddresses.find(
        (digitalAddresses) =>
          digitalAddresses.addressType == AddressType.LOCATION_URL
      );
      if (adress == undefined) {
        let digitalAddressesLocation: DigitalAddressInput = new DigitalAddressInput(
          AddressType.LOCATION_URL
        );
        digitalAddressesLocation.address = this.location;
        businessPatchInput.digitalAddresses.push(digitalAddressesLocation);
      } else {
        adress.address = this.location;
      }
    }
    console.log(JSON.stringify(businessPatchInput));
    this.patchResourceQuerySubscription = this.businessService
      .patchResource(
        this.business.merchantId,
        this.business.id,
        businessPatchInput
      )
      .subscribe((result: any) => {
        console.log(result.data.PatchBusiness);
        this.merchantBusinessService.getBusinessForMerchant(
          this.business.merchantId
        );
      });
  }
}
