import { Apollo } from "apollo-angular";
import {
  BusinessInput,
  BusinessPatchInput,
  PermalinkMetaDataInput,
} from "./../../feature/business-details/business.model";
import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import {
  UserBusinessDetailsInput,
  UserInput,
} from "src/app/feature/edit-business/user.model";
import { MerchantBusinessService } from "./merchant-business.service";
import { EventEmitterService } from "./event-emitter.service";
import { OrderNumber } from "src/app/feature/create-account/order-number.model";
import { GET_ALL_FEATURES } from "./feature-toggle.service";

export const CREATE_BUSINESS_FOR_MERCHANT = gql`
  mutation CreateBusiness($merchantId: String, $business: BusinessInput) {
    CreateBusiness(merchantId: $merchantId, business: $business) {
      id
      name
      merchantId
      vertical
      address {
        line1
        line2
        state
        city
        country
        zip
        timeZone
      }
      isEnabled
      digitalAddresses {
        addressType
        address
        preferred
      }
      businessAttributes {
        catalogues
        taxes {
          name
          rate
        }
        theme
        servicePlatforms {
          type
          liveCatalogueId
          platformFees
          isEnabled
          resources {
            id
            name
            isEnabled
            attributes
            serviceCapacity
            serviceTimeInMin
          }
          businessTimings
          avgServiceTimeInMin
        }
      }
    }
  }
`;

export const UPDATE_BUSINESS = gql`
  mutation updateBusiness(
    $merchantId: String
    $businessId: String
    $business: BusinessInput
  ) {
    UpdateBusiness(
      merchantId: $merchantId
      businessId: $businessId
      business: $business
    ) {
      id
      name
      merchantId
      vertical
      address {
        line1
        line2
        state
        city
        country
        zip
        timeZone
      }
      isEnabled
      digitalAddresses {
        addressType
        address
        preferred
      }
      businessAttributes {
        catalogues
        taxes {
          name
          rate
        }
        theme
        servicePlatforms {
          type
          liveCatalogueId
          platformFees
          isEnabled
          resources {
            id
            name
            isEnabled
            attributes
            serviceCapacity
            serviceTimeInMin
          }
          businessTimings
          avgServiceTimeInMin
        }
      }
    }
  }
`;

export const DELETE_BUSINESS = gql`
  mutation DeleteBusiness($merchantId: String, $businessId: String) {
    DeleteBusiness(merchantId: $merchantId, businessId: $businessId)
  }
`;

export const BUSINESS_BY_ID_QUERY = gql`
  query GetBusinessById($merchantId: String, $businessId: String) {
    GetBusinessById(merchantId: $merchantId, businessId: $businessId) {
      id
      name
      merchantId
      vertical
      address {
        line1
        line2
        state
        city
        country
        zip
        timeZone
      }
      isEnabled
      digitalAddresses {
        addressType
        address
        preferred
      }
      businessAttributes {
        catalogues
        taxes {
          name
          rate
        }
        theme
        servicePlatforms {
          type
          liveCatalogueId
          platformFees
          isEnabled
          resources {
            id
            name
            isEnabled
            attributes
            serviceCapacity
            serviceTimeInMin
          }
          businessTimings
          slots
          avgServiceTimeInMin
        }
      }
    }
  }
`;

export const PATCH_BUSINESS = gql`
  mutation PatchBusiness(
    $merchantId: String
    $businessId: String
    $businessPatchInput: BusinessPatchInput
  ) {
    PatchBusiness(
      merchantId: $merchantId
      businessId: $businessId
      businessPatchInput: $businessPatchInput
    ) {
      id
      name
      merchantId
      vertical
      address {
        zip
        line1
        line2
        state
        city
        country
        timeZone
      }
      isEnabled
      digitalAddresses {
        addressType
        address
        preferred
      }
      businessAttributes {
        catalogues
        taxes {
          name
          rate
        }
        theme
        servicePlatforms {
          avgServiceTimeInMin
          type
          liveCatalogueId
          businessTimings
          platformFees
          isEnabled
          resources {
            id
            name
            isEnabled
            attributes
            serviceCapacity
            serviceTimeInMin
          }
        }
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateNewUser($user: UserInput) {
    CreateNewUser(user: $user) {
      merchantId
      businesses {
        id
        uid
        businessName
      }
    }
  }
`;

export const PATCH_USER_MUTATION = gql`
  mutation PatchBusinessForUser(
    $merchantId: String
    $businessDetail: UserBusinessDetailsInput
  ) {
    PatchBusinessForUser(
      merchantId: $merchantId
      businessDetail: $businessDetail
    ) {
      merchantId
      businesses {
        id
        uid
        email
        isActive
        businessName
      }
    }
  }
`;

export const CREATE_ORDER_NUMBER = gql`
  mutation CreateOrderNumber(
    $businessId: String
    $orderNumber: OrderNumberInput
  ) {
    CreateOrderNumber(businessId: $businessId, orderNumber: $orderNumber) {
      id
      businessId
      counter
    }
  }
`;

export const CHECK_USER_EXISTS_QUERY = gql`
  query CheckUserExist($merchantId: String) {
    CheckUserExist(merchantId: $merchantId)
  }
`;

export const GET_USERS_BY_MERCHANT_ID = gql`
  query GetUserByMerchantId($merchantId: String) {
    GetUserByMerchantId(merchantId: $merchantId) {
      id
      merchantId
      businesses {
        id
        email
        isActive
        businessName
        uid
      }
    }
  }
`;

export const GET_ALL_PERMALINKS = gql`
  query GetAllMetadataByBusinessId($businessId: String) {
    GetAllMetadataByBusinessId(businessId: $businessId) {
      id
      shortName
      isDefault
      businessDetails {
        merchantId
        businessId
      }
    }
  }
`;

export const GET_ALL_BUSINESSES = gql`
  query GetAllBusinesses {
    GetAllBusinesses {
      name
      id
      address{
        line1
        line2
      }
    }
  }
`;

export const CREATE_PERMALINK = gql`
  mutation CreateMetaData($permalinkMetaData: PermalinkMetaDataInput) {
    CreateMetaData(permalinkMetaData: $permalinkMetaData) {
      id
      shortName
      isDefault
      businessDetails {
        merchantId
        businessId
      }
    }
  }
`;

export const DELETE_PERMALINK = gql`
  mutation DeleteMetadata($id: String) {
    DeleteMetadata(id: $id)
  }
`;

@Injectable({
  providedIn: "root",
})
export class BusinessService {
  constructor(public apollo: Apollo) { }

  createBusinessForMerchant(businessData: BusinessInput) {
    console.log("inside create business");
    console.log(businessData);
    return this.apollo.mutate({
      mutation: CREATE_BUSINESS_FOR_MERCHANT,
      refetchQueries: ["getAllBusinessesForMerchant"],
      variables: {
        merchantId: businessData.merchantId,
        business: businessData,
      },
    });
  }

  patchResource(merchantId, businessId, business: BusinessPatchInput) {
    return this.apollo
      .mutate({
        mutation: PATCH_BUSINESS,
        variables: {
          businessId: businessId,
          merchantId: merchantId,
          businessPatchInput: business,
        },
      })
    // .subscribe((result: any) => {
    //   console.log(result.data.PatchBusiness);
    //   this.getBusinessForMerchant(merchantId);
    // });
  }

  createUser(userInput: UserInput) {
    return this.apollo.mutate({
      mutation: CREATE_USER,
      variables: {
        user: userInput,
      },
    });
  }

  patchBusinessForUser(
    merchantId: string,
    businessDetail: UserBusinessDetailsInput
  ) {
    return this.apollo.mutate({
      mutation: PATCH_USER_MUTATION,
      refetchQueries: ["getAllBusinessesForMerchant"],
      variables: {
        merchantId: merchantId,
        businessDetail: businessDetail,
      },
    });
  }

  updateBusiness(
    merchantId: string,
    businessId: string,
    business: BusinessInput
  ) {
    return this.apollo.mutate({
      mutation: UPDATE_BUSINESS,
      variables: {
        merchantId: merchantId,
        businessId: businessId,
        business: business,
      },
    });
  }

  createOrderNumber(businessId: string, orderNumber: OrderNumber) {
    return this.apollo.mutate({
      mutation: CREATE_ORDER_NUMBER,
      variables: {
        businessId: businessId,
        orderNumber: orderNumber,
      },
    });
  }

  checkUserExist(merchantId) {
    return this.apollo.watchQuery({
      query: CHECK_USER_EXISTS_QUERY,
      variables: {
        merchantId: merchantId,
      },
    });
  }

  getUsersByMerchantId(merchantId: string) {
    return this.apollo.watchQuery({
      query: GET_USERS_BY_MERCHANT_ID,
      variables: {
        merchantId: merchantId,
      },
      fetchPolicy: "network-only",
    });
  }

  getAllPermalinksForBusiness(businessId: string) {
    return this.apollo.watchQuery({
      query: GET_ALL_PERMALINKS,
      variables: {
        businessId: businessId,
      },
      fetchPolicy: "network-only",
    });
  }

  createPermalinkForBusiness(permalinkMetaData: PermalinkMetaDataInput) {
    console.log(permalinkMetaData);
    return this.apollo.mutate({
      mutation: CREATE_PERMALINK,
      variables: {
        permalinkMetaData: permalinkMetaData,
      },
    });
  }

  deletePermaLinkForBusiness(id: string) {
    return this.apollo.mutate({
      mutation: DELETE_PERMALINK,
      variables: {
        id: id,
      },
    });
  }
  getAllBusinesses() {
    return this.apollo.watchQuery({
      query: GET_ALL_BUSINESSES,
      fetchPolicy: "cache-and-network",
    });
  }
}
