<div>
  <ion-content fullscreen>
    <ion-header class="ion-padding">
      <ion-toolbar>
        <ion-button
          size="small"
          class="float-right ion-margin"
          (click)="closeModal()"
        >
          close
        </ion-button>
        <ion-button
          size="small"
          class="float-right ion-margin"
          (click)="saveChanges()"
          >save
        </ion-button>

        <ion-searchbar
          placeholder="Find by Name or Id"
          show-clear-button="always"
          (ionClear)="onClear($event)"
          class="search"
          (ionInput)="filterList($event)"
        ></ion-searchbar>
      </ion-toolbar>
    </ion-header>
    <ion-list
      class="second"
      *ngIf="isShowingSearchResults && this.businesses.length > 0"
      lines="full"
    >
      <ion-item
        [disabled]="getStatus(business.id)"
        (click)="addBusiness(business)"
        class="ion-no-margin"
        *ngFor="let business of businesses"
      >
        <ion-label style="font-size: small">
          {{ business.name }}
          <br />
          <ion-label color="primary" class="spx-font-10">{{
            business.address.addressLine1
          }}</ion-label>
        </ion-label>
      </ion-item>
    </ion-list>
    <div class="container" *ngIf="featureBusinesses.length == 0">
      <ion-label>business list is empty... </ion-label>
    </div>
    <ion-row class="first">
      <ion-col size-md="3" *ngFor="let data of featureBusinesses">
        <ion-item class="margin:2px">
          <ion-label class="spx-font-14">
            <span class="spx-font-10"> {{ data.id }} </span>
            <br />
            {{ data.name }} <br />
            <span>
              <ion-label color="primary" class="spx-font-10">
                {{ data.address.addressLine1 }},{{ data.address.addressLine2 }}
              </ion-label>
            </span>
          </ion-label>
          <ion-button fill="clear" size="small" (click)="removeBusiness(data)">
            <ion-icon size="small" name="trash-outline"> </ion-icon>
          </ion-button>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-content>
</div>
