<div [formGroup]="businessForm">
  <div formGroupName="address"> 
    <ion-item>
      <ion-label position="floating"> Address Line 1
        <ion-text color="danger">*</ion-text>
      </ion-label>
      <ion-input required type="text" formControlName="line1"></ion-input>
    </ion-item>
    <app-control-messages [control]="businessForm.get('address').get('line1')"></app-control-messages>

    <ion-item>
      <ion-label position="floating"> Address Line 2
      </ion-label>
      <ion-input type="text" formControlName="line2"></ion-input>
    </ion-item>

    <ion-item>
      <ion-label position="floating"> Country
        <ion-text color="danger">*</ion-text>
      </ion-label>
      
      <ion-select (ionChange)="onCountryChanged($event)" mode="ios" interface="alert" formControlName="country">
        <ion-select-option *ngFor="let country of countries" value="{{country.name}}">{{country.name}}</ion-select-option>
      </ion-select>
    </ion-item>
    <app-control-messages [control]="businessForm.get('address').get('country')"></app-control-messages>

    <ion-item>
      <ion-label position="floating"> State
        <ion-text color="danger">*</ion-text>
      </ion-label>
      
      <ion-select (ionChange)="onStateChanged($event)" mode="ios" interface="alert" formControlName="state">
        <ion-select-option *ngFor="let state of states" value="{{state.name}}">{{state.name}}</ion-select-option>
      </ion-select>
    </ion-item>
    <app-control-messages [control]="businessForm.get('address').get('state')"></app-control-messages>

    <ion-item>
      <ion-label position="floating"> City
        <ion-text color="danger">*</ion-text>
      </ion-label>
      
      <ion-select mode="ios" interface="alert" formControlName="city">
        <ion-select-option *ngFor="let city of cities" value="{{city.name}}">{{city.name}}</ion-select-option>
      </ion-select>
    </ion-item>
    <app-control-messages [control]="businessForm.get('address').get('city')"></app-control-messages>

    <ion-item>
      <ion-label position="floating"> Timezone
        <ion-text color="danger">*</ion-text>
      </ion-label>
      
      <ion-select mode="ios" interface="alert" formControlName="timeZone">
        <ion-select-option *ngFor="let timezone of selectedCountry?.timezones" value="{{timezone.zoneName}}">{{timezone.zoneName}}</ion-select-option>
      </ion-select>
    </ion-item>
    <app-control-messages [control]="businessForm.get('address').get('timeZone')"></app-control-messages>

    <ion-item>
      <ion-label position="floating"> Zip Code
        <ion-text color="danger">*</ion-text>
      </ion-label>
      <ion-input required type="text" formControlName="zip"></ion-input>
    </ion-item>
    <app-control-messages [control]="businessForm.get('address').get('zip')"></app-control-messages>

    <ion-grid>
      <ion-row>

          <ion-col class="ion-text-start ion-padding ion-margin">
            <ion-button (click)="onPreviousClicked(stepper)">
              Previous
              <ion-icon slot="start" name="arrow-back-outline"></ion-icon>
            </ion-button>
          </ion-col>
      
          <ion-col class="ion-text-end ion-padding ion-margin">
            <ion-button (click)="onAddressNextClicked(stepper)">
              Next
              <ion-icon slot="end" name="arrow-forward-outline"></ion-icon>
            </ion-button>
        </ion-col>
        
      </ion-row>
    </ion-grid>
  </div>
</div>    