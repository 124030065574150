import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CategoryInput } from 'src/app/feature/catalogue/categories/categoryInput.model';

@Component({
  selector: 'app-create-category-standalone',
  templateUrl: './create-category-standalone.component.html',
  styleUrls: ['./create-category-standalone.component.scss'],
})
export class CreateCategoryStandaloneComponent implements OnInit {
  category: CategoryInput;

  categoryStandaloneForm = new FormGroup({
    // id: new FormControl('', [Validators.required, Validators.minLength(10)]),
    name: new FormControl('', [Validators.required, Validators.minLength(4)]),
    description: new FormControl(''),
    isEnabled: new FormControl(true),
  });
  constructor(private fb: FormBuilder,
              private modalController: ModalController) { }

  ngOnInit() {}

  onCreateCategoryStandaloneSubmit(){
    this.category = this.categoryStandaloneForm.value;
    
    this.modalController.dismiss(this.category);
  }
}
