import { Component, OnInit, OnDestroy } from '@angular/core';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { Subscription } from 'rxjs';
import { MerchantBusinessService } from 'src/app/shared/services/merchant-business.service';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { CataloguePackageService } from './../../shared/services/catalogue-package.service';

@Component({
  selector: 'app-is-enabled-catalogue',
  templateUrl: './is-enabled-catalogue.component.html',
  styleUrls: ['./is-enabled-catalogue.component.scss'],
})
export class IsEnabledCatalogueComponent implements OnInit, OnDestroy {
  isEnabled: boolean;
  catalogue: any;
  catalogueQuery: any;
  catPackage: any[];
  params: any;
  querySubscription: Subscription;
  queryBusinessSubscription: Subscription;
  queryCatalogueSubscription: Subscription;
  gridDataSubscription: Subscription;

  constructor(
    private catalogueService: CatalogueService,
    private merchantBusinessService: MerchantBusinessService,
    private cataloguePackageService: CataloguePackageService
  ) { }

  ngOnDestroy(): void {
    if (this.querySubscription) {
      this.querySubscription.unsubscribe();
    }
    if (this.queryBusinessSubscription) {
      this.querySubscription.unsubscribe();
    }
    if (this.queryCatalogueSubscription) {
      this.queryCatalogueSubscription.unsubscribe();
    }
    if (this.gridDataSubscription) {
      this.gridDataSubscription.unsubscribe();
    }
  }

  agInit(params) {
    this.params = params;
    this.isEnabled = this.params.data.isEnabled;
    console.log(this.isEnabled);
  }

  ngOnInit() {
    this.cataloguePackageService.currentMessage.subscribe(catPackage => {
      catPackage.forEach(liveParam => {
        // delete liveParam.liveCatId;
      });
      // delete this.catPackage.liveCatId;
      this.catPackage = catPackage;
    });
  }
  getCatalogueForBusinessById(businessId, catalogueId){
  this.queryCatalogueSubscription = this.catalogueService.getCatalogueForBusinessById(businessId, catalogueId)
    .valueChanges.subscribe((catalogueData: any) => {
      console.log('catalogueData', catalogueData);
        // this.catPackage[this.params.rowIndex].categories = catalogueData.data.GetCatalogueById;
    });
  }

  toggleCatalogueEnabled(val){
    console.log('this.params.businessId', this.params.businessId);
    console.log('this.params.data.id', this.params.data.id);
    //this.getCatalogueForBusinessById(this.params.businessId, this.params.data.id);
    // event.stopImmediatePropagation();
    // event.stopPropagation();
    // event.preventDefault();
    this.isEnabled = !this.isEnabled;
    //this.catPackage[this.params.rowIndex].categories = this.catalogueQuery;
    console.log('this.catPackage[this.params.rowIndex]', this.catPackage[this.params.rowIndex]);
    
    
    this.catalogue = this.catPackage[this.params.rowIndex];
    this.catalogue.businessId = this.params.businessId;
    this.catalogue.isEnabled = this.isEnabled;
    delete this.catalogue.liveCatId;
    delete this.catalogue.liveTableId;
    delete this.catalogue.liveTakeoutId;
    delete this.catalogue.livePickupId;
    delete this.catalogue.liveReservationId;
    delete this.catalogue.merchantId;
    

    console.log(this.catalogue);

    this.querySubscription = this.catalogueService
    .editCatalogueForBusiness(this.params.businessId, this.params.data.id, this.catalogue).subscribe((response: any) => {
      console.log('Catalogue response', response);
      // If Catalogue is Disabled then clear out it's live ID
      if (!response.isEnabled){
        console.log("catalogue is disabled");
        const business: any = {
          id: this.params.businessId,
          merchantId: this.params.merchantId,
          // liveCatalogueId: "",
          liveCatalogue: "",
          catalogues: this.catPackage[this.params.rowIndex].categories,
          isEnabled: !this.catPackage[this.params.rowIndex].isEnabled,
        };
        console.log('entire business', business);
        this.queryBusinessSubscription = this.merchantBusinessService
        .editBusiness(this.params.merchantId, this.params.businessId, business).subscribe((businessResponse: any) => {
          console.log(businessResponse);
        });
      }
    });
  }
}


