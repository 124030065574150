import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { AccountSettlementInput } from 'src/app/feature/merchant-details/account.model';

export const CREATE_ACCOUNT = gql`
mutation
  CreateAccount($merchantId: String, $businessId: String, $settlementAccount: AccountSettlementInput){
    CreateAccount(merchantId: $merchantId, businessId: $businessId, settlementAccount: $settlementAccount){
    businessId
    accountType
    id
    accountId
    additionalInfo
    simPayXFee
  }
}
`;

export const UPDATE_ACCOUNT = gql`
mutation
  UpdateAccount($merchantId: String, $businessId: String, $id: String, $settlementAccount: AccountSettlementInput){
    UpdateAccount(merchantId: $merchantId, businessId: $businessId, id: $id, settlementAccount: $settlementAccount){
    businessId
    accountType
    id
    accountId
    additionalInfo
    simPayXFee
  }
}
`;

export const DELETE_ACCOUNT = gql`
mutation
   DeleteAccount($merchantId: String, $businessId: String, $id: String){
    DeleteAccount(merchantId: $merchantId, businessId: $businessId, id: $id)
}
`;

@Injectable({
  providedIn: 'root'
})
export class AccountSettlementService {

  constructor(public apollo: Apollo) { }

  public createAccount(merchantId, businessId, settlementAccount: AccountSettlementInput) {
    console.log('inside create account settlement');
    return this.apollo.mutate({
      mutation: CREATE_ACCOUNT,
      // refetchQueries: ['allmerchants'],
      variables: {
        // tslint:disable-next-line:object-literal-shorthand
        merchantId: merchantId,
        // tslint:disable-next-line:object-literal-shorthand
        businessId: businessId,
        // tslint:disable-next-line:object-literal-shorthand
        settlementAccount: settlementAccount
      }
      });
      }

      public updateAccount(merchantId, businessId, id, accountType: AccountSettlementInput) {
        console.log('inside update account settlement');
        return this.apollo.mutate({
          mutation: UPDATE_ACCOUNT,
          // refetchQueries: ['allmerchants'],
          variables: {
            // tslint:disable-next-line:object-literal-shorthand
            merchantId: merchantId,
            // tslint:disable-next-line:object-literal-shorthand
            businessId: businessId,
            // tslint:disable-next-line:object-literal-shorthand
            id: id,
            // tslint:disable-next-line:object-literal-shorthand
            accountType: accountType
          }
          });
          }

          public deleteAccount(merchantId, businessId, id) {
            console.log('inside delete account settlement');
            return this.apollo.mutate({
              mutation: DELETE_ACCOUNT,
              // refetchQueries: ['allmerchants'],
              variables: {
                // tslint:disable-next-line:object-literal-shorthand
                merchantId: merchantId,
                // tslint:disable-next-line:object-literal-shorthand
                businessId: businessId,
                // tslint:disable-next-line:object-literal-shorthand
                id: id
              }
              });
              }
}
