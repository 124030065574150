import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'app-control-messages',
  templateUrl: './control-messages.component.html',
  styleUrls: ['./control-messages.component.scss'],
})
export class ControlMessagesComponent{
  @Input() control: FormControl;
  _errorMessage: string;
  constructor() {}

  get errorMessage() {
    for (let propertyName in this.control.errors) {
      if (
        this.control.errors.hasOwnProperty(propertyName) &&
        this.control.touched
      ) {
        this._errorMessage = ValidationService.getValidatorErrorMessage(
          propertyName,
          this.control.errors[propertyName]
        );
        return this._errorMessage;
      }
    }

    return null;
  }
}
