import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { ItemInput } from 'src/app/feature/catalogue/categories/category/menu-items/item/itemInput.model';

@Component({
  selector: 'app-create-item-standalone',
  templateUrl: './create-item-standalone.component.html',
  styleUrls: ['./create-item-standalone.component.scss'],
})
export class CreateItemStandaloneComponent implements OnInit {

  item: ItemInput;

  itemStandaloneForm = new FormGroup({
    id: new FormControl(''),
    name: new FormControl('', [Validators.required, Validators.minLength(4)]),
    description: new FormControl(''),
    price: new FormControl(''),
    isEnabled: new FormControl(true),
  });

  constructor(private modalController: ModalController,
              private catalogueService: CatalogueService) { }

  ngOnInit() {}


  makeid(length) {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

  onCreateItemStandaloneSubmit() {

    this.item = this.itemStandaloneForm.value;
    this.item.price = Number(this.item.price);
    this.item.id = this.makeid(24);
    console.log('this.item.id', this.item.id);
    this.modalController.dismiss(this.item);
  }
}
