import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeGridUpdate = new EventEmitter();
  gridDataSubscription: Subscription;

  constructor() { }

  refreshGrid(newData = []) {
    console.log('On Submission ' + newData.length);
    this.invokeGridUpdate.emit(newData);
  }
}
