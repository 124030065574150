import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-profile-popover',
  templateUrl: './profile-popover.component.html',
  styleUrls: ['./profile-popover.component.scss'],
})
export class ProfilePopoverComponent{

  constructor(public popoverController: PopoverController, private router: Router, public auth: AuthService) { }


  logout() {
    this.popoverController.dismiss();
    this.router.navigateByUrl('/login');
  }
}
