import { VerticalService } from './../../../shared/services/vertical.service';
import { Component, OnInit, Input, OnChanges, OnDestroy } from "@angular/core";
import { FormGroup, FormGroupName } from "@angular/forms";
import { BusinessInput } from "../../business-details/business.model";
import { MatStepper } from "@angular/material/stepper";
import { Subscription } from 'rxjs';

@Component({
  selector: "app-basic-details",
  templateUrl: "./basic-details.component.html",
  styleUrls: ["./basic-details.component.scss"],
})
export class BasicDetailsComponent implements OnChanges, OnInit, OnDestroy {
  @Input() businessForm: FormGroup;
  @Input() stepper: MatStepper;
  verticals = [];
  verticalSubscription: Subscription;
  isDisabled: boolean
  constructor(private verticalService: VerticalService) { }
  ngOnChanges() {
    if (this.businessForm.value.basicDetails.name != null) {
      this.isDisabled = true
    }
  }

  ngOnInit(){
    this.getVerticals()
  }

  getVerticals(){
    this.verticalSubscription = this.verticalService.getVerticalsSubscription().subscribe(
      (res:any)=>{
        if(res){
          console.log("res"+res)
          let mainVerticals = res
          console.log(mainVerticals)
          mainVerticals.forEach(ver => {
            let vertical = {
              name: ver.type === "Event"? ver.type.toLocaleLowerCase()+"/"+ver.subType.split(" ").join("-").toLocaleLowerCase(): ver.type.toLocaleLowerCase()
            }
            this.verticals.push(vertical)
          });
          console.log(this.verticals)
        }
      }
    )
  }

  onBasicDetailsNextClicked(stepper: MatStepper) {
    this.businessForm.getRawValue();
    stepper.next();
  }

  ngOnDestroy() {
    if(this.verticalSubscription){
      this.verticalSubscription.unsubscribe();
    }
  }
}
