import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { MerchantBusinessService } from 'src/app/shared/services/merchant-business.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { Merchant } from '../merchant-details/merchant.model';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-create-merchant',
    templateUrl: 'create-merchant.component.html',
})
export class CreateMerchantComponent implements OnInit, OnDestroy {
  private queryCreateMerchSubscription: Subscription;
  private gridDataSubscription: Subscription;
  allmerchant: Merchant[];

  merchant: any;
  public merchantResponse: BehaviorSubject<any>;

      constructor(private fb: FormBuilder,
                  private modalController: ModalController,
                  private merchantBusinessService: MerchantBusinessService,
                  private eventEmitterService: EventEmitterService,
                  private route: ActivatedRoute) {

      }

createMerchantForm = new FormGroup ({
        name: new FormControl(),
        // email: new FormControl(),
        // password: new FormControl()
      });

ngOnInit() {}

      onCreateMerchantSubmit() {
        this.merchant = this.createMerchantForm.value;
        this.merchantBusinessService.createMerchant(this.merchant).subscribe((merchantResponse) => {
        });
        this.modalController.dismiss();
      }

  ngOnDestroy() {
    if (this.queryCreateMerchSubscription) {
      this.queryCreateMerchSubscription.unsubscribe();
    }
    if (this.eventEmitterService.gridDataSubscription) {
      this.eventEmitterService.gridDataSubscription.unsubscribe();
    }
    if (this.queryCreateMerchSubscription){
      this.queryCreateMerchSubscription.unsubscribe();
    }
  }

  getAllMerchants() {
    this.queryCreateMerchSubscription = this.merchantBusinessService
    .getAllMerchants()
    .valueChanges.subscribe((allmerchant: any) => {
      if (allmerchant.data !== undefined){
        this.allmerchant = allmerchant.data.GetAllMerchants;
      }
    });
  }

}
