<ion-list>
  <!-- <ion-item (click)="saveNewPortion(sectionType, section, targetCategoryPosition, targetItemPosition, targetCustomizationPosition, businessId)">
    <ion-icon class="ion-align-self-center" name="add-circle-outline" slot="end"></ion-icon>
    <ion-label class="ion-cursor"> 
      Save <strong>{{section.name}}</strong> as a new {{sectionType}} template
    </ion-label>
  </ion-item> -->

  <ion-item (click)="duplicatePortion(sectionType, section, targetCategoryPosition, targetItemPosition, targetCustomizationPosition, businessId)">
    <ion-icon class="ion-align-self-center" name="duplicate-outline" slot="end"></ion-icon>
    <ion-label class="ion-cursor"> 
      Duplicate the <strong>{{section.name}}</strong> {{sectionType}}?
    </ion-label>
  </ion-item>
</ion-list>