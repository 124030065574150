import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CatalogueService } from 'src/app/shared/services/catalogue.service';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { CatalogueInput } from '../catalogue/catalogueInput.model';

@Component({
  selector: 'app-edit-catalogue',
  templateUrl: './edit-catalogue.component.html',
  styleUrls: ['./edit-catalogue.component.scss'],
})
export class EditCatalogueComponent implements OnInit, OnDestroy {
  private querySubscription: Subscription;
  catalogue: CatalogueInput;
  @Input() allData: any;
  public catalogueResponse: BehaviorSubject<any>;

    constructor(private fb: FormBuilder,
                private modalController: ModalController,
                private catalogueService: CatalogueService,
                private eventEmitterService: EventEmitterService) {

    }

  editCatalogueForm = new FormGroup({
    // id: new FormControl('', [Validators.required, Validators.minLength(10)]),
    // businessId: new FormControl('', [Validators.required, Validators.minLength(10)]),
    name:  new FormControl('', [Validators.required]),
    isEnabled: new FormControl(true)
  });

  ngOnInit() {
    console.log(this.allData);
  }

    onEditCatalogueSubmit() {
      this.catalogue = this.editCatalogueForm.value;
      this.catalogue.businessId = this.allData.data.businessId;
      // tslint:disable-next-line: max-line-length
      this.catalogueService.editCatalogueForBusiness(this.allData.data.businessId, this.allData.data.id, this.catalogue).subscribe((response: any) => {
        console.log(response);
      });
      // this.eventEmitterService.refreshGrid();
      this.modalController.dismiss();
    }

    ngOnDestroy() {
  if (this.querySubscription) {
    this.querySubscription.unsubscribe();
  }
  }
  }
