<ion-card>
    <ion-card-header class="d-flex ion-align-items-center">
        <ion-icon name="link-outline" color="primary" size="large"></ion-icon>
        <ion-label class="ion-padding-start spx-bold spx-font-color-black spx-font-16">Default Permalink</ion-label>
    </ion-card-header>
    <ion-card-content>
        <ion-grid>
            <ion-row *ngFor="let link of linkArray; let i = index">
                <ion-col *ngIf="link.isDefault">
                    <ion-grid class="ion-no-padding">
                        <ion-row class="ion-no-padding" data-tooltip="Copy link" (click)="
                copyToClipboard(
                  simpayxLink +
                    link.shortName +
                    '/' +
                    platformType.PICKUP.toLowerCase()
                )
              ">
                            <ion-col size="11" class="d-flex ion-align-items-center ion-no-padding">
                                <ion-label class="spx-font-color-black spx-font-16" [class.bold]="
                    copiedLink ===
                    simpayxLink +
                      link.shortName +
                      '/' +
                      platformType.PICKUP.toLowerCase()
                  ">
                                    <a>
                                        <ion-icon name="link-outline"></ion-icon>
                                        {{ simpayxLink }}/{{ link.shortName }}/{{ platformType.PICKUP | lowercase }}
                                    </a>
                                </ion-label>
                            </ion-col>
                            <ion-col size="1" class="d-flex ion-justify-content-end ion-no-padding">
                                <ion-button size="small" fill="clear" (click)="
                    copyToClipboard(
                      simpayxLink +
                        link.shortName +
                        '/' +
                        platformType.PICKUP.toLowerCase()
                    )
                  ">
                                    <ion-icon name="copy-outline" [class.icon-zoom]="
                      copiedLink ===
                      simpayxLink +
                        link.shortName +
                        '/' +
                        platformType.PICKUP.toLowerCase()
                    "></ion-icon>
                                </ion-button>
                            </ion-col>
                        </ion-row>
                        <ion-row class="ion-no-padding" data-tooltip="Copy link" (click)="
                copyToClipboard(
                  simpayxLink +
                    link.shortName +
                    '/' +
                    platformType.RESERVATION.toLowerCase()
                )
              ">
                            <ion-col size="11" class="d-flex ion-align-items-center ion-no-padding">
                                <ion-label class="spx-font-color-black spx-font-16" [class.bold]="
                    copiedLink ===
                    simpayxLink +
                      link.shortName +
                      '/' +
                      platformType.RESERVATION.toLowerCase()
                  ">
                                    <a>
                                        <ion-icon name="link-outline"></ion-icon>
                                        {{ simpayxLink }}/{{ link.shortName }}/{{ platformType.RESERVATION | lowercase }}
                                    </a>
                                </ion-label>
                            </ion-col>
                            <ion-col size="1" class="d-flex ion-justify-content-end ion-no-padding">
                                <ion-button size="small" fill="clear" (click)="
                    copyToClipboard(
                      simpayxLink +
                        link.shortName +
                        '/' +
                        platformType.RESERVATION.toLowerCase()
                    )
                  ">
                                    <ion-icon [class.icon-zoom]="
                      copiedLink ===
                      simpayxLink +
                        link.shortName +
                        '/' +
                        platformType.RESERVATION.toLowerCase()
                    " name="copy-outline"></ion-icon>
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="!defaultLink">
                <ion-col class="d-flex ion-justify-content-left">
                    <ion-item>
                        <ion-input placeholder="Link Name" type="text" [formControl]="defaultLinkName"></ion-input>
                    </ion-item>
                </ion-col>
                <ion-col class="d-flex ion-justify-content-end">
                    <ion-button [disabled]="!defaultLinkName.valid" (click)="addLink(true)">Add Default</ion-button>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="defaultLinkName.value !== '' && defaultLinkName.value !== null">
                <ion-col>
                    <div>
                        <ion-label class="spx-font-color-black spx-font-16">
                            <a>{{ simpayxLink }}/<b>{{ defaultLinkName.value }}</b
                >/{{ platformType.PICKUP | lowercase }}</a
              >
            </ion-label>
          </div>
          <div>
            <ion-label class="spx-bold spx-font-color-black spx-font-16">
              <a
                >{{ simpayxLink }}/<b>{{ defaultLinkName.value }}</b
                >/{{ platformType.RESERVATION | lowercase }}</a
              >
            </ion-label>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
<ion-card>
  <ion-card-header class="d-flex ion-align-items-center">
    <ion-icon name="link-outline" color="primary" size="large"></ion-icon>
    <ion-label
      class="ion-padding-start spx-bold spx-font-color-black spx-font-16"
      >Permalinks</ion-label
    >
  </ion-card-header>
  <ion-card-content>
    <ion-card>
      <ion-card-header class="d-flex ion-align-items-center">
        <ion-label
          class="ion-padding-start spx-bold spx-font-color-black spx-font-16"
          >Add Permalink</ion-label
        >
      </ion-card-header>
      <ion-card-content>
        <ion-grid>
          <ion-row>
            <ion-col size="10" class="d-flex ion-justify-content-left">
              <ion-item>
                <ion-input
                  placeholder="Link Name"
                  type="text"
                  [formControl]="linkName"
                ></ion-input>
              </ion-item>
            </ion-col>
            <ion-col size="2" class="d-flex ion-justify-content-end">
              <ion-button [disabled]="!linkName.valid" (click)="addLink(false)"
                >Add</ion-button
              >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <app-control-messages [control]="linkName"></app-control-messages>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="linkName.value !== '' && linkName.value !== null">
            <ion-col>
              <div>
                <ion-label class="spx-font-color-black spx-font-16">
                  <a
                    >{{ simpayxLink }}/<b>{{ linkName.value }}</b
                    >/{{ platformType.PICKUP | lowercase }}</a
                  >
                </ion-label>
              </div>
              <div>
                <ion-label class="spx-bold spx-font-color-black spx-font-16">
                  <a
                    >{{ simpayxLink }}/<b>{{ linkName.value }}</b
                    >/{{ platformType.RESERVATION | lowercase }}</a
                  >
                </ion-label>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
    <ion-card *ngIf="editableLinkArraylength >= 1">
      <ion-card-content>
        <ion-grid *ngFor="let link of linkArray; let i = index">
          <ion-row *ngIf="!link.isDefault">
            <ion-col class="d-flex ion-align-items-center">
              <ion-label class="spx-font-color-black bold spx-font-16">
                {{ link.shortName }}
              </ion-label>
            </ion-col>
            <ion-col
              size="1"
              class="d-flex ion-align-items-center ion-justify-content-end"
            >
              <ion-button
                size="small"
                fill="clear"
                (click)="presentDeleteAlert(link.id)"
              >
                <ion-icon name="trash" class="icon-zoom"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="!link.isDefault">
            <ion-col>
              <ion-grid class="ion-no-padding">
                <ion-row
                  class="ion-no-padding"
                  data-tooltip="Copy link"
                  (click)="
                    copyToClipboard(
                      simpayxLink +
                        link.shortName +
                        '/' +
                        platformType.PICKUP.toLowerCase()
                    )
                  "
                >
                  <ion-col
                    size="11"
                    class="d-flex ion-align-items-center ion-no-padding"
                  >
                    <ion-label
                      class="spx-font-color-black spx-font-16"
                      [class.bold]="
                        copiedLink ===
                        simpayxLink +
                          link.shortName +
                          '/' +
                          platformType.PICKUP.toLowerCase()
                      "
                    >
                      <ion-icon name="link-outline"></ion-icon>
                      {{ simpayxLink }}/{{ link.shortName }}/{{
                        platformType.PICKUP | lowercase
                      }}
                    </ion-label>
                  </ion-col>
                  <ion-col
                    size="1"
                    class="d-flex ion-justify-content-end ion-no-padding"
                  >
                    <ion-button
                      size="small"
                      fill="clear"
                      (click)="
                        copyToClipboard(
                          simpayxLink +
                            link.shortName +
                            '/' +
                            platformType.PICKUP.toLowerCase()
                        )
                      "
                    >
                      <ion-icon
                        name="copy-outline"
                        [class.icon-zoom]="
                          copiedLink ===
                          simpayxLink +
                            link.shortName +
                            '/' +
                            platformType.PICKUP.toLowerCase()
                        "
                      ></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
                <ion-row
                  class="ion-no-padding"
                  data-tooltip="Copy link"
                  (click)="
                    copyToClipboard(
                      simpayxLink +
                        link.shortName +
                        '/' +
                        platformType.RESERVATION.toLowerCase()
                    )
                  "
                >
                  <ion-col
                    size="11"
                    class="d-flex ion-align-items-center ion-no-padding"
                  >
                    <ion-label
                      class="spx-font-color-black spx-font-16"
                      [class.bold]="
                        copiedLink ===
                        simpayxLink +
                          link.shortName +
                          '/' +
                          platformType.RESERVATION.toLowerCase()
                      "
                    >
                      <ion-icon name="link-outline"></ion-icon>
                      {{ simpayxLink }}/{{ link.shortName }}/{{
                        platformType.RESERVATION | lowercase
                      }}
                    </ion-label>
                  </ion-col>
                  <ion-col
                    size="1"
                    class="d-flex ion-justify-content-end ion-no-padding"
                  >
                    <ion-button
                      size="small"
                      fill="clear"
                      (click)="
                        copyToClipboard(
                          simpayxLink +
                            link.shortName +
                            '/' +
                            platformType.RESERVATION.toLowerCase()
                        )
                      "
                    >
                      <ion-icon
                        [class.icon-zoom]="
                          copiedLink ===
                          simpayxLink +
                            link.shortName +
                            '/' +
                            platformType.RESERVATION.toLowerCase()
                        "
                        name="copy-outline"
                      ></ion-icon>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ion-card-content>
</ion-card>