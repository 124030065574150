import { Injectable } from "@angular/core";

import { Apollo } from "apollo-angular";
import {
  ALL_CATALOGUE_FOR_BUSINESS,
  CATALOGUE_FOR_BUSINESS_BY_ID_QUERY,
  CREATE_CATALOGUE_FOR_BUSINESS,
  DELETE_CATALOGUE,
  UPDATE_CATALOGUE_FOR_BUSINESS,
  CREATE_CATEGORY_FOR_BUSINESS,
  UPDATE_ITEM_FOR_BUSINESS,
  DELETE_ITEM,
  UPDATE_CATEGORY_FOR_BUSINESS,
  DELETE_CATEGORY,
  ALL_CATEGORIES,
  CREATE_ITEM_FOR_BUSINESS,
  ALL_ITEMS,
  CREATE_CUSTOMIZATIONS_FOR_BUSINESS,
  UPDATE_CUSTOMIZATIONS_FOR_BUSINESS,
  DELETE_CUSTOMIZATION,
  ALL_CUSTOMIZATIONS,
  ALL_TAGS,
  CREATE_TAG_FOR_BUSINESS,
  DELETE_TAG,
  UPDATE_TAG_FOR_BUSINESS,
} from './../../feature/catalogue/catalogue-queries';
import { Catalogue } from 'src/app/feature/catalogue/catalogue.model';
import { CatalogueInput } from 'src/app/feature/catalogue/catalogueInput.model';
import { CategoryInput } from 'src/app/feature/catalogue/categories/categoryInput.model';
import { CustomizationInput } from 'src/app/feature/catalogue/categories/category/menu-items/item/customization/optionsInput.model';
import { ItemInput } from 'src/app/feature/catalogue/categories/category/menu-items/item/itemInput.model';
import { TagInput } from 'src/app/feature/catalogue/categories/category/menu-items/item/tag/tagInput.model';

@Injectable({
  providedIn: "root",
})
export class CatalogueService {
  constructor(public apollo: Apollo) {}

  public getAllCataloguesForBusiness(businessId) {
    return this.apollo.watchQuery({
      query: ALL_CATALOGUE_FOR_BUSINESS,
      // pollInterval: 10000,
      fetchPolicy: 'cache-and-network',
      variables: {
        // tslint:disable-next-line:object-literal-shorthand
        businessId: businessId,
      }
    });
  }

  public getCatalogueForBusinessById(businessId, catalogueId) {
    return this.apollo.watchQuery({
      query: CATALOGUE_FOR_BUSINESS_BY_ID_QUERY,
      fetchPolicy: 'cache-and-network',
      variables: {
        // tslint:disable-next-line: object-literal-shorthand
        businessId: businessId,
        // tslint:disable-next-line: object-literal-shorthand
        catalogueId: catalogueId
      }
    });
  }

  public createCatalogueForBusiness(catalogue: Catalogue) {
    console.log("inside create catalogue");
    console.log(catalogue);
    return this.apollo
      .mutate({
        mutation: CREATE_CATALOGUE_FOR_BUSINESS,
        variables: {
          // tslint:disable-next-line: object-literal-shorthand
          catalogue: catalogue,
        },
      });
  }

  public editCatalogueForBusiness(businessId, catalogueId, catalogue: CatalogueInput) {
    return this.apollo
      .mutate({
        mutation: UPDATE_CATALOGUE_FOR_BUSINESS,
        variables: {
          // tslint:disable-next-line: object-literal-shorthand
          businessId: businessId,
          // tslint:disable-next-line: object-literal-shorthand
          catalogueId: catalogueId,
          // tslint:disable-next-line: object-literal-shorthand
          catalogue: catalogue,
        },
      });
  }

  public deleteCatalogue(businessId, catalogueId) {
    console.log("inside delete catalogue");
    return this.apollo
    .mutate({
      mutation: DELETE_CATALOGUE,
      variables: {
        // tslint:disable-next-line: object-literal-shorthand
        businessId: businessId,
        // tslint:disable-next-line: object-literal-shorthand
        catalogueId: catalogueId,
      }
    });
  }
  
  createCategory(categoryData: CategoryInput, businessId: string) {
    console.log("inside create category");
    console.log(categoryData);
    return this.apollo
      .mutate({
        mutation: CREATE_CATEGORY_FOR_BUSINESS,
        refetchQueries: ['GetAllCategory'],
        variables: {
          // tslint:disable-next-line: object-literal-shorthand
          category: categoryData,
          // tslint:disable-next-line: object-literal-shorthand
          businessId: businessId
        },
      });
  }

  updateCategory(businessId: string, categoryData: CategoryInput) {
    console.log("inside update item");
    console.log(categoryData);
    return this.apollo
      .mutate({
        mutation: UPDATE_CATEGORY_FOR_BUSINESS,
        refetchQueries: ['GetAllCategory'],
        variables: {
          // tslint:disable-next-line: object-literal-shorthand
          businessId: businessId,
          categoryId: categoryData.id,
          item: categoryData,
        },
      });
  }

  public deleteCategory(businessId, categoryId) {
    console.log("inside delete item");
    return this.apollo
    .mutate({
      mutation: DELETE_CATEGORY,
      refetchQueries: ['GetAllCategory'],
      variables: {
        // tslint:disable-next-line: object-literal-shorthand
        businessId: businessId,
        // tslint:disable-next-line: object-literal-shorthand
        categoryId: categoryId,
      }
    });
  }
  
  public getAllCategories(businessId) {
    return this.apollo
      .watchQuery({
        query: ALL_CATEGORIES,
        // pollInterval: 10000,
        fetchPolicy: 'cache-and-network',
        variables: {
          // tslint:disable-next-line:object-literal-shorthand
          businessId: businessId,
        }
      });
  }

  createItem(businessId: string, itemData: ItemInput) {
    console.log("inside create item");
    console.log(itemData);
    return this.apollo
      .mutate({
        mutation: CREATE_ITEM_FOR_BUSINESS,
        refetchQueries: ['GetAllItems'],
        variables: {
          // tslint:disable-next-line: object-literal-shorthand
          businessId: businessId,
          item: itemData,
        },
      });
  }

  updateItem(businessId: string, itemData: ItemInput) {
    console.log("inside update item");
    console.log(itemData);
    return this.apollo
      .mutate({
        mutation: UPDATE_ITEM_FOR_BUSINESS,
        refetchQueries: ['GetAllItems'],
        variables: {
          // tslint:disable-next-line: object-literal-shorthand
          businessId: businessId,
          itemId: itemData.id,
          item: itemData,
        },
      });
  }

  public deleteItem(businessId, itemId) {
    console.log("inside delete item");
    return this.apollo
    .mutate({
      mutation: DELETE_ITEM,
      refetchQueries: ['GetAllItems'],
      variables: {
        // tslint:disable-next-line: object-literal-shorthand
        businessId: businessId,
        // tslint:disable-next-line: object-literal-shorthand
        itemId: itemId,
      }
    });
  }

  public getAllItems(businessId) {
    return this.apollo
      .watchQuery({
        query: ALL_ITEMS,
        // pollInterval: 10000,
        fetchPolicy: 'cache-and-network',
        variables: {
          // tslint:disable-next-line:object-literal-shorthand
          businessId: businessId,
        }
      });
  }

  createCustomization(customizationData: CustomizationInput) {
    console.log("inside create Customization");
    console.log(customizationData);
    return this.apollo
      .mutate({
        mutation: CREATE_CUSTOMIZATIONS_FOR_BUSINESS,
        refetchQueries: ['GetAllCustomization'],
        variables: {
          customization: customizationData,
        },
      });
  }

  updateCustomization(customizationData: CustomizationInput) {
    console.log("inside update Customization");
    console.log(customizationData);
    return this.apollo
      .mutate({
        mutation: UPDATE_CUSTOMIZATIONS_FOR_BUSINESS,
        refetchQueries: ['GetAllCustomization'],
        variables: {
          businessId: customizationData.businessId,
          customizationId: customizationData.id,
          customization: customizationData,
        },
      });
  }

  public deleteCustomization(businessId, customizationId) {
    console.log("inside delete customization");
    return this.apollo
    .mutate({
      mutation: DELETE_CUSTOMIZATION,
      refetchQueries: ['GetAllCustomization'],
      variables: {
        // tslint:disable-next-line: object-literal-shorthand
        businessId: businessId,
        // tslint:disable-next-line: object-literal-shorthand
        customizationId: customizationId,
      }
    });
  }

  public getAllCustomizations(businessId) {
    return this.apollo
      .watchQuery({
        query: ALL_CUSTOMIZATIONS,
        // pollInterval: 10000,
        fetchPolicy: 'cache-and-network',
        variables: {
          // tslint:disable-next-line:object-literal-shorthand
          businessId: businessId,
        }
      });
  }

  createTag(tagData: TagInput) {
    console.log("inside create tag");
    console.log(tagData);
    return this.apollo
      .mutate({
        mutation: CREATE_TAG_FOR_BUSINESS,
        refetchQueries: ['GetAllTags'],
        variables: {
          tag: tagData,
        },
      });
  }

  editTag(businessId, tagId, tagData: TagInput) {
    console.log("inside edit tag");
    console.log(tagData);
    return this.apollo
      .mutate({
        mutation: UPDATE_TAG_FOR_BUSINESS,
        refetchQueries: ['GetAllTags'],
        variables: {
          // tslint:disable-next-line:object-literal-shorthand
          businessId: businessId,
          // tslint:disable-next-line:object-literal-shorthand
          tagId: tagId,
          tag: tagData,
        },
      });
  }

  deleteTag(businessId, tagId) {
    console.log("inside delete tag");
    return this.apollo
      .mutate({
        mutation: DELETE_TAG,
        refetchQueries: ['GetAllTags'],
        variables: {
          // tslint:disable-next-line:object-literal-shorthand
          businessId: businessId,
          // tslint:disable-next-line:object-literal-shorthand
          tagId: tagId,
        },
      });
  }

  public getAllTags(businessId) {
    return this.apollo
      .watchQuery({
        query: ALL_TAGS,
        // pollInterval: 10000,
        fetchPolicy: 'cache-and-network',
        variables: {
          // tslint:disable-next-line:object-literal-shorthand
          businessId: businessId,
        }
      });
  }
}
