import gql from "graphql-tag";

export const ALL_MERCHANTS_QUERY = gql`
  query allmerchants {
    GetAllMerchants {
      isEnabled
      name
      id
      digitalAddresses
        {
          addressType
          address
          preferred
        }
      businesses {
        id
        name
        merchantId
        vertical
        address {
          line1
          line2
          state
          city
          country
          zip
          timeZone
        }
        isEnabled
        digitalAddresses {
          addressType
          address
          preferred
        }
        businessAttributes {
          catalogues
          taxes {
            name
            rate
          }
          theme
          servicePlatforms {
            type
            liveCatalogueId
            platformFees
            isEnabled
            resources {
              id
              name
              isEnabled
              attributes
              serviceCapacity
              serviceTimeInMin
            }
            businessTimings
            avgServiceTimeInMin 
          }
        }
      }
    }
  }
`;

export const MERCHANTS_BY_ID_QUERY = gql`
  query
    getMerchantById($merchantId: String!) {
      GetMerchantById(merchantId: $merchantId) {
        name
        isEnabled
        digitalAddresses
        {
          addressType
          address
          preferred
        }
        businesses {
          id
          name
          merchantId
          vertical
          address {
            line1
            line2
            state
            city
            country
            zip
            timeZone
          }
          isEnabled
          digitalAddresses {
            addressType
            address
            preferred
          }
          businessAttributes {
            catalogues
            taxes {
              name
              rate
            }
            theme
            servicePlatforms {
              type
              liveCatalogueId
              platformFees
              isEnabled
              resources {
                id
                name
                isEnabled
                attributes
                serviceCapacity
                serviceTimeInMin
              }
              businessTimings
              avgServiceTimeInMin 
            }
          }
        }
      }
    }

`;

//Getting error while fetching vertical and address of businesses also active not there in SCHEMA
export const ALL_BUSINESSES_FOR_MERCHANT = gql`
  query getAllBusinessesForMerchant($merchantId: String!){
      GetAllBusinessesForMerchant(merchantId: $merchantId) {
        id
        name
        merchantId
        vertical
        address {
          line1
          line2
          state
          city
          country
          zip
          timeZone
        }
        isEnabled
        digitalAddresses {
          addressType
          address
          preferred
        }
        businessAttributes {
          catalogues
          taxes {
            name
            rate
          }
          theme
          servicePlatforms {
            type
            subtype
            liveCatalogueId
            platformFees
            isEnabled
            resources {
              id
              name
              isEnabled
              attributes
              serviceCapacity
              serviceTimeInMin
            }
            businessTimings
            avgServiceTimeInMin
            slots 
          }
        }
      }
    }
`;
