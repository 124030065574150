import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { FeatureToggleService } from 'src/app/shared/services/feature-toggle.service';
import { FeatureToggleModel } from 'src/app/toggle-feature/feature-toggle.model';

@Component({
  selector: 'app-create-feature',
  templateUrl: './create-feature.component.html',
  styleUrls: ['./create-feature.component.scss'],
})
export class CreateFeatureComponent implements OnInit {

  featureForm = new FormGroup({
    name: new FormControl()
  });

  constructor(private featureService: FeatureToggleService, private modalController: ModalController
  ) { }

  ngOnInit() { }

  onSubmit() {
    this.featureService.createFeature(new FeatureToggleModel(this.featureForm.value.name)).subscribe(feature => {
      this.modalController.dismiss();
    })
  }
}
